import axios from "axios";
import IndexNavBar2 from "components/Navbars/IndexNavBar2";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Loader from "components/Pages/Loader";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function ProfileSettings() {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  //Name popup

  const [showPopupProfile, setShowPopupProfile] = useState(false);

  const togglePopupProfile = () => {
    setShowPopupProfile(!showPopupProfile);
  };

  const closePopupProfile = () => {
    setShowPopupProfile(false);
  };

  const [userData, setUserData] = useState([]);

  const updateProfile = () => {
    setLoadingMessage("Updating profile...");
    setLoading(true)
    let token = localStorage.getItem('token');
    axios.post("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/update-profile", {userData: userData}, { headers : { Authorization: "Bearer " + token}})
    .then((response) => {
       if(response.status == 200)
       {
         if(response.data.status)
         {
            toast.success(response.data.message);
            setTimeout(() => {
              setLoading(false);
              window.location.reload();
            },2000);
         }
         else
         {
          setLoading(false)
          toast.error(response.data.message);
         }
       }
    })
    .catch((errr) => {
      setLoading(false)
      console.log(errr);
    });
  }

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios.get("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/user-profile", { headers: { Authorization: "Bearer " + token}})
    .then((response) => {
      if(response.status == 200)
      {
        if(response.data.status)
        {
          setUserData(response.data.profile);
        }
      }
    })  
    .catch((error) => {
      console.log(error);
    })
  },[]);

  return (
    <>
    <Loader loading={loading} text={loadingMessage} />
    <section>
      {/* <IndexNavbar /> */}
      <IndexNavBar2 />
      <div className="mx-auto overflow-hidden container xl:pt-24 lg:pt-32 md:pt-32 px-4 pt-28 xl:pb-28 lg:pb-20 md:pb-12 pb-8 xl:px-36 lg:px-44 md:px-32">
        <h1 className="figtree xl:text-3xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl  xl:pt-12">
          Profile Settings
        </h1>
        <div className="bg-white border-grey-50 rounded-md shadow-md xl:p-6 p-4 xl:mt-4 md:mt-2 mt-2">
          <div className="justify-between flex">
            <div>
              <h3 className="font text-dark-blue xl:text-2xl lg:text-base sm:text-sm font-medium md:text-lg text-lg ">
                {userData.name} {userData.last_name}
              </h3>
              <div className="flex xl:pt-4 xl:py-0 py-2">
                <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base ">
                  Phone Number
                </div>
                <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base xl:pl-42 pl-12">
                  Address
                </div>
              </div>
              <div className="flex xl:py-1 py-2">
                <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base ">
                  {userData.mobile}
                </div>
                <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base xl:pl-42 pl-12">
                  {userData.flat }
                </div>
              </div>
            </div>
            <div className="">
              <button
                className="no-outline focus:outline-none forgot-password font text-blue-new font-semibold text-base"
                onClick={togglePopup}
              >
                Edit
              </button>
              {showPopup && (
                <div
                  className="animate-slide-up px-4 fixed top-0 left-0 w-full h-full bg-light-black bg-opacity-50 flex justify-center items-center"
                  onClick={closePopup}
                >
                  <div
                    className="bg-white xl:p-6 lg:p-4 p-4 rounded-lg"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* Popup content */}
                    <div className="flex justify-between">
                      <div>
                        <h1 className="figtree text-dark-blue xl:text-2xl lg:text-base font-extrabold md:text-2xl text-xl mb-6">
                          Edit User Information
                        </h1>
                      </div>
                      <div>
                        <button onClick={closePopup} className="font no-outline focus:outline-none ">
                          ✖
                        </button>
                      </div>
                    </div>
                    <div className="grid xl:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-2">
                      <input
                        type="text"
                        placeholder="First Name"
                        className="text-base rounded-md border-grey-100 font mt-2"
                        value={userData.name}
                        onChange={(e) => setUserData({...userData, name: e.target.value})}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="text-base rounded-md border-grey-100 font mt-2"
                        value={userData.last_name}
                        onChange={(e) => setUserData({...userData, last_name: e.target.value})}
                      />
                      <input
                        type="text"
                        placeholder="Phone"
                        className="text-base rounded-md border-grey-100 font mt-2"
                        value={userData.mobile}
                        onChange={(e) => setUserData({...userData, mobile: e.target.value})}
                      />
                      <input
                        type="text"
                        placeholder="Street Address 1"
                        className="text-base rounded-md border-grey-100 font"
                        value={userData.flat}
                        onChange={(e) => setUserData({...userData, flat: e.target.value})}
                      />
                      <input
                        type="text"
                        placeholder="Street Address 2"
                        className="text-base rounded-md border-grey-100 font"
                        value={userData.street}
                        onChange={(e) => setUserData({...userData, street: e.target.value})}
                      />
                      <input
                        type="text"
                        placeholder="City"
                        className="text-base rounded-md border-grey-100 font"
                        value={userData.city}
                        onChange={(e) => setUserData({...userData, city: e.target.value})}
                      />
                      <input
                        type="text"
                        placeholder="Province"
                        className="text-base rounded-md border-grey-100 font"
                        value={userData.address_state}
                        onChange={(e) => setUserData({...userData, address_state: e.target.value})}
                      />
                      <select
                        id="inquiryType"
                        className="xl:mt-0 pl-4 xl:w-full font rounded px-20 py-2 hover:bg-black border-grey-100  md:text-xs xl:text-base lg:text-base text-base"
                        value={userData.country}
                        onChange={(e) => setUserData({...userData, country: e.target.value})}
                      >
                        <option
                          value="India"
                          className="font md:text-xs xl:text-base lg:text-base text-xs py-2"
                        >
                          India
                        </option>
                      </select>
                      <input
                        type="text"
                        placeholder="Postal Code"
                        className="text-base rounded-md border-grey-100 font"
                        value={userData.postal_code}
                        onChange={(e) => setUserData({...userData, postal_code: e.target.value})}
                      />
                    </div>
                    <div className="justify-between flex mt-4">
                      <div></div>
                      <div>
                        <button
                          onClick={closePopup}
                          className="no-outline focus:outline-none border-blue bg-white font-semibold px-8 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-8 xl:py-1 md:px-8 md:mt-12 md:py-1 border rounded-lg text-blue-new"
                        >
                          Cancel{" "}
                        </button>
                        <button className="no-outline focus:outline-none ml-2 buttonWithBlue border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white" type="button" onClick={updateProfile}>
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Second Popup content */}

          <div className="justify-between flex border-t">
            <div className="xl:py-6 py-2">
              <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base">
                Email
              </div>
              <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base">
                {userData.email}
              </div>
            </div>
            <div>
              {/* <button
                className="forgot-password font text-blue-new font-semibold text-base mt-4"
                onClick={togglePopupProfile}
              >
                Edit
              </button> */}
              {showPopupProfile && (
                <div
                  className="fixed top-0 left-0 w-full h-full bg-light-black bg-opacity-50 flex justify-center items-center"
                  onClick={closePopupProfile}
                >
                  <div
                    className="bg-white p-6 rounded-lg"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* Popup content */}
                    <div className="flex justify-between">
                      <div>
                        <h1 className="font-serif text-dark-blue xl:text-2xl lg:text-base font-extrabold md:text-2xl text-xl mb-6">
                          Edit Email
                        </h1>
                      </div>
                      <div>
                        <button onClick={closePopupProfile} className="font">
                          ✖
                        </button>
                      </div>
                    </div>
                    <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2">
                      <div className="border-grey-100 rounded-md p-3 text-lg font ">
                        ramkumar.elegantcarve@gmail.com
                      </div>
                      <input
                        type="password"
                        className="border-grey-100 rounded-md p-3 text-lg font "
                      ></input>
                    </div>
                    <div className="justify-between flex mt-4">
                      <div></div>
                      <div>
                        <button
                          onClick={closePopupProfile}
                          className="border-blue bg-white font-semibold px-8 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-8 xl:py-1 md:px-8 md:mt-12 md:py-1 border rounded-lg text-blue-new"
                        >
                          Cancel{" "}
                        </button>
                        <button className="ml-2 buttonWithBlue border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Remaining fields */}

          <div className="border-t">
            <div className="xl:py-6 py-2">
              <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base">
                Password
              </div>
              <div className="flex-none xl:flex lg:flex md:flex xl:pt-3 pt-2 xl:pb-0 pb-2">
                <div className="forgot-password font text-blue-new font-medium text-sm">
                  <a href="/change-password">Change Password</a>
                </div>
                {/* <div className="forgot-password font text-blue-new font-semibold text-sm xl:pl-36 pt-2 md:pt-0">
                  Setup Two Factor Authentication
                </div> */}
              </div>
            </div>
          </div>
          <div className="border-t">
            <div className="xl:py-6 py-2">
              <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base ">
                Language
              </div>
              <div className="">
                <select
                  id="inquiryType"
                  className="xl:mt-4 mt-2 pl-4 font rounded px-20 xl:w-60 py-2 hover:bg-black border-grey-100  md:text-xs xl:text-base lg:text-base text-base"
                >
                  <option
                    value="1"
                    className="font  md:text-xs xl:text-base lg:text-base text-xs py-2 xl:pt-2 md:pt-0 lg:pt-0 pt-0"
                  >
                    English
                  </option>
                  {/* <option
                    value="2"
                    className="font  md:text-xs xl:text-base lg:text-base text-xs  py-2 "
                  >
                    French
                  </option> */}
                </select>
              </div>
            </div>
          </div>
          {/* <div className="border-t mt-2 xl:mt-0">
            <div className="xl:py-6 py-2">
              <div className="font text-dark-blue xl:text-base lg:text-base font-semibold md:text-base text-base">
                Billings
              </div>
              <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-sm">
                Click company name to edit billing
              </div>
              <div className="border-grey-100 rounded-md forgot-password font text-blue-new p-3 mt-2 font-semibold text-base">
                elegantcarve lnc
              </div>
            </div>
          </div> */}
          {/* <div className="border-t">
            <div className="xl:py-6">
              <div className="font text-dark-blue xl:text-base lg:text-base font-semibold md:text-base text-base">
                Email Notification
              </div>
              <div className="flex pt-4">
                <input
                  type="checkbox"
                  id="billingCheckbox"
                  className="mt-1 rounded-md"
                />
                <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-sm ml-4">
                  Click company name to edit billing
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
    </>
  );
}
