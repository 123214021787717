import React, { useState, useRef, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import bagbgall from "assets/img/bag-bg-all.jpg";
import iplanning from "assets/img/icons/iplanning.png";
import extension from "assets/img/icons/extension.png";
import hr from "assets/img/icons/hr.png";
import download from "assets/img/icons/download.png";
import press from "assets/img/icons/press.svg";
import careers from "assets/img/images/careers.svg";

const ServiceDropdown1 = () => {
  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-16 mt-16"></div>
      <div>
        <div
          style={{
            // backgroundImage: `url(${bagbgall})`,
            backgroundColor:
              "linear-gradient(180deg,rgba(236,243,255,.4),rgba(236,243,255,0) 60%);",
            width: "100%",
          }}
          className="xl:h-maximum md:h-60 h-48 relative"
        >
          <p className="font xl:text-5xl md:text-2ml text-xl text-blue text-center xl:pt-32 md:pt-32  pt-28 font-semibold">
            Our Services
          </p>
          <p className="xl:px-96 font text-black xl:text-lg text-center xl:pt-2">
            Since we began, Kissflow has embraced a culture that puts people
            first. A decade later, we continue to uphold this philosophy by
            inspiring our employees to reach their full potential with <b> courage,
            agility, creativity, independence, learning, and collaborative
            growth.</b>
          </p>
          <div className="container mx-auto relative overflow-hidden">
            {/* <div className="grid xl:grid-cols-4 xl:gap-4 xl:mx-20 lg:grid-cols-3 md:gap-4 md:grid-cols-3 gap-4 grid-cols-1 xl:py-16 md:px-4 px-4 pt-2">
              <div className="hover:-mt-4 transition ease-out duration-150 mb-6 ">
                <div className="cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border bg-white rounded-lg font">
                <img src={iplanning} className="xl:w-18 xl:p-2" />
                <p className="font-semibold text-ivory-black xl:text-base">
                  Corporate Services
                </p>
                <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                  Incorporating a Business in a foreign jurisdiction is never
                  easy.
                </p>
                <div className="bg-blue border rounded-full text-white xl:w-7 xl:pl-1 xl:mt-4">
                  ﹥
                </div>
                </div>
              </div>
              <div className="hover:-mt-4 transition ease-out duration-150 mb-6 ">
                <div className="cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border bg-white rounded-lg font">
                <img src={extension} className="xl:w-18 xl:p-2" />
                <p className="font-semibold text-ivory-black xl:text-base">
                  HR services
                </p>
                <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                  Incorporating a Business in a foreign jurisdiction is never
                  easy.
                </p>
                <div className="bg-blue border rounded-full text-white xl:w-7 xl:pl-1 xl:mt-4">
                  ﹥
                </div>
                </div>
              </div>
              <div className="hover:-mt-4 transition ease-out duration-150 mb-6 ">
                <div className="cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border bg-white rounded-lg font">
                <img src={hr} className="xl:w-18 xl:p-2" />
                <p className="font-semibold text-ivory-black xl:text-base">
                  IT Services
                </p>
                <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                  Incorporating a Business in a foreign jurisdiction is never
                  easy.
                </p>
                <div className="bg-blue border rounded-full text-white xl:w-7 xl:pl-1 xl:mt-4">
                  ﹥
                </div>
                </div>
              </div>
              <div className="hover:-mt-4 transition ease-out duration-150 mb-6 ">
                <div className="cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border bg-white rounded-lg font">
                <img src={download} className="xl:w-18 xl:p-2" />
                <p className="font-semibold text-ivory-black xl:text-base">
                  IP Services
                </p>
                <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                  Incorporating a Business in a foreign jurisdiction is never
                  easy.
                </p>
                <div className="bg-blue border rounded-full text-white xl:w-7 xl:pl-1 xl:mt-4">
                  ﹥
                </div>
                </div>
              </div>
            </div> */}
            <div className="container mx-auto xl:px-16 px-4 overflow-hidden">
              <div className="grid xl:grid-cols-2 xl:gap-6 xl:mt-16">
                {/* <div className="hover:-mt-4 transition ease-out duration-150 mb-6 "> */}
                <div className="flex xl:w-12/12 xl:h-280 bg-light-orange cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border rounded-lg font">
                  <div className="xl:w-8/12">
                    <p className="font-semibold text-ivory-black xl:text-3xl xl:pt-8">
                      Corporate Services
                    </p>
                    <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                      Incorporating a Business in a foreign jurisdiction is
                      never easy.
                    </p>
                    {/* <div className="bg-blue border rounded-full text-white xl:w-7 xl:pl-1 xl:mt-4">
                      ﹥
                    </div> */}
                    <button className="buttonWithBlue xl:px-4 xl:py-2 xl:mt-4">
                      Get started ﹥
                    </button>
                  </div>
                  <div className="xl:w-4/12 xl:pt-6">
                    <img src={press} className="xl:w-60 xl:p-2" />
                  </div>
                </div>

                <div className="flex xl:w-12/12 xl:h-280 bg-light-green cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border rounded-lg font">
                  <div className="xl:w-8/12">
                    <p className="font-semibold text-ivory-black xl:text-3xl xl:pt-8">
                      HR Services
                    </p>
                    <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                      Incorporating a Business in a foreign jurisdiction is
                      never easy.
                    </p>
                    <button className="buttonWithBlue xl:px-4 xl:py-2 xl:mt-4">
                      Get started ﹥
                    </button>
                  </div>
                  <div className="xl:w-4/12 xl:pt-6">
                    <img src={careers} className="xl:w-60 xl:p-2" />
                  </div>
                </div>
                <div className="flex xl:w-12/12 xl:h-280 xl:mt-2 bg-light-blue-new cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border rounded-lg font">
                  <div className="xl:w-8/12">
                    <p className="font-semibold text-ivory-black xl:text-3xl xl:pt-8">
                      IT Services
                    </p>
                    <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                      Incorporating a Business in a foreign jurisdiction is
                      never easy.
                    </p>
                    <button className="buttonWithBlue xl:px-4 xl:py-2 xl:mt-4">
                      Get started ﹥
                    </button>
                  </div>
                  <div className="xl:w-4/12 xl:pt-6">
                    <img src={careers} className="xl:w-60 xl:p-2" />
                  </div>
                </div>
                <div className="flex xl:w-12/12 xl:h-280 bg-neww xl:mt-2 cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border rounded-lg font">
                  <div className="xl:w-8/12">
                    <p className="font-semibold text-ivory-black xl:text-3xl xl:pt-8">
                      IP Services
                    </p>
                    <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                      Incorporating a Business in a foreign jurisdiction is
                      never easy.
                    </p>
                    <button className="buttonWithBlue xl:px-4 xl:py-2 xl:mt-4">
                      Get started ﹥
                    </button>
                  </div>
                  <div className="xl:w-4/12 xl:pt-6">
                    <img src={press} className="xl:w-60 xl:p-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: "white" }}
          className="xl:mb-20 md:h-80 h-100"
        >
          <div className="container mx-auto xl:px-16 md:px-16 px-4"></div>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default ServiceDropdown1;
