/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import SliderPage from "./SliderPage";
import AboutUs from "components/Pages/about/AboutUs";
import Aboutt from "about/aboutpage/Aboutt";
import Homepage1 from "components/Homepage/Homepage1";
import axios from "axios";

export default function Index() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const isLoggedInValue =  localStorage.getItem("is_logged_in");
    setIsLoggedIn(isLoggedInValue ? JSON.parse(isLoggedInValue) : false);
  },[]);

  useEffect(() => {
    let token = localStorage.getItem('token');
    axios.get('https://admin.ibusinessconsulting.ca/admin/public/index.php/api/user', { headers: {Authorization: ` Bearer ${token}`}})
    .then((response) => {
      console.log(response);
      if(response.status == 200)
      {
        if(response.data.status)
        {
          setLoggedInUser(response.data.user);
        }
      }
    })
    .catch((error) => {
      if(error.request.status == 401)
      {
        localStorage.removeItem("token");
        localStorage.removeItem("is_logged_in");
        setIsLoggedIn(false);
        setLoggedInUser(null);
      }
    })
  },[]);

  return (
    <section className="relative overflow-hidden">
      <IndexNavbar fixed/>
      <Homepage1/>
    </section>
  );
}
