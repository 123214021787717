import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import 'react-toastify/dist/ReactToastify.css';

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";

// Service main page
import ServiceMain from "components/Dropdowns/ServiceMain";

//about us
import Aboutt from "about/aboutpage/Aboutt.js";

// contact us
import Contactus from "about/aboutpage/Contactus";
import Applications from "components/Dropdowns/ApplicationsContent";
import Whymarslab from "components/Dropdowns/Whymarslab";
import Leadership from "components/Dropdowns/Leadership";
import Cloud1 from "components/Cloud/Cloud1";
import Cloudslider from "views/Cloudslider";
import Service from "components/Homepage/Service";
import CloudService from "components/Homepage/CloudService";
import PrivateCloudService from "components/Homepage/PrivateCloudService";
import ModernOperations from "components/Homepage/ModernOperations";
import CloudMigration from "components/Homepage/CloudMigration";
import ServiceDropdown1 from "components/Dropdowns/ServiceDropDown1";
import CorporateService from "components/Homepage/CorporateService";
import HrService from "components/Homepage/HrService";
import ItService from "components/Homepage/ItService";
import IpService from "components/Homepage/IpService";
import SolutionHomepage from "components/Homepage/SolutionHomepage";
import CorporateServices from "components/Homepage/CorporateServices";
import CorporateWizard from "components/Pages/about/CorporateWizard";
import TermsOfUse from "components/Homepage/TermsOfUse";
import PrivacyPolicy from "components/Homepage/PrivacyPolicy";
import LoginPage from "components/Homepage/LoginPage";
import ResetPassword from "components/Homepage/ResetPassword";
import ProfileSettings from "components/Homepage/ProfileSettings";
import IndexNavBar2 from "components/Navbars/IndexNavBar2";
import Sidebar from "components/Sidebar/Sidebar";
import Dashboard from "views/admin/Dashboard";
import Dashboardnew from "components/Homepage/Dashboardnew";
import CorporationService from "components/Homepage/CorporationService";
import ItPage from "components/Homepage/ItPage";
import HrServiceHomepage from "components/Homepage/HrServiceHomepage";
import IpServiceHomepage from "components/Homepage/IpServiceHomepage";
import PopUp from "components/Pages/PopUp";
import EnquiryButton from "components/Pages/EnquiryButton";
import ChangePassword from "components/Homepage/ChangePassword";
import ThankYouPage from "components/Homepage/ThankYouPage";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CancellationPolicy from "components/Homepage/CancellationPolicy";

ReactDOM.render(
  <GoogleOAuthProvider clientId="836013379382-eo932f397cd92ktuvr64o899cl83pj7h.apps.googleusercontent.com">

  <BrowserRouter>
    <Switch>

      {/* Aboutus page */}
      <Route path="/about-us" exact component={Aboutt} />
      <Route path="/whymarslab" exact component={Whymarslab} />
      <Route path="/index" exact component={Index} />
      <Route path="/team" exact component={Leadership} />
      <Route path="/cloud" exact component={Cloud1} />
      <Route path="/cloudslider" exact component={Cloudslider} />

      {/* services */}
      <Route path="/service" exact component={Service} />
      <Route path="/cloudservice" exact component={CloudService} />
      <Route path="/privatecloudservice" exact component={PrivateCloudService} />
      <Route path="/modernoperations" exact component={ModernOperations} />
      <Route path="/cloudmigration" exact component={CloudMigration} />
      <Route path="/servicedropdown1" exact component={ServiceDropdown1} />
      <Route path="/it-services" exact component={ItPage} />

      {/* Contact us */}
      <Route path="/contact" exact component={Contactus} />
      <Route path="/" exact component={Index} />

      {/* Login and Reset password*/}
      <Route path="/login" exact component={LoginPage} />
      <Route path="/reset-password" exact component={ResetPassword} />
      <Route path="/profile" exact component={ProfileSettings} />
      <Route path="/navbar" exact component={IndexNavBar2} />
      <Route path="/dashboard-new" exact component={Dashboardnew} />
      <Route path="/sidebar-landing" exact component={Sidebar} />
      <Route path="/corporation-service" exact component={CorporationService} />

      {/* Inside dropdown content  */}
      <Route path="/applications" exact component={Applications} />
      <Route path="/popup" exact component={PopUp} />
      <Route path="/enquiry-button" exact component={EnquiryButton} />


      {/* Service */}
      <Route path="/service-homepage" exact component={ServiceMain} />
      <Route path="/corporate-service" exact component={CorporateService} />
      <Route path="/hr-service" exact component={HrService} />
      <Route path="/it-service" exact component={ItService} />
      <Route path="/ip-service" exact component={IpService} />
      <Route path="/solution-homepage" exact component={SolutionHomepage} />
      <Route path="/corporate-services" exact component={CorporateServices} />
      <Route path="/corporate-wizard" exact component={CorporateWizard} />
      <Route path="/hr-service-homepage" exact component={HrServiceHomepage} />
      <Route path="/ip-service-homepage" exact component={IpServiceHomepage} />

      {/* Footer Pages */}
      <Route path="/terms-of-use" exact component={TermsOfUse} />
      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      <Route path="/cancellation-policy" exact component={CancellationPolicy} />
      <Route path="/change-password" exact component={ChangePassword} />

      {/* Thank You page */}
      <Route path="/thank-you" exact component={ThankYouPage} />

      {/* add redirect for first page */}
      <Redirect from="*" to="/" />

      
      
    </Switch>
    <ToastContainer />
  </BrowserRouter>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
