import React, { useState, useEffect } from "react";
import one from "assets/img/one.png";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import hrservicenew from "assets/img/hrservicenew.svg";
import ipservicenew from "assets/img/ipservicenew.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import Loader from "../Loader";
import google from "assets/img/google.png";

const Step1 = ({
  onNext,
  onPrevious,
  corporateLocation,
  setCorporateLocation,
  corporateLocationError,
}) => {
  const changeCompanyLocation = (event) => {
    setCorporateLocation(event.target.value);
  };
  return (
    <div>
      <div className="flex flex-wrap justify-center">
        <div className="flex xl:mt-20 mt-32 bg-bluee-700  w-full lg:w-9/12 md:w-10/12 px-6 py-4 rounded">
          <div>
            <img src={one} className="xl:w-20 w-16 h-auto" />
          </div>
          <div className=" xl:ml-8 ml-4">
            <p className="figtree xl:text-4xl font-bold lg:text-3xl md:text-2xl text-2xl text-white xl:pt-2 pt-1">
              Where is Your Company Based?
            </p>
            <p className="text-base text-left text-white para-font leading-relaxed">
              Confirm where your business is located.
            </p>
          </div>
        </div>
      </div>
      <div className="xl:block md:hidden lg:block hidden xl:-mt-3 lg:-mt-7">
        <progress
          className="progress-bar xl:ml-36 lg:ml-31 md:ml-20 xl:mt-0 md:mt-8"
          value="25"
          max="100"
        ></progress>
      </div>
      <div className="xl:hidden md:block lg:hidden hidden md:-mt-3">
        <progress
          className="progress-md-bar xl:ml-36 md:ml-16 md:mt-0"
          value="25"
          max="100"
        ></progress>
      </div>
      <div className="bg-white rounded shadow-lg mt-1 xl:mx-36 lg:mx-31 md:mx-15 xl:p-12 p-4 md:p-4 lg:p-4 xl:mb-20 mb-4 lg:mb-12 md:mb-8 xl:h-500">
        <div className="xl:px-16">
          <div className="xl:pl-0 lg:pl-8 md:pl-6 pl-0">
            <p className="font text-dark-blue xl:text-xl lg:text-lg font-semibold md:text-lg text-lg">
              Province
            </p>
            <p className="text-base text-light-blue para-font leading-relaxed pt-4">
              My company is located in
              <select
                className="xl:ml-2 mt-2 md:ml-2 rounded xl:w-5/12 pl-2 pr-7 py-0 border-grey-100 text-xs xl:text-base ml-0 hover:bg-black h-8 xl:h-10"
                value={corporateLocation}
                onChange={changeCompanyLocation}
              >
                <option value="">Select Location</option>
                <option value="ALBERTA">ALBERTA</option>
                <option value="BRITISH COLUMBIA">BRITISH COLUMBIA</option>
                <option value="MANITOBA">MANITOBA</option>
                <option value="NEW BRUNSWICK">NEW BRUNSWICK</option>
                <option value="NEWFOUNDLAND and LABRADOR">
                  NEWFOUNDLAND and LABRADOR
                </option>
                <option value="NORTHWEST TERRITORIES">
                  NORTHWEST TERRITORIES
                </option>
                <option value="NOVA SCOTIA">NOVA SCOTIA</option>
                <option value="NUNAVUT">NUNAVUT</option>
                <option value="ONTARIO">ONTARIO</option>
                <option value="PRINCE EDWARD ISLAND">
                  PRINCE EDWARD ISLAND
                </option>
                <option value="QUEBEC">QUEBEC</option>
                <option value="SASKATCHEWAN">SASKATCHEWAN</option>
                <option value="YUKON">YUKON</option>
              </select>
            </p>
            <p style={{ color: "red" }}>{corporateLocationError}</p>
            <div className="mt-8 xl:mt-8">
              <button
                className="buttonWithBlue text-white xl:px-10 xl:py-2 py-1 px-8 rounded mr-4"
                onClick={onPrevious}
              >
                Previous
              </button>
              <button
                className="no-outline focus:outline-none buttonWithBlue text-white xl:px-10 xl:py-2 py-1 px-8 rounded mr-4"
                onClick={onNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step2 = ({
  onPrevious,
  onNext,
  corporateType,
  setCorporateType,
  corporateName,
  setCorporateName,
  corporateNameError,
  corporateSuffix,
  setCorporateSuffix,
}) => {
  // When drop down click change content

  const handleCompanyTypeChange = (e) => {
    setCorporateType(e.target.value);
  };

  const handleCompanyNameChange = (e) => {
    setCorporateName(e.target.value);
  };

  const handleCompanySuffixChange = (e) => {
    setCorporateSuffix(e.target.value);
  };

  return (
    <div>
      <div className="flex flex-wrap justify-center">
        <div className="flex xl:mt-20 mt-32 bg-bluee-700 w-full lg:w-9/12 md:w-10/12 px-6 py-4 rounded">
          <div>
            <img src={one} className="xl:w-20 w-16 h-auto" />
          </div>
          <div className=" xl:ml-8 ml-4">
            <p className="figtree xl:text-4xl font-bold lg:text-3xl md:text-2xl text-2xl text-white xl:pt-2 pt-1">
              Start a Corporation
            </p>
            <p className="text-base text-left text-white para-font leading-relaxed">
              Choose a name for your company or start a numbered corporation.
            </p>
          </div>
        </div>
      </div>
      <div className="xl:block md:hidden lg:block hidden xl:-mt-3 lg:-mt-7">
        <progress
          className="progress-bar xl:ml-36 lg:ml-31 md:ml-20 xl:mt-0 md:mt-8"
          value="50"
          max="100"
        ></progress>
      </div>
      <div className="xl:hidden md:block lg:hidden hidden md:-mt-3">
        <progress
          className="progress-md-bar xl:ml-36 md:ml-16 md:mt-0"
          value="50"
          max="100"
        ></progress>
      </div>
      <div className="bg-white rounded shadow-lg mt-1 xl:mx-36 lg:mx-31 md:mx-15 xl:p-12 p-4 md:p-4 lg:p-4 xl:mb-20 mb-4 lg:mb-12 md:mb-8  xl:h-500">
        <div className=" xl:px-16">
          <div className="xl:pl-0 lg:pl-8 md:pl-6 pl-0">
            <p className="font text-dark-blue xl:text-xl lg:text-lg font-semibold md:text-lg text-lg">
              About Your Company
            </p>
            <div className="flex xl:mt-6 mt-4">
              <p className="md:pt-1 text-base text-light-blue para-font leading-relaxed">
                I want a company
              </p>
              <select
                className="xl:w-4/12 md:w-3/12 xl:ml-2 border-grey-100 xl:mr-2 text-sm text-light-blue para-font leading-relaxed ml-2 rounded xl:h-10 lg:h-10 md:h-10 h-10 pl-2 pr-7"
                value={corporateType}
                onChange={handleCompanyTypeChange}
              >
                <option value="name" className="text-sm">
                  Named
                </option>
                <option value="number" className="text-sm">
                  Numbered
                </option>
              </select>
            </div>

            {corporateType === "name" && (
              <p className="xl:mt-6 mt-4 xl:mb-0 mb-4 text-base text-light-blue para-font leading-relaxed">
                My company will be called
                <input
                  type="text"
                  className="xl:w-4/12 lg:w-4/12 md:w-3/12 w-full xl:ml-2 md:ml-2 ml-0 xl:mr-2 text-sm border-grey-100 xl:mt-2 mt-1 xl:h-10 lg:h-10 md:h-10 h-10 rounded"
                  placeholder="Company name"
                  value={corporateName}
                  onChange={handleCompanyNameChange}
                />
                <select
                  className="xl:w-3/12 border-r border-grey-100 xl:ml-0 md:ml-2 ml-0 xl:mt-2 text-sm mt-1 xl:h-10 lg:h-10 md:h-10 h-10 rounded px-4"
                  value={corporateSuffix}
                  onChange={handleCompanySuffixChange}
                >
                  <option value="">Select</option>
                  <option value="Inc.">Inc.</option>
                  <option value="Ltd.">Ltd.</option>
                  <option value="Corp.">Corp.</option>
                  <option value="Limited">Limited</option>
                  <option value="Incorporated">Incorporated</option>
                  <option value="Corporation">Corporation</option>
                </select>
              </p>
            )}
            <p style={{ color: "red" }}>{corporateNameError}</p>
            <div className="mt-6">
              <button
                className="buttonWithBlue text-white xl:px-10 xl:py-2 py-1 px-8 rounded mr-4"
                onClick={onPrevious}
              >
                Previous
              </button>
              <button
                className="no-outline focus:outline-none buttonWithBlue text-white xl:px-10 xl:py-2 py-1 px-8 rounded mr-4"
                onClick={onNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step3 = ({
  onPrevious,
  onNext,
  handleSubmit,
  name,
  email,
  password,
  setName,
  setEmail,
  setPassword,
  loginWithGoogle,
}) => {
  return (
    <div>
      <div className="flex flex-wrap justify-center">
        <div className="flex xl:mt-20 mt-32 bg-bluee-700  w-full lg:w-9/12 md:w-10/12 px-6 py-4 rounded">
          <div>
            <img src={one} className="xl:w-20 w-16 h-auto" />
          </div>
          <div className=" xl:ml-8 ml-4">
            <p className="figtree xl:text-4xl font-bold lg:text-3xl md:text-2xl text-2xl text-white xl:pt-2 pt-1">
              Create Your Account
            </p>
            <p className="text-base text-left text-white para-font leading-relaxed">
              Create your account in iBusiness
            </p>
          </div>
        </div>
      </div>
      <div className="xl:block md:hidden lg:block hidden xl:-mt-3 lg:-mt-7">
        <progress
          className="progress-bar xl:ml-36 lg:ml-31 md:ml-20 xl:mt-0 md:mt-8"
          value="75"
          max="100"
        ></progress>
      </div>
      <div className="xl:hidden md:block lg:hidden hidden md:-mt-3">
        <progress
          className="progress-md-bar xl:ml-36 md:ml-16 md:mt-0"
          value="75"
          max="100"
        ></progress>
      </div>
      <div className="bg-white rounded shadow-lg mt-1 xl:mx-36 lg:mx-31 md:mx-15 xl:p-12 p-4 md:p-4 lg:p-4 xl:mb-20 mb-4 lg:mb-12 md:mb-8  xl:h-600">
        <div className="xl:px-16">
          <p className="font text-dark-blue xl:text-xl lg:text-lg font-semibold md:text-lg text-lg">
            Let’s Get Your Information
          </p>
          <p className="text-base text-light-blue para-font leading-relaxed pt-4 pb-1">
            Once you set up your account, you can establish your corporation,
            select your plan.
          </p>
          <div className="grid xl:grid-cols-1 md:grid-cols-1 gap-2 xl:gap-4 lg:gap-2 md:gap-2  grid-cols-1 mb-2">
            <input
              type="text"
              className="rounded border-grey-100 font md:text-xs xl:text-base"
              placeholder="First Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-2 xl:gap-4 lg:gap-2 md:gap-2  grid-cols-1 mb-2">
            <input
              type="email"
              className="rounded border-grey-100 font md:text-xs xl:text-base"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              className="rounded border-grey-100 font md:text-xs xl:text-base"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="flex">
            {/* <input
              type="checkbox"
              id="dummyCheckbox"
              className="checkbox rounded mt-1"
              checked
            /> */}
            <p className="ml-0 font" htmlFor="dummyCheckbox">
              By signing up, you agree to iBusiness Terms of Use and Privacy
              Policy.
            </p>
          </div>
          <div className="mt-6 ">
            <button
              className="buttonWithBlue text-white xl:px-10 xl:py-2 py-1 px-8 rounded mr-4"
              onClick={onPrevious}
            >
              Previous
            </button>

            <button
              className="buttonWithBlue text-white xl:px-10 xl:py-2 py-1 px-8 rounded mr-4"
              onClick={handleSubmit}
            >
              Sign Up
            </button>
          </div>

          <div className="flex mt-6 border-b pb-16 mb-3 mb-10">
            <div className="">
              <button
                className="focus:outline-none border-bluee py-2 px-16 md:px-9 xl:px-14 flex rounded "
                type="button"
                onClick={loginWithGoogle}
              >
                <div>
                  <img
                    src={google}
                    className="xl:w-6 md:w-6 w-4"
                  />
                </div>
                <div className="font xl:pl-4 md:pl-4 pl-1 text-light-blue xl:text-base md:text-base text-xs">
                  Continue with Google
                </div>
              </button>
            </div>
          </div>
          <a href="" className="pt-2 text-center">
            <p className="font font-medium text-sm">
              Already have an account?
              <a href="/login">
                <span className="forgot-password text-dark-blue font-semibold pl-2">
                  Login
                </span>
              </a>
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

const Step4 = ({
  onPrevious,
  onNext,
  corporateName,
  setCorporateName,
  corporateNameError,
}) => {
  return (
    <div>
      <div className="flex flex-wrap justify-center">
        <div className="flex xl:mt-20 mt-32 bg-bluee-700  w-full lg:w-9/12 md:w-10/12 px-6 py-4 rounded">
          <div>
            <img src={one} className="xl:w-20 w-16 h-auto" />
          </div>
          <div className=" xl:ml-8 ml-4">
            <p className="figtree xl:text-4xl font-bold lg:text-3xl md:text-2xl text-2xl text-white xl:pt-2 pt-1">
              Start a Sole Proprietorship
            </p>
            <p className="text-base text-left text-white para-font leading-relaxed">
              As a first step, choose a name for your business.
            </p>
          </div>
        </div>
      </div>
      <div className="xl:block md:hidden lg:block hidden xl:-mt-3 lg:-mt-7">
        <progress
          className="progress-bar xl:ml-36 lg:ml-31 md:ml-20 xl:mt-0 md:mt-8"
          value="100"
          max="100"
        ></progress>
      </div>
      <div className="xl:hidden md:block lg:hidden hidden md:-mt-3">
        <progress
          className="progress-md-bar xl:ml-36 md:ml-16 md:mt-0"
          value="100"
          max="100"
        ></progress>
      </div>
      <div className="bg-white rounded shadow-lg mt-1 xl:mx-36 lg:mx-31 md:mx-15 xl:p-12 p-4 md:p-4 lg:p-4 xl:mb-20 mb-4 lg:mb-12 md:mb-8 xl:h-500">
        <div className=" xl:px-16">
          <p className="font text-dark-blue xl:text-xl lg:text-lg font-semibold md:text-lg text-lg">
            About Your Company
          </p>
          <div className="md:flex flex-none xl:flex lg:flex mt-4">
            <div>
              <p className="text-base text-light-blue pt-2 para-font leading-relaxed">
                My company will be called
              </p>
            </div>
            <div>
              <input
                required
                className="md:ml-4 ml-0 md:mt-0 mt-2 rounded border-grey-100"
                type="text"
                value={corporateName}
                onChange={(e) => setCorporateName(e.target.value)}
              />
            </div>
          </div>
          {/* <h2>Step 4</h2> */}
          <div className="md:mt-4 mt-8">
            <button
              className="buttonWithBlue text-white xl:px-10 xl:py-2 py-1 px-8 rounded mr-4"
              onClick={onPrevious}
            >
              Previous
            </button>
            <button
              className="no-outline focus:outline-none buttonWithBlue text-white xl:px-10 xl:py-2 py-1 px-8 rounded mr-4"
              onClick={onNext}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CorporateWizard = () => {
  const [step, setStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showSteps, setShowSteps] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [corporateLocation, setCorporateLocation] = useState("");
  const [corporateType, setCorporateType] = useState("name");
  const [corporateName, setCorporateName] = useState("");
  const [corporateSuffix, setCorporateSuffix] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [corporateLocationError, setCorporateLocationError] = useState("");
  const [corporateNameError, setCorporateNameError] = useState("");
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState("")

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => toast.error(error.message),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    if (profile) {
      if (profile !== undefined) {
        console.log("profile : " + profile.email);
        const formData = {
          corporate_type: selectedOption,
          corporate_location: corporateLocation,
          corporate_naming_type: corporateType,
          corporate_name: corporateName,
          corporate_suffix: corporateSuffix,
          name: profile.name,
          email: profile.email,
          password: profile.id,
        };
        axios
          .post(
            "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/corporate-wizard",
            formData
          )
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("is_logged_in", "true");
                setSelectedOption("");
                setCorporateLocation("");
                setCorporateType("");
                setCorporateName("");
                setCorporateSuffix("");
                setName("");
                setEmail("");
                setPassword("");
                window.location = "/thank-you";
              } else {
                if (response.data.errors.email) {
                  toast.error(response.data.errors.email[0]);
                } else if (response.data.errors.password) {
                  toast.error(response.data.errors.password[0]);
                }
              }
            }
          });
      }
    }
  }, [profile]);

  function createCorporateWizard()
  {
    setLoadingMessage("Updating Details")
    setLoading(true)
    const formData = {
      corporate_type: selectedOption,
      corporate_location: corporateLocation,
      corporate_naming_type: corporateType,
      corporate_name: corporateName,
      corporate_suffix: corporateSuffix,
    };
    let token = localStorage.getItem("token")
    axios
      .post(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/corporate-wizard-user",
        formData,
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setSelectedOption("");
            setCorporateLocation("");
            setCorporateType("");
            setCorporateName("");
            setCorporateSuffix("");
            setLoading(false)
            window.location = "/thank-you";
          } else {
            setLoading(false)
            toast.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log("Error is " + error)
      });
  }

  const onNext = () => {
    if (selectedOption == "Incorporation") {
      if (step == 1) {
        if (corporateLocation.trim() == "") {
          toast.error("Please select Location");
        } else {
          setStep((prevStep) => prevStep + 1);
        }
      } else if (step == 2) {
        if (corporateType == "name") {
          if (corporateName.trim() == "") {
            toast.error("Please enter company name");
          } else if (corporateSuffix.trim() == "") {
            toast.error("Please select company suffix");
          } else {
            if(isLoggedIn)
            {
              createCorporateWizard();
            }
            else
            {
              setStep((prevStep) => prevStep + 1);
            }
          }
        } else if (corporateType == "number") {
          if(isLoggedIn)
          {
            createCorporateWizard();
          }
          else
          {
            setStep((prevStep) => prevStep + 1);
          }
        }
      }
    } else if (selectedOption == "Sole Proprietorship") {
      if (step == 1) {
        if (corporateName.trim() == "") {
          toast.error("Please enter corporate name");
        } else {
          if(isLoggedIn)
          {
            createCorporateWizard();
          }
          else
          {
            setStep((prevStep) => prevStep + 1);
          }
        }
      }
    }
  };

  const onPrevious = () => {
    if (step === 1) {
      setShowSteps(false);
      setSelectedOption("");
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    const isLoggedInValue = localStorage.getItem("is_logged_in");
    setIsLoggedIn(isLoggedInValue ? JSON.parse(isLoggedInValue) : false);
  }, []);

  const handleSubmit = () => {
    if (name.trim() == "") {
      toast.error("Please enter your name");
    } else if (email.trim() == "") {
      toast.error("Please enter your email");
    } else if (password.trim() == "") {
      toast.error("Please enter your password");
    } else {
      setLoadingMessage("Updating Details...");
      setLoading(true)
      const formData = {
        corporate_type: selectedOption,
        corporate_location: corporateLocation,
        corporate_naming_type: corporateType,
        corporate_name: corporateName,
        corporate_suffix: corporateSuffix,
        name: name,
        email: email,
        password: password,
      };
      axios
        .post(
          "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/corporate-wizard",
          formData
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("is_logged_in", "true");
              setSelectedOption("");
              setCorporateLocation("");
              setCorporateType("");
              setCorporateName("");
              setCorporateSuffix("");
              setName("");
              setEmail("");
              setPassword("");
              setLoading(false)
              window.location = "/thank-you";
            } else {
              setLoading(false)
              if (response.data.errors.email) {
                toast.error(response.data.errors.email[0]);
              } else if (response.data.errors.password) {
                toast.error(response.data.errors.password[0]);
              }
            }
          }
        });
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setShowSteps(true);
    setStep(1);
  };

  useEffect(() => {
    let timer;
    if (showThankYou) {
      timer = setTimeout(() => {
        setShowThankYou(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showThankYou]);

  // view more

  const [showMore, setShowMore] = useState(false);

  const handleViewMore = () => {
    setShowMore(true);
  };

  return (
    <>
    <Loader loading={loading} text={loadingMessage} />
    <div>
      <IndexNavbar />
      <div className="wizard-bg-color">
        <div className="container mx-auto xl:px-16 px-4 overflow-hidden xl:mt-20">
          {!showSteps && (
            <div className="xl:pt-8">
              <div className="flex flex-wrap justify-center">
                <div className="flex xl:mt-12 mt-32 bg-bluee-700 shadow-lg w-full lg:w-9/12 md:w-10/12 px-6 py-4 rounded">
                  <div>
                    <img src={one} className="xl:w-20 w-16 h-auto" />
                  </div>
                  <div className="xl:ml-8 ml-4">
                    <p className="figtree xl:text-4xl font-bold lg:text-3xl md:text-2xl text-2xl text-white xl:pt-2 pt-1">
                      Let's Get Started
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded shadow-lg mt-1 xl:mx-36 lg:mx-31 lg:mb-16 md:mb-12 md:mx-15 xl:p-12 md:p-2 p-8 xl:mb-28 mb-4 ">
                <p className="xl:pl-12 lg:pl-12 md:pl-12 xl:pt-0 lg:pt-4 font text-dark-blue xl:text-xl lg:text-lg font-semibold md:text-lg text-lg">
                  Compare Your Options
                </p>
                <p className="xl:pl-12 lg:pl-12 md:pl-12 text-base text-light-blue para-font leading-relaxed xl:pt-4 pt-2">
                  Different business structures come with different legal and
                  tax benefits for entrepreneurs.
                </p>

                {/* Table content start */}
                <div className="xl:block lg:block md:block sm:block hidden">  
                  <div className="overflow-x-auto xl:px-12 lg:px-12 md:px-12 md:mb-8 md:mt-4 mt-4 lg:mt-8 xl:mb-12 xl:mt-8 lg:mb-4">
                    {/* header */}
                    <div className="flex border-tlr xl:mt-4 rounded-t xl:w-12/12 lg:w-12/12 md:w-12/12 w-12/12">
                      <div className="pl-4 pt-5 p-2 xl:w-5/12 lg:w-5/12 md:w-5/12 w-5/12 border-r xl:text-sm text-xs font-semibold text-center text-light-blue font leading-relaxed">
                        Description
                      </div>
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-3/12 border-r xl:p-2 p-2 xl:text-sm text-xs font-semibold text-center text-light-blue font leading-relaxed">
                        <p>🏢</p>Incorporation
                      </div>
                      <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-4/12 xl:p-2 p-2 xl:text-sm text-xs font-semibold text-center text-light-blue font leading-relaxed">
                        <p>🛍️</p> Sole Proprietorship
                      </div>
                    </div>

                    {/* content */}

                    <div className="flex bg-table  border-tlr xl:w-12/12 lg:w-12/12 md:w-12/12 w-12/12">
                      <div className="pl-4 p-2 xl:w-5/12 lg:w-5/12 md:w-5/12 w-5/12 border-r text-left xl:text-base text-sm font-medium text-light-blue font leading-relaxed">
                        Limited personal liability
                      </div>
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-3/12 border-r xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                        ✔
                      </div>
                      <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-4/12 xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                        ✖
                      </div>
                    </div>
                    <div className="flex border-tlr xl:w-12/12 lg:w-12/12 md:w-12/12 w-12/12">
                      <div className="pl-4 p-2 xl:w-5/12 lg:w-5/12 md:w-5/12 w-5/12 border-r text-left xl:text-base text-sm font-medium text-light-blue font leading-relaxed">
                        Operate under a business name
                      </div>
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-3/12 border-r xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                        ✔
                      </div>
                      <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-4/12 xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                        ✔
                      </div>
                    </div>
                    <div className="flex bg-table  border-blr xl:w-12/12 lg:w-12/12 md:w-12/12 w-12/12">
                      <div className="pl-4 p-2 xl:w-5/12 lg:w-5/12 md:w-5/12 w-5/12 border-r text-left xl:text-base text-sm font-medium text-light-blue font leading-relaxed">
                        Lower corporation tax rate
                      </div>
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-3/12 border-r xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                        ✔
                      </div>
                      <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-4/12 xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                        ✖
                      </div>
                    </div>

                    {/* this content should display */}

                    <div>
                      <div className="flex border-lr xl:w-12/12 lg:w-12/12 md:w-12/12 w-12/12">
                        <div className="pl-4 p-2 xl:w-5/12 lg:w-5/12 md:w-5/12 w-5/12 border-r text-left xl:text-base text-sm font-medium text-light-blue font leading-relaxed">
                          Business name protection
                        </div>
                        <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-3/12 border-r xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                          ✔
                        </div>
                        <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-4/12 xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                          ✖
                        </div>
                      </div>

                      <div className="flex bg-table  border-tlr xl:w-12/12 lg:w-12/12 md:w-12/12 w-12/12">
                        <div className="pl-4 p-2 xl:w-5/12 lg:w-5/12 md:w-5/12 w-5/12 border-r text-left xl:text-base text-sm font-medium text-light-blue font leading-relaxed">
                          Have multiple owners
                        </div>
                        <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-3/12 border-r xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                          ✔
                        </div>
                        <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-4/12 xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                          ✖
                        </div>
                      </div>
                      <div className="flex border-tlr xl:w-12/12 lg:w-12/12 md:w-12/12 w-12/12">
                        <div className="pl-4 p-2 xl:w-5/12 lg:w-5/12 md:w-5/12 w-5/12 border-r text-left xl:text-base text-sm font-medium text-light-blue font leading-relaxed">
                          Simple tax structure
                        </div>
                        <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-3/12 border-r xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                          ✖
                        </div>
                        <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-4/12 xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                          ✔
                        </div>
                      </div>
                      <div className="flex bg-table rounded-b border-blr xl:w-12/12 lg:w-12/12 md:w-12/12 w-12/12">
                        <div className="pl-4 p-2 xl:w-5/12 lg:w-5/12 md:w-5/12 w-5/12 border-r text-left xl:text-base text-sm font-medium text-light-blue font leading-relaxed">
                          Obtain GST/HST number
                        </div>
                        <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-3/12 border-r xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                          ✔
                        </div>
                        <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-4/12 xl:p-2 p-2 text-center text-base font-medium text-light-blue-light font leading-relaxed">
                          ✔
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:hidden lg:hidden md:hidden sm:hidden block">
                  <div className="grid xl:grid-cols-2 grid-cols-2 mt-8">
                    <button className="font-medium">Incorporation</button>
                    <button className="font-medium">Sole Proprietorship</button>
                  </div>
                  </div>
                <div className="pl-4">
                  {!showSteps && (
                    <div className="grid grid-cols-2 gap-3 xl:-mt-8 lg:mt-4 xl:pl-84 lg:pb-4 md:pb-4 lg:pl-80 md:pl-52">
                      <div className="xl:p-0 p-2 rounded-xl xl:pl-0 lg:pl-0 md:pl-10">
                        <label id="incorporation" className="cursor-pointer">
                          <div className="flex ">
                            <button
                              className="border-blue-dark bg-button-white text-blue xl:px-8 xl:py-1 py-1 px-8 rounded mr-4"
                              onClick={() => handleSelect("Incorporation")}
                            >
                              Select
                            </button>
                          </div>
                        </label>
                      </div>
                      <div className="xl:p-0 p-2 rounded-xl">
                        <label
                          id="soleProprietorship"
                          className="cursor-pointer"
                        >
                          <div className="flex">
                            <button
                              className="border-blue-dark bg-button-white xl:-ml-4 text-blue xl:px-8 xl:py-1 py-1 px-8 rounded mr-4"
                              onClick={() =>
                                handleSelect("Sole Proprietorship")
                              }
                            >
                              Select
                            </button>
                          </div>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {showSteps && (
            <div>
              {selectedOption === "Incorporation" && (
                <>
                  {step === 1 && (
                    <Step1
                      onNext={onNext}
                      onPrevious={onPrevious}
                      corporateLocation={corporateLocation}
                      setCorporateLocation={setCorporateLocation}
                      corporateLocationError={corporateLocationError}
                    />
                  )}
                  {step === 2 && (
                    <Step2
                      onPrevious={onPrevious}
                      onNext={onNext}
                      corporateType={corporateType}
                      setCorporateType={setCorporateType}
                      corporateName={corporateName}
                      setCorporateName={setCorporateName}
                      corporateNameError={corporateNameError}
                      corporateSuffix={corporateSuffix}
                      setCorporateSuffix={setCorporateSuffix}
                    />
                  )}
                  {step === 3 && (
                    <Step3
                      onPrevious={onPrevious}
                      onNext={onNext}
                      name={name}
                      setName={setName}
                      email={email}
                      setEmail={setEmail}
                      password={password}
                      setPassword={setPassword}
                      handleSubmit={handleSubmit} // Pass handleSubmit as a prop
                      loginWithGoogle={loginWithGoogle}
                    />
                  )}
                </>
              )}
              {selectedOption === "Sole Proprietorship" && (
                <>
                  {step === 1 && (
                    <Step4
                      onNext={onNext}
                      onPrevious={onPrevious}
                      corporateName={corporateName}
                      setCorporateName={setCorporateName}
                      corporateNameError={corporateNameError}
                    />
                  )}
                  {step === 2 && (
                    <Step3
                      onPrevious={onPrevious}
                      onNext={onNext}
                      name={name}
                      setName={setName}
                      email={email}
                      setEmail={setEmail}
                      password={password}
                      setPassword={setPassword}
                      handleSubmit={handleSubmit}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default CorporateWizard;
