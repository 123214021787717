import IndexNavbar from "components/Navbars/IndexNavbar";
import React, { useEffect, useState } from "react";
import loginimage from "assets/img/loginimage.svg";
import axios from "axios";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import Loader from "components/Pages/Loader";
import google from "assets/img/google.png";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => toast.error(error.message),
  });

  const handleEmailFocus = () => {
    setEmailFocused(true);
  };

  const handleEmailBlur = () => {
    setEmailFocused(false);
  };

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setPasswordFocused(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    const isLoggedInValue = localStorage.getItem("is_logged_in");
    setIsLoggedIn(isLoggedInValue ? JSON.parse(isLoggedInValue) : false);
  }, []);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    if (profile) {
      if (profile != undefined) {
        const formData = { email: profile.email, password: profile.id };
        axios
          .post(
            "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/login",
            formData
          )
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("is_logged_in", "true");
                window.location = "/";
                setError("");
                setErrors([]);
              } else {
                setErrors(response.data.errors);
                toast.error(
                  response.data.message != "Validation Error"
                    ? response.data.message
                    : ""
                );
              }
            }
          });
      }
    }
  }, [profile]);

  useEffect(() => {
    if (isLoggedIn) {
      window.location = "/sidebar-landing";
    }
  }, [isLoggedIn]);

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingMessage("Signing In...");
    const formData = { email: email, password: password };
    axios
      .post(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/login",
        formData
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("is_logged_in", "true");
            setIsLoggedIn(true);
            window.location = "/sidebar-landing";
            setError("");
            setErrors([]);
            setLoading(false);
            setLoadingMessage("");
          } else {
            localStorage.setItem("is_logged_in", "false");
            setErrors(response.data.errors);
            setError(
              response.data.message != "Validation Error"
                ? response.data.message
                : ""
            );
            setLoading(false);
            setLoadingMessage("");
          }
        }
      });
  };

  const handleLanguageToggle = () => {
    // Toggle the display of the dropdown content
    const dropdownContent = document.getElementById("dropdown-content");
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
    } else {
      dropdownContent.style.display = "block";
    }
  };

  return (
    <>
      <Loader loading={loading} text={loadingMessage} />
      <section>
        <IndexNavbar />
        <div className="overflow-hidden ">
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 xl:gap-8 lg:gap-4 md:gap-4 gap-4">
            <div className=" bg-extra-light-grey">
              <div className="xl:ml-16 lg:ml-0 md:ml-0 ml-0 xl:px-32 md:px-56 lg:px-8 px-4 xl:pt-44 lg:pt-52 md:pt-40 pt-48 px-12 xl:pb-8 lg:pb-64 md:pb-52 pb-64">
                <h1 className="font text-dark-blue xl:text-2xl lg:text-3xl md:text-3xl text-3xl font-semibold xl:pt-12">
                  Login
                </h1>
                <div className="login-form xl:pt-8 pt-4 md:pt-4 xl:pr-16">
                  <div className="input-container cursor-pointer xl:w-12/12 w-full lg:w-6/12 md:w-full">
                    {/* <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        onFocus={handleEmailFocus}
                        onBlur={handleEmailBlur}
                        required
                        className={
                          emailFocused || email
                            ? "active rounded-md border-grey-100"
                            : "rounded-md border-grey-100 "
                        }
                      />
                      <label className="rounded-md font flex">
                        <img
                          src="https://i.ibb.co/c6Rbp1T/email-4546924.png"
                          className="w-4 h-4 mt-1"
                        ></img>
                        <p className="pl-2">Email</p>
                      </label> */}
                    <input
                      type="email"
                      placeholder="Enter Email"
                      className="rounded-md border-grey-100 border-on-hover w-full"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <p style={{ color: "red" }}>
                    {errors && errors.email && errors.email[0]}
                  </p>
                  <div className="input-container cursor-pointer xl:w-12/12 w-full lg:w-6/12 md:w-full">
                    <input
                      type="password"
                      placeholder="Enter Password"
                      className="rounded-md border-grey-100 w-full"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {/* <input
                        type="text"
                        value={password}
                        onChange={handlePasswordChange}
                        onFocus={handlePasswordFocus}
                        onBlur={handlePasswordBlur}
                        required
                        className={
                          passwordFocused || password
                            ? "active rounded-md border-grey-100"
                            : "rounded-md border-grey-100"
                        }
                      />
                      <label className="rounded-md font flex">
                        <img
                          src="https://i.ibb.co/kqsqf9h/padlock-2889676.png"
                          className="w-4 h-4 mt-1"
                        ></img>
                        <p className="pl-2 cursor-pointer">Password</p>
                      </label> */}
                  </div>
                  <p style={{ color: "red" }}>
                    {errors && errors.password && errors.password[0]}
                  </p>
                  {/* <div className="justify-between flex">
                      <p></p>
                      
                    </div> */}

                  <button
                    className="no-outline focus:outline-none rounded-md buttonWithBlue text-white xl:text-base lg:text-base text-base para-font font-medium xl:w-12/12 w-full lg:w-6/12 md:w-full px-8 py-2"
                    onClick={handleSubmit}
                  >
                    Login
                  </button>
                  <p style={{ color: "red" }}>{error}</p>
                  <div className="xl:w-12/12 w-full lg:w-6/12 md:w-full center-items">
                    <p className="font text-xs pl-3 pt-1 text-light-blue mt-4">
                      OR
                    </p>
                  </div>

                  <div className="xl:w-12/12 w-full lg:w-6/12 md:w-full mt-4 mb-10">
                    <div className="center-items">
                      <button
                        className="border-bluee focus:outline-none p-2 w-full center-items rounded"
                        type="button"
                        onClick={googleLogin}
                      >
                        <div className=" flex">
                          <div className="pl-1">
                            <img src={google} className="w-6" />
                          </div>
                          <div className="font text-xs pl-3 pt-1 text-light-blue ">
                            Continue with Google
                          </div>
                        </div>
                      </button>
                    </div>
                    {/* <div className="">
                      <button className="border-bluee p-2 flex rounded ">
                        <div className="pl-1">
                          <img
                            src="https://i.ibb.co/pj0vtRh/linkedin-icon-1024x1024-net2o24e.png"
                            className="w-6"
                          />
                        </div>
                        <div className="font text-xs pl-3 pt-1 text-light-blue ">
                          Continue with LinkedIn
                        </div>
                      </button>
                    </div> */}
                  </div>
                  <div className="mt-4 xl:w-5/12 ">
                    <a href="/reset-password">
                      <p className="forgot-password font text-dark-blue font-semibold text-sm">
                        Forgot Password?
                      </p>
                    </a>
                  </div>
                </div>
                <div className="justify-between flex border-t xl:mt-16 mt-8 lg:mt-12">
                  <a href="/reset-password" className="pt-2">
                    <p className="font font-medium text-sm">
                      Don't have an account?
                      <a href="corporate-wizard">
                        <span className="forgot-password text-dark-blue font-semibold pl-2">
                          Sign Up
                        </span>
                      </a>
                    </p>
                  </a>
                  {/* <div className="dropdown pt-2" id="dropdown">
                      <a
                        className="font"
                        href="#"
                        id="dropdown-toggle"
                        onClick={handleLanguageToggle}
                      >
                        Language »
                      </a>
                      <div className="dropdown-content" id="dropdown-content">
                        <a className="font p-5 mt-2" href="#">
                          English
                        </a>
                        <br></br>
                        <a className="font p-5 mt-2" href="#">
                          French
                        </a>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="xl:block lg:block md:hidden hidden">
              <div className="xl:pt-40 lg:pt-40 md:pt-0 pt-10 xl:pb-8 lg:pb-0 md:pb-0 pb-8 xl:px-16 px-4 xl:p-0 p-8">
                <img
                  src={loginimage}
                  className="xl:p-12 md:p-12 lg:p-0 p-0"
                ></img>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </section>
    </>
  );
}
