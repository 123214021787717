import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import service from "assets/img/icons/service.jpg";
import pocket from "assets/img/pocket.jpg";
import favicon from "assets/img/icons/favicon.png";
import logo from "assets/img/icons/logo.webp";
import Meetmarslab from "assets/img/images/Meetmarslab.png";
import cloudmigration from "assets/img/images/cloudmigration.png";
import assessment from "assets/img/images/assessment.png";
import passion from "assets/img/passion.jpg";
import customer from "assets/img/customer.jpg";
import bagbgall from "assets/img/bag-bg-all.jpg";
import compu from "assets/img/images/compu.png"
import cloudnew from "assets/img/images/cloudnew.jpg"
import cloudcomputing from "assets/img/images/cloudcomputing.avif"
import memoo from "assets/img/images/memoo.png"

export default function CloudMigration() {
  return (
    <section className="relative overflow-hidden">
      <IndexNavbar fixed />
      <div className="xl:mt-20 lg:mt-16 mt-16"></div>
      <div className="container xl:px-16 px-4 mx-auto relative overflow-hidden ">
        <div className="flex xl:w-12/12 w-12/12 md:12/12 xl:gap-4 xl:py-16">
          <div className="font xl:w-8/12 md:w-8/12">
            <div className="xl:pr-4">
              <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-4 pt-8 md:pt-12 xl:py-0">
                Cloud Migration
              </p>
            </div>

            <div className="xl:pr-4">
              <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
                Effortless Cloud Migration for Your Enterprise Embark on a
                seamless cloud adoption journey with Marslab Cloud Migration
                Services, empowering you to harness the transformative power of
                the cloud and achieve your business objectives. Our
                comprehensive suite of services encompasses expert assessment,
                workload migration, and secure, scalable cloud environment
                provisioning, ensuring a smooth and successful transition to the
                cloud.
              </p>
            </div>

            <div class="flex xl:w-12/12 border-red shadow-xl bg-transparent-black rounded-lg xl:mt-12 xl:p-3 mt-4">
              <div class="xl:w-2/12 xl:p-1">
                <img
                  src={favicon}
                  class="xl:h-16 h-8 w-32 mt-4 xl:mt-0 md:w-28"
                />
              </div>
              <div class="font xl:w-10/12 xl:pl-3 pl-4 pt-1">
                <p class="xl:text-sm text-white text-xs font">
                  Marslab is the only “visionary” again on the 2021 Gartner
                  Magic Quadrant for the CRM Customer Engagement Center.
                </p>

                <a href="">
                  <p class="text-danger xl:text-base text-xs xl:pt-2 pt-1">
                    Get the report
                  </p>
                </a>
              </div>
            </div>

            <div className="xl:hidden md:hidden block">
              <div className="font xl:mt-0 xl:pt-3 ml-12 my-4">
                <img
                  src={cloudmigration}
                  alt="Your browser does not support this image shadow-xl"
                  className="xl:h-400 md:240 h-250 rounded-xl"
                ></img>
              </div>
            </div>
          </div>
          <div className="xl:block md:block hidden">
            <div className="font xl:mt-0 xl:pt-8 md:pt-24">
              <img
                src={cloudmigration}
                alt="Your browser does not support this image shadow-xl"
                className="xl:h-400 md:240 h-250 rounded-xl"
              ></img>
            </div>
          </div>
        </div>
        <div className="flex xl:w-12/12 xl:gap-4">
          <div className="xl:w-5/12">
            <div className="xl:block md:block hidden">
              <div className="font xl:mt-0 xl:pt-12 md:pt-24">
                <img
                  src={memoo}
                  alt="Your browser does not support this image shadow-xl"
                  className="xl:h-300 md:240 h-250 rounded-xl"
                ></img>
              </div>
            </div>
          </div>
          <div className="xl:w-8/12">
            <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-16 pt-8 md:pt-12 xl:py-0">
              Expert Assessment:
            </p>

            <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
              <b> Deep Dive into Your IT Landscape:</b> Our experienced cloud
              consultants will thoroughly assess your current IT environment,
              applications, and data infrastructure to identify the optimal
              cloud migration strategy for your unique needs.
            </p>
            <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-4 pt-4">
              <b>Tailored Cloud Migration Plan:</b> We will craft a
              comprehensive cloud migration plan that aligns with your business
              goals, budget, and timeline, ensuring a seamless transition to the
              cloud.
            </p>
          </div>
        </div>

        <div className="flex xl:w-12/12 xl:gap-4 xl:mb-8 xl:pt-12">
          <div className="xl:w-5/12">
            <div className="xl:block md:block hidden">
              <div className="font xl:mt-0 xl:pt-0 md:pt-24">
                <img
                  src={service}
                  alt="Your browser does not support this image shadow-xl"
                  className="xl:h-400 md:240 h-250 rounded-xl"
                ></img>
              </div>
            </div>
          </div>
          <div className="xl:w-8/12">
            <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-16 pt-8 md:pt-12 xl:py-0">
              Workload Migration Expertise:
            </p>
            <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
              <b>Minimize Migration Disruptions:</b> Our proven migration
              methodologies minimize downtime, ensuring business continuity and
              uninterrupted operations throughout the migration process.
            </p>
            <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-4 pt-4">
              <b> Automated Migration Tools:</b> We leverage cutting-edge
              automation tools to streamline the migration process, reducing
              manual effort and minimizing errors.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${bagbgall})`,
          width: "100%",
          // height: "76.50vh",
          backgroundSize: "cover",
        }}
        className="xl:h-59 md:h-80 xl:pb-16 md:pb-8 pb-8"
      >
        <div className="xl:px-16 px-4 container mx-auto overflow-hidden text-white xl:pt-12 md:pt-8 pt-8">
          <p className="font xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-4 pt-8 md:pt-12 xl:py-0">
            Secure, Scalable Cloud Environments:
          </p>
          <p className="font xl:text-lg md:text-sm md:pt-4 xl:pt-8 pt-4">
            <b>Provider-Agnostic Approach: </b> We can migrate your workloads to
            any cloud provider of your choice, ensuring flexibility and choice
            in your cloud adoption journey.
          </p>
          <p className="font xl:text-lg md:text-sm md:pt-4 xl:pt-4 pt-4">
            <b>Secure Cloud Infrastructure: </b> We implement robust security
            measures to safeguard your data and applications in the cloud,
            ensuring compliance with industry standards and regulations.
          </p>
          <p className="font xl:text-lg md:text-sm md:pt-4 xl:pt-4 pt-4">
            <b>Scalable Cloud Architecture: </b> We design and implement cloud
            architectures that can scale to meet your evolving business needs,
            ensuring optimal performance and agility. With Marslab Cloud
            Migration Services, you can embark on your cloud adoption journey
            with confidence, knowing that your cloud migration is in the hands
            of experts. Partner with Marslab and experience the transformative
            power of the cloud.
          </p>
        </div>
      </div>

      <div className="xl:px-16 px-4 container mx-auto overflow-hidden">
        <div className="flex xl:w-12/12 xl:gap-4 xl:mb-8">
          <div className="xl:w-5/12">
            <div className="xl:block md:block hidden">
              <div className="font xl:pt-16 md:pt-24">
                <img
                  src={cloudnew}
                  alt="Your browser does not support this image shadow-xl"
                  className="xl:h-250 md:240 h-250 rounded-xl"
                ></img>
              </div>
            </div>
          </div>
          <div className="xl:w-8/12">
            <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-16 pt-8 md:pt-12 xl:py-0">
              Managed Cloud Services
            </p>
            <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
              Unlock the Transformative Power of Your IT Infrastructure with
              Marslab Managed Services In today's dynamic business landscape,
              organizations are constantly seeking ways to optimize their IT
              infrastructure and applications to achieve greater agility,
              innovation, and cost-efficiency. Marslab's unparalleled cloud and
              managed services solutions empower enterprises to harness the full
              potential of the cloud, while simultaneously simplifying IT
              management and enhancing security.
            </p>
          </div>
        </div>

        <div className="flex xl:w-12/12 xl:gap-4 xl:mb-8 xl:gap-4">
          <div className="xl:w-5/12">
            <div className="xl:block md:block hidden">
              <div className="font xl:mt-0 xl:pt-8 md:pt-24">
                <img
                  src={service}
                  alt="Your browser does not support this image shadow-xl"
                  className="xl:h-330 md:240 h-250 rounded-xl"
                ></img>
              </div>
            </div>
          </div>
          <div className="xl:w-8/12">
            <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-16 pt-8 md:pt-12 xl:py-0">
              Unleash the Cloud's Potential:
            </p>
            <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
              Marslab's comprehensive cloud expertise encompasses public,
              private, and hybrid cloud environments, enabling you to select the
              optimal cloud solution for your specific needs. Our seamless cloud
              adoption journey ensures a smooth transition and continuous
              optimization of your cloud environment.
            </p>
          </div>
        </div>

        <div className="flex xl:w-12/12 xl:gap-4 xl:mb-24">
          <div className="xl:w-5/12">
            <div className="xl:block md:block hidden">
              <div className="font xl:mt-0 xl:pt-16 md:pt-24">
                <img
                  src={cloudcomputing}
                  alt="Your browser does not support this image shadow-xl"
                  className="xl:h-250 md:240 h-250 rounded-xl"
                ></img>
              </div>
            </div>
          </div>
          <div className="xl:w-8/12">
            <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-16 pt-8 md:pt-12 xl:py-0">
              Maximize Efficiency with Managed Services
            </p>
            <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
              Marslab's managed services portfolio covers the entire IT stack,
              providing you with a single point of accountability and ensuring
              the seamless integration and management of your IT ecosystem. Our
              services streamline IT operations, reduce costs, and free up your
              team to focus on strategic initiatives.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}
