import axios from "axios";
import IndexNavBar2 from "components/Navbars/IndexNavBar2";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Loader from "components/Pages/Loader";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function ChangePassword() {

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('')

  const handlePasswordChange = () => {
    if(password.length < 5) 
    {
      toast.error("Password should have minimum 6 characters")
    }
    else if(newPassword.length < 5)
    {
      toast.error("Confirm password should have minimum 6 characters")
    }
    else if(password !== newPassword)
    {
      toast.error("Both passwords should be same")
    }
    else
    {
      setLoadingMessage("Changing password...")
      setLoading(true)
      let token = localStorage.getItem("token");
      if(token == null)
      {
        const params = new URLSearchParams(window.location.search);
        token = params.get('token');
      }
      axios.post("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/change-password", { password: password }, { headers: { Authorization: "Bearer " + token} })
      .then((response) => {
        if(response.status == 200)
        {
          if(response.data.status)
          {
            toast.success(response.data.message);
            setTimeout(() => {
              setLoading(false)
              window.location= '/login';
            },3000);
          }
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error(error);
      });
    }
  }

  return (
    <>
    <Loader loading={loading} text={loadingMessage} />
    <section>
      {/* <IndexNavbar /> */}
      <IndexNavBar2 />
      <div className="mx-auto overflow-hidden container  xl:pt-32 lg:pt-32 md:pt-32 px-4 pt-28 xl:pb-28 lg:pb-20 md:pb-12 pb-8 xl:px-36 lg:px-44 md:px-32">
        <div className="bg-white border-grey-50 rounded-md shadow-md xl:p-6 p-4 xl:mt-4 mt-4 ">
          <div className="">
            <div>
              <h1 className="figtree text-dark-blue xl:text-2xl lg:text-2xl font-bold md:text-2xl text-lg">
                Change Password
              </h1>
              <div className="xl:py-6 py-2 xl:pt-8 md:pt-8 lg:pt-4 pt-4">
                <div className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base ">
                  Password
                </div>
                <input
                  type="password"
                  placeholder="Password"
                  className="font border-grey-50 rounded-md text-xs xl:text-sm mt-2 w-4/12"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </div>
              <div className="xl:py-0 py-2">
                <div className="font text-dark-blue xl:text-base lg:text-base  font-medium md:text-base text-base ">
                  Confirm Password
                </div>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="font border-grey-50 rounded-md text-xs xl:text-sm mt-2 w-4/12"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div className="flex xl:pt-4 lg:pt-4 md:pt-0">
            <button className="buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-4 md:py-1 border rounded-lg red-new text-white" onClick={handlePasswordChange}>
              Change
            </button>
            <a href="/">
              <button className="ml-2 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-4 md:py-1 border rounded-lg red-new text-white">
                Cancel
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
