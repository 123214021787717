import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer.js";
import corporationservicenew from "assets/img/corporationservicenew.svg";
import itservicenew from "assets/img/itservicenew.svg";
import hrservicenew from "assets/img/hrservicenew.svg";
import ipservicenew from "assets/img/ipservicenew.svg";

const SolutionHomepage = () => {
  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-18 mt-18">
        <div className="">
          <div className="bg-grey-50">
            <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
              <a href="index" className="link">
                Home{" "}
              </a>{" "}
              ― Services
            </div>
          </div>
        </div>
        <div className="bg-extra-light-grey xl:pt-20 md:pt-12 pt-8 lg:pt-16">
          <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 ">
            <div className="align-items text-center">
              <p className="text-blue uppercase xl:text-base text-sm font-medium">
                ALL-IN-ONE PLATFORM
              </p>
              <p className="figtree xl:text-4xl font-bold lg:text-3xl md:text-2xl text-2xl text-center xl:px-16 lg:px-0 md:px-36 px-0 lg:pt-2 xl:pt-0 md:pt-2 pt-2">
                Empowering Entrepreneurs: Paving the Path to Success
              </p>
              <p className="xl:px-24 lg:px-44 pt-2 md:px-28 font text-light-blue text-left text-base xl:text-lg lg:text-lg leading-relaxed text-center">
                "iBusiness provides entrepreneurs with the tools they need at
                every stage of their journey. Start with hassle-free
                registration and continue to thrive with our comprehensive
                support."
              </p>
              <div className="grid xl:grid-cols-4 xl:pb-20 md:pb-12 pb-12 md:grid-cols-4 lg:grid-cols-4 grid-cols-1 xl:gap-4 lg:gap-4 md:gap-4 gap-4 xl:pt-12 pt-8">
                <a href="/corporate-services">
                  <div className="bg-white new-enterprises rounded-md xl:p-4 lg:p-4 md:p-2 p-4 cursor-pointer hover-move-right">
                    <p className="text-dark-blue xl:text-lg font font-semibold lg:text-sm md:text-sm xl:pt-4 pt-2 md:pt-2 lg:pt-4 text-lg">
                      Corporate Services
                    </p>
                    <p className="font text-base text-light-blue xl:pt-4 para-font md:pt-2 pt-2">
                      Incorporating a business abroad is complex due to diverse
                      tax laws, procedures, languages, and costs.
                    </p>
                    <div className="xl:pt-6 pt-4 pb-2 para-font">
                      <a
                        href="/corporate-services"
                        className="font text-base xl:text-base text-blue"
                      >
                        Learn More <span className="right-symbol">➜</span>
                      </a>
                    </div>
                    <img
                      src={corporationservicenew}
                      alt="First Image"
                      className="mt-4"
                    />
                  </div>
                </a>

                <a href="/it-services">
                  <div className="bg-white new-enterprises rounded-md xl:p-4 lg:p-4 md:p-2 p-4 cursor-pointer hover-move-right">
                    <p className="text-dark-blue xl:text-lg font font-semibold lg:text-sm md:text-sm xl:pt-4 pt-2 md:pt-2 lg:pt-4  text-xl">
                      IT Services
                    </p>
                    <p className="font text-base text-light-blue xl:pt-4 para-font md:pt-2 pt-2">
                      We provide full-scale IT services covering web design,
                      development, mobile app creation, and digital marketing.
                    </p>
                    <div className="xl:pt-6 pt-4 pb-2 para-font">
                      <a
                        href="/it-services"
                        className="font text-base xl:text-base text-blue"
                      >
                        Learn More <span className="right-symbol">➜</span>
                      </a>
                    </div>
                    <img src={itservicenew}></img>
                  </div>
                </a>

                <a href="/hr-service-homepage">
                  <div className="bg-white new-enterprises rounded-md xl:p-4 lg:p-4 md:p-2 p-4 cursor-pointer hover-move-right">
                    <p className="text-dark-blue xl:text-lg font font-semibold lg:text-sm md:text-sm xl:pt-4 pt-2 md:pt-2 lg:pt-4  text-xl">
                      HR Services
                    </p>
                    <p className="font text-base text-light-blue xl:pt-4 para-font md:pt-2 pt-2">
                      Our HR department expertly handles candidate recruitment,
                      training, and employee benefits programs.
                    </p>
                    <div className="xl:pt-6 pt-4 para-font">
                      <a
                        href="/hr-service-homepage"
                        className="font text-base xl:text-base text-blue"
                      >
                        Learn More <span className="right-symbol">➜</span>
                      </a>
                    </div>
                    <img src={hrservicenew} className="mt-1"></img>
                  </div>
                </a>

                {/* <a href="/ip-service-homepage">
                <div className="bg-white new-enterprises rounded-md xl:p-4 lg:p-4 md:p-2 p-4 cursor-pointer hover-move-right">
                  <p className="text-dark-blue xl:text-lg font font-semibold lg:text-sm md:text-sm xl:pt-4 pt-2 md:pt-2 lg:pt-4  text-xl">
                  IP Services
                  </p>
                  <p className="font text-base text-light-blue xl:pt-4 para-font md:pt-2 pt-2">
                  Intellectual property, such as patents, copyrights, and
                    trademarks, is legally protected.
                  </p>
                  <div className="xl:pt-6 pt-4 para-font">
                    <a
                      href="/ip-service-homepage"
                      className="font text-base xl:text-base text-blue"
                    >
                      Learn More <span className="right-symbol">➜</span>
                    </a>
                  </div>
                  <img src={ipservicenew} className="mt-1"></img>
                </div>
              </a> */}

                <a href="/ip-service-homepage">
                  <div className="bg-white new-enterprises rounded-md xl:p-4 lg:p-4 md:p-2 p-4 cursor-pointer hover-move-right">
                    <p className="text-dark-blue xl:text-lg font font-semibold lg:text-sm md:text-sm xl:pt-4 pt-2 md:pt-2 lg:pt-4  text-xl">
                      IP Services
                    </p>
                    <p className="font text-base text-light-blue xl:pt-4 para-font">
                      Intellectual property, such as patents, copyrights, and
                      trademarks, is legally protected.
                    </p>
                    <div className="xl:pt-12 md:pt-11 pt-4 para-font">
                      <a
                        href="/ip-service-homepage"
                        className="font text-base xl:text-base text-blue"
                      >
                        Learn More <span className="right-symbol">➜</span>
                      </a>
                    </div>
                    <img src={ipservicenew} className="mt-2"></img>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </section>
  );
};

export default SolutionHomepage;
