import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer.js";

const ThankYouPage = () => {

  //   Navigating to perticular page start
  const history = useHistory();

  useEffect(() => {
    // Redirect to '/sidebar-landing' after 5 seconds
    const timeout = setTimeout(() => {
      history.push("/sidebar-landing");
    }, 5000);

    // Clear timeout if the component unmounts or the user navigates away
    return () => {
      clearTimeout(timeout);
    };
  }, [history]);

  //   Navigating to perticular end

  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-18 mt-18">
        <div className="container mx-auto xl:px-16 px-4 overflow-hidden ">
          <div className="text-center xl:pt-36 lg:pt-36 md:pt-36 pt-32 pb-32">
            <div className="xl:text-5xl lg:text-5xl md:text-5xl text-4xl font-bold font"> Thank You ! </div>
            <p className="font xl:text-lg lg:text-lg  lg:px-52 xl:px-64 md:px-32 px-4 pt-4">
              Thank you for your payment! We appreciate your business and hope
              you enjoy our services. Have a great day!
            </p>
            <div className="center-button">
              <img
                src="https://i.ibb.co/RBBy1Kh/icons8-protect.gif"
                className="xl:w-20 lg:w-20 md:w-24 w-20 mt-4"
                alt="Thank You Image"
              />
            </div>
            <p className="font xl:text-lg xl:px-64 lg:text-lg pt-4">
              If you didn't receive any mail contact -{" "}
              <span className="text-blue-new">info@recrutementplus.ca</span>
            </p>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </section>
  );
};

export default ThankYouPage;
