import React from 'react';
import './Loader.css';

const Loader = ({ loading, text }) => {
  if (!loading) return null; // Don't render the loader if not loading

  return (
    <div className="loader">
      <div className="loader-content">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Loader;