import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-red-500 cursor-pointer`}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* <span className="xl:pl-24 ">{"<"}</span> */}
      {/* ﹤ */}
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-blue-500 cursor-pointer`}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      ﹥
    </div>
  );
};
const Cloudslider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    responsive: [
      {
        breakpoint: 1280, // desktop breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 1.9,
        },
      },
      {
        breakpoint: 900, // tablet landscape breakpoint
        settings: {
          slidesToShow: 1.35,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1.25,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section>
      {/* slide section */}
      <CustomPrevArrow />
      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 ">
        <Slider {...settings}>
          <div className="bg-ivory-black">
            <div className="xl:flex lg:flex xl:w-12/12">
              <div className="xl:w-4/12 md:w-12/12 md:p-12 lg:p-7 xl:p-4 ">
                <img
                  src="https://i.ibb.co/4jbn3Qr/technicians-in-server-room-16x9.jpg"
                  className="xl:h-40 "
                  alt="Your browser does not support this part content"
                />
              </div>
              <div className="xl:w-10/12 xl:pl-4 md:pl-4 pl-4 lg:pr-4 xl:py-4">
                <p className="text-white font xl:text-3xl lg:text-3xl md:text-4xl text-2xl font-thin md:font-thin xl:w-9/12 md:py-0 py-4 xl:py-0">
                  Outcome-driven technology consulting
                </p>
                <p className="text-white font xl:text-sm lg:text-xl md:text-lg text-xl xl:pt-4  md:py-8 py-4 xl:py-0">
                  Kyndryl Consult accelerates digital transformation by aligning
                  business outcomes with the technology that drives them.
                </p>
                <button className="buttoncoffeesmallneww text-white md:mb-8 xl:mb-0 mb-8">
                  Learn more →
                </button>
              </div>
            </div>
          </div>

          <div className="bg-ivory-black">
            <div className="xl:flex lg:flex xl:w-12/12">
              <div className="xl:w-4/12 md:w-12/12 md:p-12 lg:p-7 xl:p-4">
                <img
                  src="https://i.ibb.co/Jxjhj8J/Kyndryl-consult-promo-16x9.jpg"
                  className="xl:h-40 "
                  alt="Your browser does not support this part content"
                />
              </div>
              <div className="xl:w-10/12 xl:pl-4 md:pl-4 pl-4 lg:pr-4 xl:py-4">
                <p className="text-white font xl:text-3xl lg:text-3xl md:text-4xl text-2xl font-thin md:font-thin xl:w-9/12 md:py-0 py-4 xl:py-0">
                  Curiosity that fuels transformation
                </p>
                <p className="text-white font xl:text-sm lg:text-xl md:text-lg text-xl xl:pt-4  md:py-8 py-4 xl:py-0">
                  Our teams co-create with you to uncover and solve your most
                  complex business problems
                </p>
                <button className="buttoncoffeesmallneww text-white md:mb-8 xl:mb-0 mb-8">
                  Learn more →
                </button>
              </div>
            </div>
          </div>

          <div className="bg-ivory-black">
            <div className="xl:flex lg:flex xl:w-12/12">
              <div className="xl:w-4/12 md:w-12/12 md:p-12 lg:p-7 xl:p-4">
                <img
                  src="https://i.ibb.co/q0GzPYF/Kyndryl-bridge-promo-16x9.jpg"
                  className="xl:h-40 "
                  alt="Your browser does not support this part content"
                />
              </div>
              <div className="xl:w-10/12 xl:pl-4 md:pl-4 pl-4 lg:pr-4 xl:py-4">
                <p className="text-white font xl:text-3xl lg:text-3xl md:text-4xl text-2xl font-thin md:font-thin xl:w-9/12 md:py-0 py-4 xl:py-0">
                  Introducing Marslab
                </p>
                <p className="text-white font xl:text-sm lg:text-xl md:text-lg text-xl xl:pt-4  md:py-8 py-4 xl:py-0">
                  How can businesses overcome the challenges of complex IT
                  environments? Radical simplicity is the answer.
                </p>
                <button className="buttoncoffeesmallneww text-white md:mb-8 xl:mb-0 mb-8">
                  Learn more →
                </button>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <CustomNextArrow />
    </section>
  );
};

export default Cloudslider;
