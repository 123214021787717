import React, { useState, useEffect } from "react";
import AccordionFooter from "./AccordionFooter";
import ibusinesslogo from "assets/img/icons/ibusinesslogo.png";
import moment from "moment-timezone";
// import timezones from "./timezones.json"; // Adjust the path if needed

export default function Footer() {
  const timezones = require("../../timezones.json");

  const [isEnglish, setIsEnglish] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("Canada");
  const [currentTime, setCurrentTime] = useState("");

  const toggleLanguage = () => {
    setIsEnglish(!isEnglish);
    setIsDropdownOpen(false); // Close the dropdown when a language is selected
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getCurrentTime(selectedCountry));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [selectedCountry]);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
  };

  const getCurrentTime = (country) => {
    const now = moment();
    const timeZone = getCountryTimeZone(country);
    return now.tz(timeZone).format("hh:mm:ss A");
  };

  const getCountryTimeZone = (country) => {
    const countryData = timezones.find((tz) => tz.country === country);
    return countryData ? countryData.timezone : "UTC";
  };

  const currentYear = new Date().getFullYear();

  const accordionData = [
    {
      title: "Solutions",
      content: (
        <ul key="list">
          <li className="text-xs text-white font py-2">
            <a href="/corporate-services">Corporate Services</a>
          </li>
          <li className="text-xs font text-white py-2">
            <a href="/it-services">IT Services</a>
          </li>
          <li className="text-xs font text-white py-2">
            <a href="/hr-service-homepage">HR Services</a>
          </li>
          <li className="text-xs font text-white py-2">
            <a href="/ip-service-homepage">Intellectual Property Services</a>
          </li>
        </ul>
      )
    },
    {
      title: "Quick Menu",
      content: (
        <ul key="list">
          <li className="text-xs font text-white py-2">
            <a href="/about-us">About Us</a>
          </li>
          <li className="text-xs font text-white py-2">
            <a href="/solution-homepage">Solutions</a>
          </li>
          <li className="text-xs font text-white py-2">
            <a href="/contact">Contact Us</a>
          </li>
        </ul>
      )
    }
  ];

  return (
    <>
      <footer className="buttonWithBluenew">
        <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20" style={{ transform: "translateZ(0)" }}></div>
        <div className="container mx-auto xl:px-16 px-4 xl:block lg:block sm:hidden md:hidden hidden">
          <div className="flex justify-between lg:text-left xl:pt-12 pt-8 lg:pt-4">
            <div className="w-full xl:w-12/12 lg:w-12/12">
              <div className="xl:ml-0 flex">
                <div className="font text-white xl:text-4xl lg:text-3xl">Let's Connect</div>
              </div>
              <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 xl:pt-8 lg:pt-6">
                <div className="xl:pr-8 xl:ml-0">
                  <h4 className="para-font xl:text-lg text-white lg:text-lg md:text-2xl font-semibold">Services</h4>
                  <div className="ftDropDown"></div>
                  <ul className="list-unstyled xl:pt-4 lg:pt-4">
                    <li>
                      <a className="para-font text-white hover:text-dark-800 font-medium block xl:pb-2 md:pt-3 xl:pt-0 text-base" href="/corporate-services">
                        Corporate Services
                      </a>
                    </li>
                    <li>
                      <a className="para-font text-white hover:text-dark-800 font-medium block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-base" href="/it-services">
                        IT Services
                      </a>
                    </li>
                    <li>
                      <a className="para-font text-white hover:text-dark-800 font-medium block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-base" href="/hr-service-homepage">
                        HR Services
                      </a>
                    </li>
                    <li>
                      <a className="para-font text-white hover:text-dark-800 font-medium block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-base" href="/ip-service-homepage">
                        IP Services
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h4 className="para-font xl:text-lg text-white md:text-2xmediuml font-semibold">Quick Menu</h4>
                  <div className="ftDropDown"></div>
                  <ul className="list-unstyled xl:pt-4 lg:pt-4">
                    <li>
                      <a className="para-font text-white hover:text-dark-800 font-medium block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-base" href="/about-us">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a className="para-font text-white hover:text-dark-800 font-medium block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-base" href="/solution-homepage">
                        Solutions
                      </a>
                    </li>
                    <li>
                      <a className="para-font text-white hover:text-dark-800 font-medium block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-base" href="/contact">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <div className="font xl:text-lg text-white xl:pl-2 xl:pt-8 lg:pt-8">Find us on any of these platforms, we respond in 1-2 business days.</div>
              <div className="xl:pl-2">
                <h2 className="font xl:text-3xl lg:text-3xl font-bold text-white xl:pt-6 lg:pt-4">{currentTime}</h2>
                <select value={selectedCountry} onChange={handleCountryChange} className="w-full rounded-md border-blue text-dark-blue font xl:w-64 lg:w-full xl:mt-3 lg:mt-3">
                  {timezones.map((tz) => (
                    <option key={tz.country} value={tz.country} className="text-dark-blue font">
                      {tz.country}
                    </option>
                  ))}
                </select>
                <div className="xl:pt-6 lg:pt-4">
                  <a href="">
                    <button className="bg-white hover:text-dark-800 text-dark-blue shadow-lg-white font-normal h-8 w-8 items-center justify-center align-center rounded-xll outline-none focus:outline-none mr-2" type="button" href="youtube">
                      <i className="fab fa-facebook"></i>
                    </button>
                  </a>
                  <a href="">
                    <button className="bg-white hover:text-dark-800 text-dark-blue shadow-lg-white font-normal h-8 w-8 items-center justify-center align-center rounded-xll outline-none focus:outline-none mr-2" type="button" href="youtube">
                      <i className="fab fa-linkedin"></i>
                    </button>
                  </a>
                  <a href="">
                    <button className="bg-white hover:text-dark-800 text-dark-blue shadow-lg-white font-normal h-8 w-8 items-center justify-center align-center rounded-xll outline-none focus:outline-none mr-2" type="button" href="twitter">
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>
                  <a href="">
                    <button className="bg-white hover:text-dark-800 text-dark-blue shadow-lg-white font-normal h-8 w-8 items-center justify-center align-center rounded-xll outline-none focus:outline-none mr-2" type="button" href="youtube">
                      <i className="">𝕏</i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 items-center md:justify-between justify-center">
            <div className="text-sm text-white para-font font-medium py-1">
              <p className="font">
                iBusiness™ is not a law firm and does not provide legal advice or legal services. We provide self-help services at your specific direction.
              </p>
              <p className="xl:pt-3">
                <a href="/terms-of-use" className="text-xs hover:text-dark-800">
                  Terms of use{" "}
                </a>
                |
                <a href="/privacy-policy" className="text-xs px-2 hover:text-dark-800">
                  Privacy policy
                </a>
                |
                <a href="/cancellation-policy" className="text-xs pl-2 hover:text-dark-800">
                  Cancellation policy
                </a>
              </p>
            </div>
            <div>
              <div className="justify-between flex">
                <div></div>
                <div className="flex">
                  <div className="relative xl:mr-4 ">
                    <button onClick={toggleDropdown} className="hover:text-dark-800 para-font xl:p-4 text-white font-normal h-10 w-32 items-center justify-center align-center outline-none focus:outline-none mr-2">
                      Language »
                    </button>
                    <div className={`absolute bg-white border rounded-md mt-2 py-2 w-28 ${isDropdownOpen ? "block" : "hidden"}`} style={{ bottom: "100%", left: 0 }}>
                      <button className="w-full font text-dark-blue text-left px-4 py-2" onClick={() => {
                        setIsEnglish(true);
                        toggleDropdown(); // Close the dropdown when a language is selected
                      }}>
                        English
                      </button>
                      {/* <button className="w-full font text-dark-blue text-left px-4 py-2" onClick={() => {
                        setIsEnglish(false);
                        toggleDropdown(); 
                      }}>
                        French
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center py-4">
            <p className="xl:pt-3 font text-sm">Copyright © {currentYear} iBusiness Consulting Team.</p>
          </div>
        </div>
        <div className="xl:hidden md:block lg:hidden block pt-6">
          <div className="app px-4">
            {accordionData.map((item, index) => (
              <AccordionFooter key={index} title={item.title} content={item.content} />
            ))}
          </div>
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <p className="xl:pt-8 pt-8">
              <a href="/terms-of-use" className="text-xs px-2 hover:text-dark-800">
                Terms of use
              </a>
              |
              <a href="/privacy-policy" className="text-xs px-2 hover:text-dark-800">
                Privacy policy
              </a>
              |
              <a href="/cancellation-policy" className="text-xs pl-2 hover:text-dark-800">
                Cancellation policy
              </a>
            </p>
            <div className="w-full md:w-10/12 px-4 mx-auto text-center">
              <div className="md:text-lg text-sm text-white font-semibold py-1 font">Copyright © {new Date().getFullYear()} iBusiness Inc. All rights reserved<a href="" className="text-blueGray-500 hover:text-dark-800"></a>.</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
