import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import service from "assets/img/icons/service.jpg";
import favicon from "assets/img/icons/favicon.png";
import logo from "assets/img/icons/logo.webp";
import Meetmarslab from "assets/img/images/Meetmarslab.png";
// import service from "assets/img/service.jpg"


export default function ModernOperations() {
  return (
    <section className="relative overflow-hidden">
      <IndexNavbar fixed />
      <div className="xl:mt-20 lg:mt-16 mt-16"></div>
      <div className="container xl:px-16 px-4 mx-auto relative overflow-hidden ">
        <div className="flex xl:w-12/12 w-12/12 md:12/12 xl:gap-4 xl:py-16">
          <div className="font xl:w-8/12 md:w-8/12">
            <div className="xl:pr-4">
              <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-4 pt-8 md:pt-12 xl:py-0">
                Modern Operations
              </p>
              {/* <p className="xl:text-lg md:text-base text-base text-danger xl:pt-4 pt-4">
                Unleash the Power of a Hybrid Cloud
              </p> */}
            </div>

            <div className="xl:pr-4">
              <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
                In today's dynamic business environment, organizations are
                constantly seeking ways to optimize their operations, enhance
                efficiency, and achieve sustainable growth. Marslab's Modern
                Operations approach seamlessly integrates people, processes, and
                cutting-edge technology to deliver unparalleled value. Our
                approach goes beyond simply addressing current needs; it
                encompasses a comprehensive understanding of anticipated future
                requirements and the precise steps necessary to optimize
                operational processes and maximize efficiency.
              </p>
              <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-4 pt-4">
                Marslab's evolved operating model is specifically tailored to
                meet the challenges and opportunities of today's rapidly
                evolving digital landscape. We expertly manage and continuously
                refine our approach to facilitate ongoing business growth and
                adaptation to changing market conditions.
              </p>

            
            </div>

            <div class="flex xl:w-12/12 border-red shadow-xl bg-transparent-black rounded-lg xl:mt-12 xl:p-3 mt-4">
              <div class="xl:w-2/12 xl:p-1">
                <img
                  src={favicon}
                  class="xl:h-16 h-8 w-32 mt-4 xl:mt-0 md:w-28"
                />
              </div>
              <div class="font xl:w-10/12 xl:pl-3 pl-4 pt-1">
                <p class="xl:text-sm text-white text-xs font">
                  Freshworks is the only “visionary” again on the 2021 Gartner
                  Magic Quadrant for the CRM Customer Engagement Center.
                </p>

                <a href="">
                  <p class="text-danger xl:text-base text-xs xl:pt-2 pt-1">
                    Get the report
                  </p>
                </a>
              </div>
            </div>

            {/* <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-12 pt-8 md:pt-12 xl:py-0">
              How Marslab Consult works
            </p> */}
            {/* <p className="xl:text-lg md:text-base text-base text-danger xl:pt-4 pt-4">
                Embark on a transformative journey with Marslab Cloud Services
              </p> */}

            {/* <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
              We partner with customers to eliminate roadblocks to innovation
              and empower them with modern IT management practices.<br></br>Our
              continuous lifecycle approach caters to businesses at any stage of
              their digital transformation journey and provides a framework for
              seamless co-innovation.
            </p> */}
            <div className="xl:hidden md:hidden block">
              <div className="font xl:mt-0 xl:pt-3 ml-12 my-4">
                <img
                  src={service}
                  alt="Your browser does not support this image shadow-xl"
                  className="xl:h-400 md:240 h-250 rounded-xl"
                ></img>
              </div>
            </div>
          </div>
          <div className="xl:block md:block hidden">
            <div className="font xl:mt-0 xl:pt-8 md:pt-24">
              <img
                src={service}
                alt="Your browser does not support this image shadow-xl"
                className="xl:h-400 md:240 h-250 rounded-xl"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
}
