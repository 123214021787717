import React, { useState } from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
// import SliderPage from "./SliderPage";
import AboutUs from "components/Pages/about/AboutUs";
import Aboutt from "about/aboutpage/Aboutt";
import Homepage1 from "components/Homepage/Homepage1";
import Footer from "components/Footers/Footer";
import marslababout from "assets/img/marslababout.png";
import onlyimagesupport from "assets/img/only-image-support.webp";
import freshchat from "assets/img/freshchat.webp";
import freshmarketerww from "assets/img/freshmarketerww.webp";
import freshmarketer from "assets/img/freshmarketer.webp";
import allproducts from "assets/img/all-products.webp";
import freshserviceoffsetgridupdated from "assets/img/freshservice-offsetgrid-updated.webp";
import LifeatFreshworks from "assets/img/LifeatFreshworks.webp";
import passion from "assets/img/passion.jpg"
import customer from "assets/img/customer.jpg"
import transparent from "assets/img/transparent.jpg"
import service from "assets/img/service.jpg"
import technology from "assets/img/technology.jpg"
import pocket from "assets/img/pocket.jpg"

const HoveredImage = ({ hoveredBox }) => {
  let imageSrc = passion;

  switch (hoveredBox) {
    case "passionDriven":
      imageSrc = passion;
      break;
    case "customerCentric":
      imageSrc = customer;
      break;
    case "transparentApproach":
      imageSrc = transparent;
      break;
    case "customerService":
      imageSrc = service;
      break;
    case "technologyObsessed":
      imageSrc = technology;
      break;
    case "pocketFriendly":
      imageSrc = pocket;
      break;

    default:
      break;
  }

  return (
    <div className="md:mt-0 bg-white xl:-mt-32 my-4">
      {imageSrc && (
        <img
          src={imageSrc}
          alt="Hovered Image"
          className="xl:w-max xl:h-max xl:mt-0 md:mt-4 ml-4 md:ml-0 md:mr-4"
        />
      )}
    </div>
  );
};

export default function Whymarslab() {
  const [hoveredBox, setHoveredBox] = useState(null);

  return (
    <section className="relative overflow-hidden">
      <IndexNavbar fixed />
      <div className="xl:mt-20 lg:mt-16 mt-16">
        <div className="">
          <div className="bg-stone-200">
            <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
              <a href="index" className="link">
                Home{" "}
              </a>{" "}
              ― Why Marslab
            </div>
          </div>
          {/* <div
            style={{
              // backgroundImage: `url(https://i.ibb.co/XtLpVmk/greenee.jpg)`,
              backgroundImage: `url(${marslababout})`,
              width: "100%",
              height: "600px",
              backgroundSize: "cover",
            }}
          ></div> */}
        </div>
      </div>
      <div className="bg-white">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-16 md:py-8">
          <div>
            <div className="xl:text-3xl text-left md:ml-92 md:text-2xl text-xl text-black uppercase font-semibold xl:ml-maximum xl:mb-10 md:pb-8 py-4">
             Why MARSLAB <br></br> <p className="text-danger"> for your Organization ?</p>
            </div>

            <div className="grid xl:grid-cols-2 md:grid-cols-2">

              <div className="xl:mt-28">
                <HoveredImage hoveredBox={hoveredBox} />
              </div>

              <div class="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:gap-4 md:gap-2 gap-2 xl:mb-0 mb-8">
                <div
                  className={` cursor-pointer passionBox border border-ivory-500 rounded-xl xl:p-5 md:p-4 p-5 bg-white xl:h-36 ${
                    hoveredBox === "passionDriven" && "hovered"
                  }`}
                  onMouseEnter={() => setHoveredBox("passionDriven")}
                  onMouseLeave={() => setHoveredBox(null)}
                >
                  <p class="xl:text-lg text-sm font-semibold font-medium font text-ivory-black">
                    Passion Driven
                  </p>
                  <p class="font xl:text-sm md:text-xs text-xs text-black xl:pt-1">
                    Love to learn and work for the client cause and constantly
                    build the expertise to innovate
                  </p>
                </div>

                <div
                  className={`cursor-pointer passionBox border border-ivory-500 rounded-xl xl:p-5 md:p-4 p-5 bg-white xl:h-36  ${
                    hoveredBox === "customerCentric" && "hovered"
                  }`}
                  onMouseEnter={() => setHoveredBox("customerCentric")}
                  onMouseLeave={() => setHoveredBox(null)}
                >
                  <p className="xl:text-lg text-sm font-semibold font-medium font text-ivory-black">
                    Customer Centric
                  </p>
                  <p className="font xl:text-sm md:text-xs text-xs text-black xl:pt-1">
                    Pay attention to what the client want and align their IT
                    solution strategy with their business goals.
                  </p>
                </div>

                <div
                  className={`cursor-pointer passionBox border border-ivory-500 rounded-xl xl:p-5 md:p-4 p-5 bg-white xl:h-36  ${
                    hoveredBox === "transparentApproach" && "hovered"
                  }`}
                  onMouseEnter={() => setHoveredBox("transparentApproach")}
                  onMouseLeave={() => setHoveredBox(null)}
                >
                  <p className="xl:text-lg text-sm font-semibold font-medium font text-ivory-black">
                    Transparent Approach
                  </p>
                  <p className="font xl:text-sm md:text-xs text-xs text-black xl:pt-1">
                    Upfront about benefits and limitations, believe in open
                    communication and collaboration.
                  </p>
                </div>

                <div
                  className={`cursor-pointer passionBox border border-ivory-500 rounded-xl xl:p-5 md:p-4 p-5 bg-white xl:h-36   ${
                    hoveredBox === "customerService" && "hovered"
                  }`}
                  onMouseEnter={() => setHoveredBox("customerService")}
                  onMouseLeave={() => setHoveredBox(null)}
                >
                  <p className="xl:text-lg text-sm font-semibold font-medium font text-ivory-black">
                    Customer Service
                  </p>
                  <p className="font xl:text-sm md:text-xs text-xs text-black xl:pt-1">
                    Best-in-Class Service/Support at round the clock and easily
                    reachable service.
                  </p>
                </div>

                <div
                  className={`cursor-pointer passionBox border border-ivory-500 rounded-xl xl:p-5 md:p-4 p-5 bg-white xl:h-36 ${
                    hoveredBox === "customerCentric" && "hovered"
                  }`}
                  onMouseEnter={() => setHoveredBox("technologyObsessed")}
                  onMouseLeave={() => setHoveredBox(null)}
                >
                  <p className="xl:text-lg text-sm font-semibold font-medium font text-ivory-black">
                    Technology Obsessed
                  </p>
                  <p className="font xl:text-sm md:text-xs text-xs text-black xl:pt-1">
                    Urge to continuously learn new technologies and deliver
                    cutting-edge value to clients.
                  </p>
                </div>

                <div
                  className={`cursor-pointer passionBox border border-ivory-500 rounded-xl xl:p-5 md:p-4 p-5 bg-white xl:h-36  ${
                    hoveredBox === "customerCentric" && "hovered"
                  }`}
                  onMouseEnter={() => setHoveredBox("pocketFriendly")}
                  onMouseLeave={() => setHoveredBox(null)}
                >
                  <p className="xl:text-lg text-sm font-semibold font-medium font text-ivory-black">
                    Pocket Friendly
                  </p>
                  <p className="font xl:text-sm md:text-xs text-xs text-black xl:pt-1">
                    Based on client requirements, implementing the
                    cost-effective service in a timely fashion.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4 ">
      <div className="bg-grey-new rounded-xl xl:mb-8">
        <div className="grid xl:grid-cols-2 xl:pl-16">
          <div className="xl:py-16 md:py-16 py-10 ">
            <p className="font text-black xl:text-4xl md:text-4xl text-4xl font-semibold">
              Stunning animated icons that are sure to impress
            </p>
            <p className="font text-black xl:text-lg xl:pt-8 md:pt-8 pt-4">
              Download high quality animated icons for free and give your
              project the wow factor you'have been looking for.
            </p>
            <div className="mb-8">
              <button className="buttoncoffee">Explore animated icon</button>
            </div>
          </div>
          <div className="xl:h-maxx xl:w-maxx xl:h-maxx xl:mt-16">
            <video src="https://media.flaticon.com/dist/min/img/animated-icon-detail/animation.mp4"
            autoplay="autoplay" loop="true" muted="muted" playsinline poster=""  className="xl:h-maxx xl:w-maxx xl:h-maxx"/>
          </div>
        </div>
      </div></div> */}
      <Footer />
    </section>
  );
}
