import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";
const TermsOfUse = () => {
  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-18 mt-18">
        <div className="">
          <div className="bg-grey-50">
            <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
              <a href="index" className="link">
                Home{" "}
              </a>{" "}
              ― Terms of use
            </div>
          </div>
        </div>
        <div className="container mx-auto overflow-hidden xl:px-16 px-4 xl:pb-16 pb-8 md:pb-8">
          <h1 className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl text-center xl:pt-20 lg:pt-12 md:pt-12 xl:px-24 lg:px-32 pt-8">
            Terms of use
          </h1>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-6">
              Overview
            </p>
            <p className="para-font xl:text-base text-dark-blue pt-0 md:text-sm text-sm">
              Welcome to the iBC website. By accessing or using our website, you
              agree to the terms outlined below and affirm your compliance with
              applicable laws and regulations, including those concerning export
              and re-export controls. If you disagree with any part of these
              terms, please refrain from using our website.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2 md:text-sm text-sm">
              iBC reserves the right to amend these Terms of Use and any other
              information on this website without prior notice. Additionally, we
              may modify or discontinue any products, services, or programs
              described here at any time without notice.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              General Use
            </p>
            <p className="para-font xl:text-base text-dark-blue  xl:pt-1 md:pt-2 pt-2 md:text-sm text-sm">
              This website includes proprietary material which is protected by
              intellectual property laws. You are granted a non-exclusive,
              non-transferable, limited right to access and display the pages
              within this site for personal, non-commercial use, provided you
              comply with these Terms of Use. Any use of automated systems or
              software to extract data from this website for commercial purposes
              ('screen scraping') is prohibited unless you have obtained a
              license to do so.
            </p>
            <p className="para-font xl:text-base text-dark-blue  xl:pt-1 md:pt-2 pt-2 md:text-sm text-sm">
              You are not permitted to use this website for any commercial
              purposes without express written permission from IBC. Unauthorized
              use of this website and systems including but not limited to
              unauthorized entry into iBC’s systems, misuse of passwords, or
              misuse of any information posted to a site, is strictly
              prohibited.
            </p>
            <p className="para-font xl:text-base text-dark-blue  xl:pt-1 md:pt-2 pt-2 md:text-sm text-sm">
              You may not mirror any material contained on this website or any
              other server. You may not copy, modify, reproduce, republish,
              upload, post, transmit, or distribute any material from this
              website including code and software. iBC retains full and complete
              title to the material provided on this site, including all
              associated intellectual property rights, and provides this
              material under a license that can be revoked at any time by iBC.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Copyright and Trademark Information
            </p>
            <p className="para-font xl:text-base text-dark-blue  xl:pt-1 md:pt-2 pt-2 md:text-sm text-sm">
              This website contains distinct proprietary notices and copyright
              information which must be adhered to. Further details can be found
              under the "Copyright and trademark information" tab on our
              website.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Disclaimer
            </p>
            <p className="para-font xl:text-base text-dark-blue  xl:pt-1 md:pt-2 pt-2 md:text-sm text-sm">
              iBC does not guarantee that the information presented on this
              website is complete or current. The site may contain inaccuracies
              or typographical errors. IBC disclaims all liabilities for these
              errors or inaccuracies. We encourage you to verify all information
              before making any decisions related to the services, products, or
              other matters described in this website.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Limitation of Liability
            </p>
            <p className="para-font xl:text-base text-dark-blue  xl:pt-1 md:pt-2 pt-0 md:text-sm text-sm">
              In no event will IBC be liable for any direct, indirect,
              incidental, special, or consequential damages that result from the
              use of, or the inability to use, the material on this site or the
              performance of the products purchased through the site.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Governing Law
            </p>
            <p className="para-font xl:text-base text-dark-blue  xl:pt-1 md:pt-2 pt-0 md:text-sm text-sm">
              This agreement is governed by the laws of the jurisdiction where
              IBC is located, without giving effect to its conflict of law
              provisions.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Sever ability
            </p>
            <p className="para-font xl:text-base text-dark-blue  xl:pt-1 md:pt-2 pt-0 md:text-sm text-sm">
              If any part of these Terms of Use is deemed unlawful, void, or
              unenforceable, that part will be deemed severable and will not
              affect the validity and enforceability of remaining provisions.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Updates and Modifications
            </p>
            <p className="para-font xl:text-base text-dark-blue  xl:pt-1 md:pt-2 pt-0 md:text-sm text-sm">
              iBC may make improvements or changes in the information, services,
              products, and other materials on this website, or terminate this
              website, at any time without notice. We may modify these Terms of
              Use at any time, and such modifications shall be effective
              immediately upon posting of the modified Terms of Use.
              Accordingly, you agree to review the Terms of Use periodically,
              and your continued access or use of this website will be deemed
              your acceptance of the updated or modified Terms of Use.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};
export default TermsOfUse;
