import React, { useState } from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
// import SliderPage from "./SliderPage";
import AboutUs from "components/Pages/about/AboutUs";
import Aboutt from "about/aboutpage/Aboutt";
import Homepage1 from "components/Homepage/Homepage1";
import Footer from "components/Footers/Footer";
import marslababout from "assets/img/marslababout.png";
import Homepage from "assets/img/images/Homepage.webp"
import muthukumar from "assets/img/images/muthukumar.jpg"
import govd from "assets/img/images/govd.jpg"
import devarajan from "assets/img/images/devarajan.jpg"
import dinesh from "assets/img/images/dinesh.jpg"
import Prabesh from "assets/img/images/Prabesh.jpg"
import Mika from "assets/img/images/Mika.webp"

export default function Leadership() {
  const [isPassionDrivenHovered, setIsPassionDrivenHovered] = useState(false);

  return (
    <section className="relative overflow-hidden">
      <IndexNavbar fixed />
      <div className="xl:mt-20 lg:mt-16 mt-16">
        <div className="">
          <div className="bg-stone-200">
            <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
              <a href="index" className="link">
                Home{" "}
              </a>{" "}
              ― Our Team
            </div>
          </div>
          {/* <div
            style={{
              // backgroundImage: `url(https://i.ibb.co/XtLpVmk/greenee.jpg)`,
              backgroundImage: `url(${marslababout})`,
              width: "100%",
              height: "600px",
              backgroundSize: "cover",
            }}
          ></div> */}
        </div>
      </div>
      <div className="bg-white">
        <div className="relative  container mx-auto xl:px-16 px-4">
          <div className="xl:mt-48 md:mt-0 xl:mt-0 pb-8 relative xl:mx-0">
            <div className="grid xl:grid-cols-2 md:grid-cols-2 xl:pt-12 pt-8">
              <div className="xl:pr-16 md:pr-8">
                <img src={Homepage}/>
              </div>
              <div>
                <div className="xl:text-5xl md:text-2xl text-xl text-black xl:pt-10 font-semibold md:pt-4 pb-3 pt-4 md:pb-3 xl:pb-4">
                  What Our Team Strive For
                </div>
                <div className="font xl:text-lg md:text-mg text-sm text-black">
                  MARSLAB began with the objective of helping customers address
                  challenges with improving ROI, scaling quickly, resource
                  provisioning and going to market faster through cloud and
                  digital transformation. This key mission is supported by other
                  strong objectives that establish us as a reliable and cloud
                  savvy partner.
                </div>
                <div className="grid xl:grid-cols-1 text-left xl:mb-0 mb-8">
                  <ul className="list-none xl:pt-4 pt-4 xl:pt-0 m-0 text-black">
                    <li className="border bg-ivory-black text-white rounded-lg font xl:text-lg md:text-mg text-xs xl:ml-24 ml-12 flex items-start">
                      <span className="mr-2 xl:pl-3 pl-3">⇝</span> Ensuring consistent high
                      quality and performance
                    </li>
                    <li className="border bg-ivory-black text-white xl:mt-3 rounded-lg font xl:text-lg mt-1 md:text-mg text-xs flex items-start">
                      <span className="mr-2 xl:pl-3 pl-2">⇝</span> Achieving results that are
                      closely aligned with business goals
                    </li>
                    <li className="border bg-ivory-black text-white xl:mt-3 xl:ml-8 rounded-lg font mt-1 xl:text-lg md:text-mg text-xs ml-4 flex items-start">
                      <span className="mr-2 xl:pl-3 pl-3">⇝</span> Implementing cost
                      effective solutions that are impactful
                    </li>
                    <li className="border bg-ivory-black text-white xl:mt-3 rounded-lg font xl:text-lg mt-1 md:text-mg text-xs  flex items-start">
                      <span className="mr-2 xl:pl-3 pl-3">⇝</span> Enabling continuous
                      improvement and innovation
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto xl:px-16 px-4 flex flex-wrap items-center xl:pt-8">
        <div className="grid xl:grid-cols-3 xl:px-16 lg:grid-cols-3 md:grid-cols-3 grid-cols-1 xl:gap-4 md:gap-4">
          <div className="relative hover:shadow rounded-xl flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg bg-white">
            <img
              alt="..."
              src={muthukumar}
              className="w-full align-middle rounded-t-xl"
            />
            <blockquote className="relative xl:p-8 lg:p-5 p-5">
              <h4 className="xl:text-2xl md:text-base md:text-base font text-ivory-black">
                MK (aka) Muthukumaran
              </h4>
              <div class="text-container">
                <p className="font xl:text-base md:text-mg xl:pb-5">Director</p>
              </div>
            </blockquote>
          </div>

          <div className="relative hover:shadow rounded-xl flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg  bg-white">
            <img
              alt="..."
              src={devarajan}
              className="w-full align-middle rounded-t-xl"
            />
            <blockquote className="relative xl:p-8 lg:p-5 p-5">
              <h4 className="xl:text-2xl md:text-base md:text-base font text-ivory-black">
                DV (aka) Devarajan
              </h4>

              <div class="text-container">
                <p className="font xl:text-base md:text-mg xl:pb-5">Director</p>
              </div>
            </blockquote>
          </div>

          <div className="relative hover:shadow rounded-xl flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg bg-white">
            <img
              alt="..."
              src={Mika}
              className="w-full align-middle rounded-t-xl"
            />
            <blockquote className="relative xl:p-8 lg:p-5 p-5">
              <h4 className="xl:text-2xl md:text-base font text-ivory-black">
                SMA (aka) Selvam
              </h4>

              <div class="text-container">
                <p className="font xl:text-base md:text-mg xl:pb-5">
                  Head: Tech Support
                </p>
              </div>
            </blockquote>
          </div>

          <div className="relative hover:shadow rounded-xl flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg  bg-white">
            <img
              alt="..."
              src={govd}
              className="w-full align-middle rounded-t-xl"
            />
            <blockquote className="relative xl:p-8 lg:p-5 p-5">
              <h4 className="xl:text-2xl md:text-base font text-ivory-black">
                GVR (aka) Govindan
              </h4>

              <div class="text-container">
                <p className="font xl:text-base md:text-mg xl:pb-5">
                  Advisor : Board
                </p>
              </div>
            </blockquote>
          </div>

          <div className="relative hover:shadow rounded-xl flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg  bg-white">
            <img
              alt="..."
              src={dinesh}
              className="w-full align-middle rounded-t-xl"
            />
            <blockquote className="relative xl:p-8 lg:p-5 p-5">
              <h4 className="xl:text-2xl md:text-base font font-medium text-ivory-black  xl:pb-2">
                DK (aka) Dinesh
              </h4>

              <div class="text-container">
                <p className="font xl:text-base md:text-mg xl:pb-5">
                  Head : AI/Data Engg{" "}
                </p>
              </div>
            </blockquote>
          </div>

          <div className="relative hover:shadow rounded-xl flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg  bg-white">
            <img
              alt="..."
              src={Prabesh}
              className="w-full align-middle rounded-t-xl"
            />
            <blockquote className="relative xl:p-8 lg:p-5 p-5">
              <h4 className="xl:text-2xl md:text-base font text-ivory-black">
                PB (aka) Prabesh
              </h4>

              <div class="text-container">
                <p className="font xl:text-base md:text-mg xl:pb-5">
                  {" "}
                  Head : Cloud
                </p>
              </div>
            </blockquote>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
}
