// EnquiryButtonWithPopup.js
import React, { useState } from "react";
import enquiry from "assets/img/enquiry.svg";
import axios from "axios";
import { toast } from "react-toastify";
import whatsapp from "assets/img/whatsapp.png";

function EnquiryButtonWithPopup() {
  const [showPopup, setShowPopup] = useState(false);
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    message: "",
  });

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleClickCall = () => {
    window.location.href = "tel:+1 514 679 3874";
  };

  const handleClickMail = () => {
    window.location.href = "mailto:info@recrutementplus.ca";
  };

  const handleEnquirySubmit = (e) => {
    e.stopPropagation();
    if (enquiryData.name == "" || enquiryData.name == null) {
      toast.error("Please enter your name");
    } else if (enquiryData.mobile == "" || enquiryData.mobile == null) {
      toast.error("Please enter your mobile");
    } else if (enquiryData.email == "" || enquiryData.email == null) {
      toast.error("Please enter your email");
    } else if (enquiryData.service == "" || enquiryData.service == null) {
      toast.error("Please select your service");
    } else if (enquiryData.message == "" || enquiryData.message == null) {
      toast.error("Please select your message");
    } else {
      const formData = { enquiry: enquiryData };
      axios
        .post(
          "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/add-enquiry",
          formData
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status) {
              setEnquiryData({
                name: "",
                email: "",
                mobile: "",
                service: "",
                message: "",
              });
              toast.success("Your enquiry has been sent successfully");
              window.location = "/thank-you";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleClickWhatsApp = () => {
    window.location.href = "https://whatsapp.com?send=15146793874";
  };

  return (
    <>
      <div className="container">
        {/* Enquiry button */}
        <div className="floating-inquiry-button">
          <img
            src={enquiry}
            alt="Enquiry"
            className="enquiry-button w-16"
            onClick={togglePopup}
          />
        </div>

        {/* Popup */}
        {showPopup && (
          <div className="popup-overlay xl:px-0 px-4">
            <div className="popup-container-enquiry animate-slide-up xl:w-4/12 xl:pl-4 xl:px-0 px-4">
              {/* Popup content */}
              <div className="bg-white rounded-md xl:pp-5 lg:p-5 md:p-4">
                <div className="xl:-mt-8">
                  <div className="flex justify-between xl:pt-4 pt-0 xl:-mt-0 lg:-mt-0 md:-mt-4">
                    <div>
                      <p className="font-semibold text-light-blue text-lg">
                        Enquiry
                      </p>
                    </div>
                    <div>
                      <button
                        className="font font-semibold text-blue"
                        onClick={closePopup}
                      >
                        X
                      </button>
                    </div>
                  </div>

                  <p className="font xl:text-base text-sm text-left text-light-blue para-font pt-2">
                    "Got questions? We're here to help! Contact <br></br> our
                    support team, and we'll get back to you ASAP."
                  </p>
                </div>

                <div className="grid grid-rows-4 gap-2 mt-4 lg:gap-2 lg:mt-2">
                  <input
                    type="text"
                    className="pl-4 rounded font text-sm border-grey-100 mt-2"
                    placeholder="Name"
                    onClick={(e) => e.stopPropagation()}
                    value={enquiryData ? enquiryData.name : ""}
                    onChange={(e) =>
                      setEnquiryData({ ...enquiryData, name: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    className="pl-4 rounded font text-sm border-grey-100"
                    placeholder="Phone"
                    onClick={(e) => e.stopPropagation()}
                    value={enquiryData ? enquiryData.mobile : ""}
                    onChange={(e) =>
                      setEnquiryData({ ...enquiryData, mobile: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    className="pl-4 rounded font text-sm border-grey-100"
                    placeholder="E-mail"
                    onClick={(e) => e.stopPropagation()}
                    value={enquiryData ? enquiryData.email : ""}
                    onChange={(e) =>
                      setEnquiryData({ ...enquiryData, email: e.target.value })
                    }
                  />
                </div>
                <div className="grid lg:grid-rows-1">
                  <select
                    id="inquiryType"
                    className="text-grey-500 border-grey-100 mt-2 pl-4 font rounded px-20 xl:w-full py-2 md:py-2 text-sm"
                    onClick={(e) => e.stopPropagation()}
                    value={enquiryData ? enquiryData.service : ""}
                    onChange={(e) =>
                      setEnquiryData({
                        ...enquiryData,
                        service: e.target.value,
                      })
                    }
                  >
                    <option
                      value="Corporate Service"
                      className="font  md:text-xs xl:text-base lg:text-base text-xs"
                    >
                      Corporate Service
                    </option>
                    <option
                      value="IT Service"
                      className="font  md:text-xs xl:text-base lg:text-base text-xs"
                    >
                      IT Service
                    </option>
                    <option
                      value="HR Service"
                      className="font  md:text-xs xl:text-base lg:text-base text-xs"
                    >
                      HR Service
                    </option>
                    <option
                      value="IP Service"
                      className="font  md:text-xs xl:text-base lg:text-base text-xs"
                    >
                      IP Service
                    </option>
                  </select>
                </div>

                <div className="grid grid-rows-1 xl:gap-4 mt-2">
                  <textarea
                    className="pl-4 rounded font border-grey-100 md:text-xs xl:text-base lg:text-base text-sm"
                    placeholder="Drop us a line."
                    onClick={(e) => e.stopPropagation()}
                    value={enquiryData ? enquiryData.message : ""}
                    onChange={(e) =>
                      setEnquiryData({
                        ...enquiryData,
                        message: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="grid grid-cols-2 xl:gap-4 gap-2 xl:mt-8 md:mt-2 mt-2 lg:mt-2">
                  <button
                    className="font buttonWithBlue rounded border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                    onClick={handleEnquirySubmit}
                  >
                    Send Message
                  </button>
                  <button
                    onClick={handleClickCall}
                    className="font buttonWithBlue rounded border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                  >
                    Call
                  </button>
                </div>
                {/* WhatsApp button */}
                <div className="grid grid-rows-1 xl:mt-4 lg:mt-2 md:mt-1 mt-2">
                  <button
                    className="rounded buttonWithBlue border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                    onClick={handleClickWhatsApp}
                  >
                    <div className="flex justify-center">
                      <img
                        src={whatsapp}
                        className="w-4 h-4 mt-1 mr-2"
                        alt="WhatsApp"
                      />
                      Whats App
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default EnquiryButtonWithPopup;
