// PopUp.js
import React from "react";

function PopUp({ onClose }) {
  const handleClickOutside = (event) => {
    if (!event.target.closest(".popup-container")) {
      onClose();
    }
  };

  const handleButtonClickCall = () => {
    window.location.href = "tel:+1 514 679 3874 ";
  };

  const handleButtonClickMail = () => {
    window.location.href = "mailto:info@recrutementplus.ca";
  };

  return (
    <div>nothing</div>
  );
}

export default PopUp;
