import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer.js";
import EnquiryButton from "components/Pages/EnquiryButton";
import intellectualproperty from "assets/img/intellectualproperty.svg";
import React, { useState } from "react";
import ipibc from "assets/img/ipibc.svg";

const IpServiceHomepage = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <div>
      <IndexNavbar />
      {/* EnquiryButton */}
      <EnquiryButton />
      <div className="xl:mt-20 lg:mt-18 mt-18">
        <div className="">
          <div className="bg-grey-50">
            <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
              <a href="index" className="link">
                Home
              </a>
              ― Intellectual Property Services
            </div>
          </div>
        </div>
        <section className="bg-cover xl:mt-20 lg:mt-12 md:mt-12 mt-12">
          <div className="text-center text-blue uppercase xl:text-base md:text-xs text-xs para-font font-medium">
            our services
          </div>
          <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl px-2 text-center leading-14 xl:pt-3 md:pt-1 pt-1">
            iBusiness Consultant - To help you succeed.
          </div>
          <div className="xl:px-84 px-2 font text-base text-light-blue para-font text-center leading-relaxed xl:px-32 lg:px-32  md:px-16 px-0 xl:px-0 md:pt-2 xl:pt-2 pt-2">
            Intellectual property (IP) encompasses the wide array of
            intellectual creations, from inventions and literary works to
            artistic endeavours, designs, symbols, names, and images utilized in
            commerce. These assets are safeguarded by laws through patents,
            copyrights, trademarks, and more, enabling individuals and companies
            to earn recognition and financial benefits from their creations. The
            IP system is designed to strike a balance between the interests of
            innovators and the public good, thereby encouraging an ecosystem
            where creativity and innovation can flourish.
          </div>
        </section>

        <section className="bg-cover xl:pt-16 lg:pt-12 md:pt-12 pt-8">
          <div className="container mx-auto overflow-hidden xl:px-16 px-4">
            <div className="text-center text-blue uppercase xl:text-base md:text-xs text-xs para-font font-medium">
              our story
            </div>
            <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl text-center leading-14 xl:pt-0 pt-0 md:pt-0 font-bold">
              Intellectual Property Services
            </div>
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:gap-6 xl:text-lg para-font text-light-blue text-center lg:pt-0 xl:pt-8 md:pt-8 pt-4">
              <div className="xl:hidden lg:hidden md:hidden block">
                <div className="text-2xl text-bold text-left xl:pt-30 md:pt-12 lg:pt-0 xl:p-0 lg:p-12 md:p-0 p-0 pt-0 xl:pl-40">
                  {/* <img src={corporateservices}></img> */}
                  <img
                    src={intellectualproperty}
                    className="xl:w-450px lg:width-400px xl:mt-16 lg:mt-20 md:mt-16"
                  ></img>
                </div>
              </div>

              <div className="pt-4 xl:pt-16 lg:pt-12 md:pt-0">
                <p className="font text-base text-light-blue para-font text-left">
                  <b>
                    Copyrights safeguard tangible creative outputs, including:
                  </b>
                  <p>Movies, Books, Video Games, Music, Artworks.</p>
                </p>

                <p className="font text-base text-justify text-light-blue para-font xl:pt-4 lg:pt-4 md:pt-2 pt-2">
                  At iBC, we are committed to helping intellectual property
                  owners protect their rights and maintain a robust IP
                  portfolio. This involves defending against unauthorized use
                  and helping to manage intellectual property in a way that
                  aligns with domain-based IP rights.
                </p>

                <p className="font text-base text-justify text-light-blue para-font xl:pt-4 lg:pt-4 md:pt-2 pt-2">
                  <b> Patent Filing and Management:</b> Protecting inventions to
                  ensure that innovators can capitalize on their market
                  potential.
                </p>

                <div className="xl:pt-4 lg:pt-4 md:pt-2 pt-2">
                  <p className="font text-base text-justify text-light-blue para-font">
                    <b> Trademark Registration and Defence: </b> Securing brand
                    names, logos, and associated services marks to establish and
                    protect the identity of a business.
                  </p>

                  <div className="">
                    <div className="text-left xl:pt-2 pt-2 para-font">
                      <p
                        className="figtree text-base underline-on-hover xl:text-base cursor-pointer font-bold text-light-blue"
                        onClick={toggleContent}
                      >
                        Learn more <span className="right-symbol">➜</span>
                      </p>
                    </div>
                    {isContentVisible && (
                      <div className="xl:pb-16 lg:pb-8 md:pb-4 pb-0">
                        <p className="font text-base text-light-blue para-font text-justify">
                          <b> Brand Protection Services: </b> Offering
                          comprehensive strategies to safeguard your brand’s
                          integrity and market position.
                        </p>

                        <div className="xl:pb-4 lg:pb-4 md:pb-4 pb-8 xl:pt-4 lg:pt-4 md:pt-2 pt-2">
                          <p className="font text-base text-light-blue para-font text-justify">
                            With iBC, strengthen your competitive edge by
                            effectively managing and protecting your
                            intellectual property assets. Our expert team is
                            here to guide you through every step of the process,
                            ensuring your intellectual creations are fully
                            protected under the law.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="xl:block lg:block md:block hidden">
                <div className="text-2xl text-bold text-left xl:p-12 lg:p-12 md:p-0 p-0">
                  <img
                    // src={intellectualproperty}
                    src={ipibc}
                    className="xl:w-450px lg:w-320 xl:-mt-24 lg:mt-0 md:mt-12 xl:p-16 lg:p-0 md:p-0 p-0"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default IpServiceHomepage;
