import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import dropdown from "assets/img/dropdown.png";
import ibusinesslogo from "assets/img/icons/ibusinesslogo.png";
import { createPopper } from "@popperjs/core";
import axios from "axios";

export default function IndexNavbar2(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const popoverDropdownRef = React.createRef();
  const btnDropdownRef = React.createRef();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const [showFirstMenu, setShowFirstMenu] = useState(false);
  const handleFirstMenuHover = () => {
    setShowFirstMenu(true);
  };

  const handlePackagesMenuLeave = () => {
    setShowFirstMenu(false);
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (isDropdownVisible) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });
    }
  }, [isDropdownVisible]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/user", {
        headers: { Authorization: ` Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          setLoggedInUser(response.data);
        }
      })
      .catch((error) => {
        if (error.request.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("is_logged_in");
          setIsLoggedIn(false);
          setLoggedInUser(null);
          if (!window.location.href.includes("change-password")) {
            window.location = "/";
          }
        }
      });
  }, []);

  function logoutUser() {
    setLoading(true);
    setLoadingMessage("Logging out...");
    if (localStorage.getItem("is_logged_in")) {
      let token = localStorage.getItem("token");
      axios
        .get(
          "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/logout",
          { headers: { Authorization: ` Bearer ${token}` } }
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              setLoading(false);
              localStorage.removeItem("token");
              localStorage.removeItem("is_logged_in");
              window.location = "/";
            }
          }
        });
    }
  }

  return (
    <section className="">
      <nav className="fixed z-50 top-0 left-0 right-0 w-full xl:pb-3 navbar-expand-lg bg-white shadow-lg-blue">
        {/* <div className="container mx-auto px-4 overflow-hidden"> */}
        <div className="">
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none py-3 xl:pt-1 xl:py-0" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <div className="px-0 xl:hidden lg:hidden md:block block sm:block">
              <div className="w-full relative flex justify-between lg:w-auto lg:static lg:justify-start">
                <div className="pr-4 xl:ml-0">
                  <span>
                    <a href="/">
                      <img
                        src={ibusinesslogo}
                        alt=""
                        className="xl:h-16 lg:w-auto xl:w-70 md:w-48 h-12 w-48 xl:pt-2 lg:pt-0 lg:mt-0 mt-8 xl:mt-0 cursor-pointer pr-0 lg:pr-0 md:pr-0 xl:pr-0"
                      />
                    </a>
                  </span>
                </div>
                <button
                  className="cursor-pointer text-xs leading-none block lg:hidden outline-none focus:outline-none"
                  type="button"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  ✖
                </button>
              </div>
            </div>

            {/* Mobile and tab view */}

            <div className="xl:hidden lg:hidden md:block block">
              <span
                className="flex-1 flex justify-between my-3 md:pt-3 pt-4"
                onClick={handleDropdownToggle}
              >
                <h3
                  className="md:border-b-long xl:border-b-none font lg:border-b-none pb-0 md:pb-4 font-medium uppercase text-dark-blue text-base pl-2"
                  onClick={handleDropdownToggle}
                >
                  <a href="/solution-homepage">Solutions</a>
                </h3>
                <img
                  src={dropdown}
                  className="w-4 h-4"
                  alt="dropdown icon"
                  onClick={handleDropdownToggle}
                />
              </span>

              {isDropdownOpen && (
                <div className="dropdown-content">
                  <div className="">
                    <div className="font xl:p-4 pb-0 pl-4 md:pl-8 md:p-0 p-0 xl:w-11/12">
                      <a href="/corporate-wizard">
                        <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-4 md:pt-4 lg:pt-14 pt-4">
                          Corporate Services
                        </p>
                      </a>

                      <a href="cloud-engineering">
                        <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          IT Services
                        </p>
                      </a>

                      <a href="data-engineering">
                        <p className="font xl:text-sm text-base font-medium text-light-blue  xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          IP Services
                        </p>
                      </a>

                      <a href="ai-engineering">
                        <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          HR Services
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="xl:block lg:block md:hidden sm:hidden hidden">
              <div className="justify-between flex">
                <div className="">
                  <div className="w-full">
                    <a href="/">
                      <img
                        src={ibusinesslogo}
                        alt=""
                        className="xl:ml-8 lg:ml-3 xl:h-16 xl:w-66 md:w-48 lg:w-56 h-12 w-48 xl:pt-2 lg:pt-0 lg:mt-0 mt-8 xl:mt-0 cursor-pointer"
                      />
                    </a>
                  </div>
                </div>

                {/* Desktp and tab landscape view  */}

                <div className="xl:ml-maximum2 lg:ml-maximum2">
                  <ul className="">
                    <div className="menu navbar-links">
                      <div
                        className="menu-item"
                        onMouseEnter={handleFirstMenuHover}
                        onMouseLeave={handlePackagesMenuLeave}
                      >
                        <div className="items-center flex text-sky-800 text-sm sm:text-base font-medium pr-4 relative cursor-pointer">
                          <h1 className="navbar-dropdown white-on-hover pr-1 cursor-pointer xl:text-base1 lg:text-base para-font xl:pt-3 xl:pl-76">
                            <div className="buttonWithBlueround rounded-full xl:px-5 xl:py-new lg:py-4 lg:px-5 font-bold">
                              {loggedInUser ? loggedInUser.name[0] : <span className="fa fa-user"></span>}
                            </div>
                          </h1>
                        </div>
                        <div className="fixed xl:ml-56 lg:-ml-28 xl:mt-0 lg:mt-4 xl:top-14 lg:top-20 xl:w-1/12 lg:w-2/12 cursor-pointer">
                          {showFirstMenu && (
                            <div className="menu-dropdown bg-white rounded-md shadow-xl border-light-blue-alterpro xl:mt-5 xl:-ml-16 animate-slide-up">
                              <div className="xl:pl-6 lg:pl-4 pb-4">
                                <div className="items-center pt-4">
                                  <a
                                    href="#!"
                                    onClick={logoutUser}
                                    className="white-on-hover text-light-blue "
                                  >
                                    <p className="xl:text-base font text-base font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      Sign Out
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            {/* Mobile view and tab view  */}
            <div className="xl:hidden  lg:hidden md:block block">
              <a href="/about-us">
                <p className="md:border-b-longg white-on-hover xl:border-b-none lg:border-b-none cursor-pointer md:pl-0 pl-2 xl:pl-0 md:pb-3 pb-0 xl:pb-0 lg:pb-0 lg:pl-0 md:pt-4 pt-5 xl:pt-0 lg:pt-0 font text-base uppercase text-dark-blue">
                  About Us
                </p>
              </a>
            </div>
            <div className="xl:hidden lg:hidden md:block block">
              <a href="/contact">
                <p className="md:border-b-longg xl:border-b-none lg:border-b-none cursor-pointer md:pl-0 pl-2 xl:pl-0 md:pb-3 pb-0 xl:pb-0 lg:pb-0 lg:pl-0 md:pt-4 pt-5 xl:pt-0 lg:pt-0  text-base font  uppercase text-dark-blue">
                  Contact Us
                </p>
              </a>
            </div>

            <div className="xl:hidden lg:hidden md:block block sm:block">
              <div className="xl:ml-42 lg:ml-34">
                <a>
                  <button className="border-blue bg-white font-semibold px-8 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-10 xl:py-2 md:px-8 md:mt-12 md:py-1 border rounded-lg text-blue-new md:ml-6 ml-2 xl:ml-0 lg:-ml-3">
                    Sign in
                  </button>
                </a>

                <a href="/solution-homepage">
                  <button class="buttonWithBlue border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-8 xl:py-2 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white md:ml-6 ml-2 xl:ml-4 lg:ml-4 lg:-ml-3">
                    Get Started
                  </button>
                </a>
              </div>
            </div>

            <div className="xl:hidden lg:hidden md:block block">
              <div className="mt-32 pt-8 border-t xl:pl-4 md:pl-4 pb-56 pl-0">
                <a href="">
                  <button
                    className="bg-blue text-white shadow-lg font-normal  h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href=""
                  >
                    <i className="fab fa-facebook"></i>
                  </button>
                </a>

                <a href="">
                  <button
                    className="bg-blue text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href="twitter"
                  >
                    <i className="">𝕏</i>
                  </button>
                </a>

                <a href="">
                  <button
                    className="bg-blue text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href="youtube"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                </a>

                <a href="">
                  <button
                    className="bg-blue text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href="youtube"
                  >
                    <i className="fab fa-linkedin"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* mobile and tab view */}
          <div className="xl:hidden lg:hidden md:block sm:block block md:py-4 py-4">
            <div className="px-0">
              <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <div className={`px-0 ${navbarOpen ? "hidden" : ""}`}>
                  <span>
                    <a href="index">
                      <img
                        src={ibusinesslogo}
                        alt=""
                        className="xl:h-12 xl:w-52 md:h-45 md:w-48 lg:h-12 h-10 w-48 cursor-pointer ml-2"
                      />
                    </a>
                  </span>
                </div>
                <div className="xl:ml-maximum2 lg:ml-maximum">
                  <ul className="">
                    <div className="menu navbar-links">
                      <div
                        className="menu-item"
                        onMouseEnter={handleFirstMenuHover}
                        onMouseLeave={handlePackagesMenuLeave}
                      >
                        <div className="items-center flex text-sky-800 text-sm sm:text-base font-medium pr-4 relative cursor-pointer">
                          <h1 className="navbar-dropdown white-on-hover pr-1 cursor-pointer xl:text-base1 lg:text-base para-font xl:pt-4 xl:pl-10">
                            <div className="buttonWithBlueround rounded-full px-4 py-3  xl:px-5 md:px-5 md:py-3 xl:py-4 lg:py-4 lg:px-6 font-bold">
                              {loggedInUser ? loggedInUser.name[0] : <span className="fa fa-user"></span>}
                            </div>
                          </h1>
                        </div>
                        <div className="md:w-22/12 w-5/12 mt-5 md:mt-5 md:-ml-28 -ml-30 fixed xl:top-14 lg:top-20 xl:w-1/12 lg:w-2/12 cursor-pointer">
                          {showFirstMenu && (
                            <div className="menu-dropdown bg-white rounded-md shadow-xl border-light-blue-alterpro xl:mt-5 xl:-ml-16 animate-slide-up">
                              <div className="pl-4 md:pl-4 pb-4">
                                <div className="xl:hidden lg:hidden md:hidden sm:hidden block">
                                <div className="items-center pt-4">
                                  <a
                                    href="/sidebar-landing"
                                    className="white-on-hover text-light-blue"
                                  >
                                    <p className="xl:text-base font text-base font-medium xl:-pt-4 md:pt-0 lg:pt-0 pt-0">
                                      Dashboard
                                    </p>
                                  </a>
                                </div>
                                <div className="items-center pt-4">
                                  <a
                                    href="/change-password"
                                    className="white-on-hover text-light-blue"
                                  >
                                    <p className="xl:text-base font text-base font-medium xl:-pt-4 md:pt-0 lg:pt-0 pt-0">
                                      Change password
                                    </p>
                                  </a>
                                </div>

                                </div>
                                <div className="items-center pt-4">
                                  <a
                                    href="#!"
                                    onClick={logoutUser}
                                    className="white-on-hover text-light-blue "
                                  >
                                    <p className="xl:text-base font text-base font-medium xl:pt-0 md:pt-0 lg:pt-0 pt-0">
                                      Sign Out
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
}
