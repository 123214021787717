import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";

const PrivacyPolicy = () => {
  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-18 mt-18">
        <div className="">
          <div className="bg-grey-50">
            <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
              <a href="index" className="link">
                Home{" "}
              </a>{" "}
              ― Privacy policy
            </div>
          </div>
        </div>
        <div className="container mx-auto overflow-hidden xl:px-16 px-4 xl:pb-16 pb-8 md:pb-8">
          <h1 className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl text-center xl:pt-20 lg:pt-12 md:pt-12 xl:px-24 lg:px-32 pt-8">
            Privacy Policy
          </h1>
          <div className="text-left ">
            <p className="para-font xl:text-base font-semibold text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              Introduction
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              At iBC, we prioritize your privacy and are committed to
              maintaining the confidentiality and security of your personal
              information. This Privacy Policy outlines how we collect, use, and
              disclose your personal information in accordance with our
              commitment to customer satisfaction and the highest standards of
              trust and integrity.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Scope of this Policy
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              This Privacy Policy applies to all personal information ("Personal
              Data") that iBC collects, uses, or discloses about our customers.
              "Personal Data" refers to any information that can identify an
              individual, which we handle according to applicable data
              protection laws. This policy does not cover:
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              1. Information that is publicly available, such as names,
              addresses, telephone numbers, and email addresses, when listed in
              public directories.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              2. Business contact information, including names, titles, business
              addresses, or telephone numbers of employees.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              3. Data that is aggregated and cannot be linked to an identifiable
              individual, and other information that is not considered personal
              data under applicable law.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Collection of Personal Information
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              We collect Personal Information from you in several ways,
              including but not limited to:
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              1. Information you provide via our website forms, which may
              include your name, address, email, financial information, and
              other contact details.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              2. Billing and payment information, such as credit or debit card
              details, that you provide for purchasing our products or services.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              3. Location and movement data from devices when location services
              are enabled.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              iBC uses this data to deliver and enhance the services you
              request, verify your identity, manage our business operations, and
              improve the convenience and functionality of our services.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Consent
            </p>
            <p className="para-font xl:text-base text-dark-blue pt-1">
              Your consent to the collection, use, or disclosure of your
              personal information may be obtained in writing or electronically.
              Consent can also be implied through your actions. You have the
              right to opt out or withdraw your consent at any time, though this
              may affect our ability to provide you with certain services.
              Disclosure of Personal Information.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              We do not share your personal information with third parties,
              except as described in this Privacy Policy or when required by
              law, or with your explicit consent.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Responsibility and Compliance
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              iBC is committed to protecting your personal information in
              accordance with this Privacy Policy and relevant data protection
              regulations. Our Data Protection Officer oversees compliance and
              is available to address any questions or concerns you may have
              regarding our privacy practices.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              If you have any issues or complaints, we encourage you to contact
              our Data Protection Officer. We will investigate and respond
              promptly and, if necessary, adjust our policies and practices.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Communication Preferences
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              iBC respects your preferences concerning the receipt of
              promotional communications. You can update your communication
              preferences at any time, and changes will be implemented within 10
              business days at no cost. This Privacy Policy is subject to
              periodic updates and modifications. Please review it frequently to
              stay informed about how we are protecting your personal
              information.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};
export default PrivacyPolicy;
