import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";
import websitedesign from "assets/img/websitedesign.svg";
import mobileapp from "assets/img/mobileapp.svg";
import webapplicationdevelopment from "assets/img/webapplicationdevelopment.svg";
import digitalmarketing from "assets/img/digitalmarketing.svg";
import EnquiryButton from "components/Pages/EnquiryButton";
import itsupportnew from "assets/img/itsupportnew.svg";
import itconsultingnew from "assets/img/itcolsultingnew.svg";

const ItPage = () => {
  return (
    <>
      <section>
        <IndexNavbar />
        {/* EnquiryButton */}
        <EnquiryButton />
        <div className="xl:mt-20 lg:mt-18 mt-18">
          <div className="">
            <div className="bg-grey-50">
              <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
                <a href="index" className="link">
                  Home
                </a>
                ― IT Service
              </div>
            </div>
          </div>
          <div className="container mx-auto overflow-hidden xl:px-16 px-4">
            <div className="">
              <div className="items-center grid xl:grid-cols-2 xl:gap-12 md:grid-cols-2 md:gap-8 lg:grid-cols-2 lg:gap-8 items-center">
                <div className="xl:mt-16 lg:mt-4 mt-8">
                  <p className="uppercase xl:text-base md:text-xs text-sm text-cyan-500 ">
                    Service
                  </p>
                  <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl xl:pt-1 lg:pt-2 md:pt-2 pt-2">
                    IT Consulting & Services
                  </div>
                  <div className="font text-base text-justify text-light-blue para-font xl:pt-2 lg:pt-2 pt-1 xl:pb-0 pb-4">
                    iBusiness Consulting offers a comprehensive suite of IT
                    consulting services designed to help your business leverage
                    technology to achieve its full potential. Our team of
                    experienced and certified professionals works closely with
                    you to understand your unique needs and develop customized
                    solutions that drive efficiency, productivity, and growth.
                  </div>
                  <div className="font text-base text-justify text-light-blue para-font xl:pt-4 lg:pt-2 pt-1 xl:pb-0 pb-8">
                    Like IT Strategy & Planning, Website Design, Web & Mobile
                    App Development, Cloud Solutions, IT Project Management.
                  </div>
                </div>
                <div className="xl:mr-24">
                  <img
                    // src={websitedesign}
                    src={itconsultingnew}
                    alt="Partner Image"
                    className="rounded-md xl:mt-24 p-4 xl:ml-28 md:mt-8 lg:mt-8 mt-2 xl:w-350 lg:w-320 lg:ml-20"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ongoing it support */}

          <div className="container mx-auto overflow-hidden xl:px-16 xl:pb-20 lg:pb-12 md:pb-12 pb-12 px-4">
            <div className="bg-cover xl:mt-16 mt-8 md:mt-8">
              <div className="items-center grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 md:grid-cols-2 md:gap-8 md:flex-col-reverse lg:gap-8 flex-col-reverse xl:flex-col-reverse flex xl:flex-none xl:gap-12 gap-4">
                <div className="xl:mr-20 xl:mt-0 lg:mt-4 md:mt-20">
                  <img
                    // src={webapplicationdevelopment}
                    src={itsupportnew}
                    alt="Partner Image"
                    className="rounded-md xl:w-250 lg:w-230 xl:ml-28 lg:ml-36 xl:mr-0 lg:mr-116"
                  />
                </div>
                <div className="xl:mt-4 lg:mt-12 md:mt-4 mt-0">
                  <p className="uppercase xl:text-base md:text-xs text-sm text-cyan-500 xl:mt-14">
                    support
                  </p>
                  <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl lg:pt-2 md:pt-2 pt-2 xl:pt-1">
                    Ongoing IT support
                  </div>
                  <div className="font text-base text-justify text-light-blue para-font xl:pt-2 lg:pt-2 pt-1 xl:pb-0 pb-0">
                    At iBusiness Consulting, we understand that technology is an
                    ever-evolving landscape. That's why we offer comprehensive
                    ongoing IT support plans designed to keep your business
                    running smoothly and efficiently.
                  </div>
                  <div className="font text-base text-light-blue para-font xl:pt-2 lg:pt-2 pt-1 xl:pb-0 pb-8">
                    Like Help Desk Support, Maintenance, Monitoring & Management
                    and more.
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <Footer />
        </div>
      </section>
    </>
  );
};

export default ItPage;
