import worldbg from "assets/img/images/world-bg.svg";
import telegraph from "assets/img/logo/telegraph.webp";
import saintgobain from "assets/img/logo/saint-gobain.svg";
import pepsi2 from "assets/img/logo/pepsi-2.svg";
import global_logo_041 from "assets/img/logo/global_logo_04-1.webp";
import comcast_logo1 from "assets/img/logo/comcast_logo-1.svg";
import Reckitt from "assets/img/logo/Reckitt.webp";
import casio from "assets/img/logo/casio.webp";
import IndexNavbar from "components/Navbars/IndexNavbar";
import bluebuilding from "assets/img/images/bluebuilding.jpg";
import building from "assets/img/images/building.jpg";
import press from "assets/img/icons/press.svg";
import careers from "assets/img/images/careers.svg";
import Footer from "components/Footers/Footer";

const ServiceMain = () => {
  return (
    <section>
      <IndexNavbar fixed />
      <div className="relative xl:h-80 md:h-60 h-48">
        <div
          style={{
            backgroundImage: `url(${building})`,
            width: "100%",
            height: "970px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "absolute",
            marginTop: "5.7rem",
          }}
          className="top-0 left-0 opacity-1 fixed"
        ></div>
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-16">
          {/* <IndexNavbar /> */}
          <div>
            <p className="font xl:text-5xl md:text-2ml text-xl text-blue text-center xl:pt-32 md:pt-32  pt-28 font-semibold">
              Our Services
            </p>
            <p className="xl:px-36 font text-blue xl:text-lg text-center xl:pt-2">
              Since we began, Kissflow has embraced a culture that puts people
              first. A decade later, we continue to uphold this philosophy by
              inspiring our employees to reach their full potential with{" "}
              <b>
                {" "}
                courage, agility, creativity, independence, learning, and
                collaborative growth.
              </b>
            </p>
            <div className="container mx-auto relative xl:px-16 px-4 overflow-hidden">
              <div className="grid xl:grid-cols-2 xl:gap-6 xl:mt-16">
                <a href="corporate-service">
                  <div className="hero-sectionnew flex xl:w-12/12 xl:h-280 bg-light-orange cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border rounded-lg font">
                    <div className="xl:w-8/12">
                      <p className="font-semibold text-ivory-black xl:text-3xl xl:pt-8">
                        Corporate Services
                      </p>
                      <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                        Incorporating a Business in a foreign jurisdiction is
                        never easy.
                      </p>
                      {/* <div className="bg-blue border rounded-full text-white xl:w-7 xl:pl-1 xl:mt-4">
                      ﹥
                    </div> */}
                      <button className="buttonWithBlue xl:px-4 xl:py-2 xl:mt-4">
                        Get started ﹥
                      </button>
                    </div>
                    <div className="xl:w-4/12 xl:pt-6">
                      <img src={press} className="xl:w-60 xl:p-2" />
                    </div>
                  </div>
                </a>

                <a href="hr-service">
                  <div className="hero-sectionnew flex xl:w-12/12 xl:h-280 bg-light-green cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border rounded-lg font">
                    <div className="xl:w-8/12">
                      <p className="font-semibold text-ivory-black xl:text-3xl xl:pt-8">
                        HR Services
                      </p>
                      <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                        Incorporating a Business in a foreign jurisdiction is
                        never easy.
                      </p>
                      <button className="buttonWithBlue xl:px-4 xl:py-2 xl:mt-4">
                        Get started ﹥
                      </button>
                    </div>
                    <div className="xl:w-4/12 xl:pt-6">
                      <img src={careers} className="xl:w-60 xl:p-2" />
                    </div>
                  </div>
                </a>

                <a href="it-service">
                  <div className="hero-sectionnew flex xl:w-12/12 xl:h-280 xl:mt-2 bg-light-blue-new cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border rounded-lg font">
                    <div className="xl:w-8/12">
                      <p className="font-semibold text-ivory-black xl:text-3xl xl:pt-8">
                        IT Services
                      </p>
                      <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                        Incorporating a Business in a foreign jurisdiction is
                        never easy.
                      </p>
                      <button className="buttonWithBlue xl:px-4 xl:py-2 xl:mt-4">
                        Get started ﹥
                      </button>
                    </div>
                    <div className="xl:w-4/12 xl:pt-6">
                      <img src={careers} className="xl:w-60 xl:p-2" />
                    </div>
                  </div>
                </a>

                <a href="ip-service">
                  <div className="hero-sectionnew flex xl:w-12/12 xl:h-280 bg-neww xl:mt-2 cursor-pointer hover:bg-lightBlue-200 hover:shadow-lg shadow-xl xl:p-8 md:p-4 p-4 border rounded-lg font">
                    <div className="xl:w-8/12">
                      <p className="font-semibold text-ivory-black xl:text-3xl xl:pt-8">
                        IP Services
                      </p>
                      <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                        Incorporating a Business in a foreign jurisdiction is
                        never easy.
                      </p>
                      <button className="buttonWithBlue xl:px-4 xl:py-2 xl:mt-4">
                        Get started ﹥
                      </button>
                    </div>
                    <div className="xl:w-4/12 xl:pt-6">
                      <img src={press} className="xl:w-60 xl:p-2" />
                    </div>
                  </div>
                </a>
              </div>
            </div>

            {/* <div
              style={{ backgroundColor: "white" }}
              className="xl:mb-20 md:h-80 h-100"
            >
              <div className="container mx-auto xl:px-16 md:px-16 px-4"></div>
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default ServiceMain;
