import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import ApplicationContent2 from "components/Dropdowns/ApplicationContent2";
import ApplicationContent3 from "components/Dropdowns/ApplicationContent3";
import Cloudslider from "views/Cloudslider";
import sky from "assets/img/images/sky.jpg";
import discussion from "assets/img/images/discussion.jpg";
import privatepic from "assets/img/images/privatepic.jpg";
import atwork from "assets/img/images/atwork.jpg";
import modern from "assets/img/images/modern.jpg";
import cloud from "assets/img/images/cloud.jpg";
import path from "assets/img/images/path.jpg";
import letstalk from "assets/img/images/letstalk.jpg";
const Cloud1 = () => {
  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-22 mt-16">
        <div className="bg-stone-200 ">
          <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
            <a href="index" className="link">
              Home {"  "} {/* space purpose */}
            </a>
            {"  "}― {"  "}
            <a href="aboutt" className="link">
              Services {"  "}
            </a>
            {"  "} ― {"  "}
            Cloud
          </div>
        </div>
      </div>

      {/* section */}

      <div className="xl:pt-16 md:px-4 px-4">
        <div className="xl:block lg:block md:block block font">
          <div className="container mx-auto relative overflow-hidden">
            <div className="xl:p-12 lg:p-0 md:p-0 lg:p-0">
              <div className="grid xl:grid-cols-2 md:grid-cols-2 grid cols-1 md:mb-8">
                <div className="">
                  <p className="font xl:pt-8 lg:pt-20 md:pt-8 xl:text-4xl uppercase md:text-2xl lg:text-5xl text-xl pt-8 md:pt-16 font-semibold md:text-xl text-slate-700 pb-2">
                    Cloud Services
                  </p>
                  <div className="">
                    <p className="font text-sm font-thin xl:text-lg lg:text-xl text-black pb-4 xl:pt-4 md:pt-0">
                      Secure and managed cloud solutions that drive the
                      outcome-based modernization you need.
                    </p>
                  </div>
                  <a href="service">
                    {" "}
                    <button className="xl:buttonWithGradient md:buttonWithGradient buttonWithGradient py-1 px-4">
                      Service
                    </button>
                  </a>
                </div>
                <div className="xl:ml-4 mt-8">
                  <img
                    src={sky}
                    alt="your browser does not support this content"
                    className="rounded-xl "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="xl:block md:hidden hidden">
        <Cloudslider />
      </div>

      {/* <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pt-8">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 xl:pt-8 pt-8 lg:pt-0 md:pt-2">
          <div className="font xl:w-9/12 xl:text-4xl lg:text-3xl md:text-2xl text-xl font-thin md:font-thin xl:w-9/12 xl:py-0 ">
            Unlock innovation with the right cloud solutions
          </div>
          <div className="font xl:text-lg lg:text-lg md:text-sm text-sm md:py-4 lg:py-0 py-4 xl:py-0">
            Cloud has emerged as an enabler of transformation, but there are
            still significant barriers to cloud expansion and many enterprises
            are struggling to prove transformation value or to tie IT value to
            business outcomes. marslab Cloud Services provide a 360-degree
            approach identifying and solving challenges and driving
            transformation forward.
          </div>
        </div>
      </div> */}

      {/* cloud consulting section */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 md:py-4 xl:pt-24 pt-4 lg:py-8 py-10 ">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 ">
          <div className="xl:hidden md:hidden lg:hidden block">
            <div className="font xl:p-0 lg:p-5 md:p-0">
              <img
                src={discussion}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650 rounded-xl"
              ></img>
            </div>
          </div>
          <div className="xl:pt-12 md:pr-4 md:pt-6">
            <p className="xl:pt-12 lg:pt-20 pt-8 font uppercase font-semibold xl:text-4xl md:text-2xl text-xl font-square">
              Cloud Consulting
            </p>
            <p className="font text-black xl:text-lg text-sm xl:pt-8 md:pt-4 pt-4">
              Capitalize on our extensive knowledge of intricate IT environments
              to develop your optimal cloud strategy, simplify your IT
              architecture, and achieve your desired business outcomes.
            </p>
            <div className="mb-8 xl:mt-4">
              <a href="cloudservice">
                <button className="xl:buttonWithGradient md:buttonWithGradient buttonWithGradient py-1 px-4 xl:mt-8">
                  Explore cloud consulting ➙
                </button>
              </a>
            </div>
          </div>
          <div className="xl:block md:block lg:block hidden">
            <div className="font xl:p-0 lg:p-5 md:p-0">
              <img
                src={discussion}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650 rounded-xl"
              ></img>
            </div>
          </div>
        </div>
      </div>

      {/* private cloud services section */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 md:py-4 xl:pt-24 lg:py-8">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 ">
          <div className="xl:block md:block lg:block">
            <div className="font xl:p-0 lg:p-5 md:p-0">
              <img
                src={privatepic}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650 rounded-xl"
              ></img>
            </div>
          </div>
          <div className="xl:pt-0 pt-8 md:pt-2 xl:pl-8 md:pl-4">
            <p className="xl:pt-0 lg:pt-20 font uppercase font-semibold xl:text-4xl md:text-2xl text-xl font-square">
              Private Cloud Services
            </p>
            <p className="font text-black xl:text-lg text-sm xl:pt-8 md:pt-4 pt-4">
              Strike the perfect balance between cloud agility and private
              infrastructure stability, empowering you to modernize and manage
              your enterprise with enhanced compliance, security, and
              performance.
            </p>
            <p className="text-danger xl:text-lg font text-sm font-semibold xl:pt-4 pt-4">
              Marslab Private Cloud Managed Services:Unleash the Power of a
              Hybrid Cloud
            </p>

            <p className="font text-black xl:text-lg text-sm xl:pt-4 md:pt-4 pt-4">
              In today's dynamic business landscape, organizations are
              increasingly embracing hybrid cloud strategies to harness the
              benefits of both public and private cloud environments. Marslab
              Private Cloud Managed Services empowers you to seamlessly
              integrate these environments, enabling you to modernize your IT
              infrastructure while maintaining the performance, compliance, and
              security of a private cloud.
            </p>

           
          </div>
        </div>
      </div>

      {/* public cloud services section */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 md:py-4 xl:pt-24 lg:py-8 ">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 ">
          <div className="xl:hidden md:hidden lg:hidden block">
            <div className="font xl:p-0 lg:p-5 md:p-0 md:pt-8">
              <img
                src={atwork}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650 rounded-xl"
              ></img>
            </div>
          </div>
          <div className="xl:pr-8 md:pr-4">
            <p className="xl:pt-4 lg:pt-20 pt-8 font uppercase font-semibold xl:text-4xl md:text-2xl text-xl font-square">
              Public Cloud Services
            </p>
            <p className="font text-black xl:text-lg text-sm xl:pt-8 md:pt-4 pt-4">
              Embrace the transformative power of the public cloud with Marslab
              Public Cloud Services, meticulously designed to empower you to
              effortlessly design and deploy your applications and workloads on
              the optimal cloud platform for your enterprise. Our comprehensive
              suite of services prioritizes security, ease of management, and
              flexible deployment options, tailored to your specific needs.
            </p>
            <p className="font text-black xl:text-lg text-sm xl:pt-4 md:pt-4 pt-4">
              From initial cloud adoption to ongoing management, we provide
              integrated, full-lifecycle support for cloud environments,
              ensuring a smooth and successful journey. Our services are built
              on industry best practices and fully aligned with the unique cloud
              adoption and management frameworks of each hyperscale, ensuring
              seamless integration with your existing infrastructure.
            </p>
          </div>
          <div className="xl:block md:block lg:block hidden">
            <div className="font xl:p-0 lg:p-5 md:p-0 md:pt-8">
              <img
                src={atwork}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650 rounded-xl"
              ></img>
            </div>
          </div>
        </div>
      </div>

      {/*Modern Operations section */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 md:py-4 xl:pt-24 lg:py-8 py-10 ">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 ">
          <div className="xl:block md:block lg:block">
            <div className="font xl:p-0 lg:p-5 md:p-0 md:pt-4">
              <img
                src={modern}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650 rounded-xl"
              ></img>
            </div>
          </div>
          <div className="xl:pl-8 md:pl-4">
            <p className="xl:pt-24 md:pt-0 pt-4 lg:pt-20 font uppercase font-semibold xl:text-4xl md:text-2xl text-xl font-square">
              Modern Operations
            </p>
            <p className="font text-black xl:text-lg text-sm xl:pt-8 md:pt-4 pt-4">
              Unleash Operational Excellence with Marslab's Modern Operations
              Approach
            </p>
            <div className="mb-8 xl:mt-4">
              <a href="modernoperations">
                <button className="xl:buttonWithGradient md:buttonWithGradient buttonWithGradient py-1 px-4 xl:mt-8">
                  Explore modern oprations ➙
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Cloud Migration  section */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 md:py-4 xl:pt-24 lg:py-8">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 ">
          <div className="xl:hidden md:hidden lg:hidden block">
            <div className="font xl:p-0 lg:p-5 md:p-0">
              <img
                src={cloud}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650 rounded-xl"
              ></img>
            </div>
          </div>
          <div className="xl:pt-12 xl:pr-8 md:pr-4">
            <p className="xl:pt-12 pt-8 lg:pt-20 font uppercase font-semibold xl:text-4xl md:text-2xl text-xl font-square">
              Cloud Migration
            </p>
            <p className="font text-black xl:text-lg text-sm xl:pt-8 md:pt-4 pt-4">
              Effortless Cloud Migration for Your Enterprise Embark on a
              seamless cloud adoption journey with Marslab Cloud Migration
              Services.
            </p>
            <div className="mb-8 xl:mt-4">
              <a href="cloudmigration">
                <button className="xl:buttonWithGradient md:buttonWithGradient buttonWithGradient py-1 px-4 xl:mt-8">
                  Explore cloud migration ➙
                </button>
              </a>
            </div>
          </div>
          <div className="xl:block md:block lg:block hidden">
            <div className="font xl:p-0 lg:p-5 md:p-0">
              <img
                src={cloud}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650 rounded-xl"
              ></img>
            </div>
          </div>
        </div>
      </div>

      {/*  Managed Cloud Services section */}

      {/* <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 md:py-4 xl:pt-24 lg:py-8 py-10 ">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 ">
          <div className="xl:block md:block lg:block">
            <div className="font xl:p-0 lg:p-5 md:p-0">
              <img
                src={path}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650 rounded-xl"
              ></img>
            </div>
          </div>
          <div className="xl:pl-8 md:pl-4">
            <p className="xl:pt-12 md:pt-0 pt-4 lg:pt-20 font uppercase font-semibold xl:text-4xl md:text-2xl text-xl font-square">
              Managed Cloud Services
            </p>
            <p className="font text-black xl:text-lg text-sm xl:pt-8 md:pt-4 pt-4">
              With a suite of unparalleled capabilities across public, private,
              and hybrid cloud, marslab empowers enterprises to optimize and
              modernize their infrastructure and applications, enabling them to
              fully harness the potential of the cloud, while also offering an
              array of managed services that cover the entire IT stack. Maximize
              the full potential of your IT infrastructure with marslab Managed
              Services.
            </p>
            <div className="mb-8 xl:mt-4">
              <button className="buttoncoffeesmall">
                Explore managed services ➙
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {/* section */}

      <div className="bg-green-950 ">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 grid-cols-1">
            <div className="xl:p-12 lg:p-5 md:p-12 p-4 py-4  buttoncoffeesmallred xl:buttoncoffeesmallred">
              <p className="font text-white xl:text-4xl md:text-2xl text-2xl font-square">
                Reduce risk
              </p>
              <p className="font text-white xl:text-lg text-lg xl:pt-4 md:pt-4 pt-4">
                Our services are designed with integrated industry best
                practices, processes, and methodologies that are reinforced by
                24-7 support, that helps your service runs smoothly, minimizes
                the risk of failure and drives your ongoing success.
              </p>
            </div>
            <div className="xl:p-12 lg:p-5 md:p-12 p-4 buttoncoffeesmallred xl:buttoncoffeesmallred">
              <p className="font text-white xl:text-4xl md:text-2xl text-2xl font-square">
                Secure
              </p>
              <p className="font text-white xl:text-lg text-lg xl:pt-4 md:pt-4 pt-4">
                Our modern security and privacy measures safeguard your data and
                our time-tested processes coupled with our cloud governance,
                compliance, and visibility features.
              </p>
            </div>
            <div className="xl:p-12 lg:p-5 md:p-12 p-4 buttoncoffeesmallred xl:buttoncoffeesmallred">
              <p className="font text-white xl:text-4xl md:text-2xl text-2xl font-square">
                Manage
              </p>
              <p className="font text-white xl:text-lg text-lg xl:pt-4 md:pt-4 pt-4">
                Prioritize your business objectives by entrusting the management
                of your IT estate to the marslab experts. Our intelligent
                monitoring and pre-emptive management of hybrid multi-cloud.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* section */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 md:py-4 lg:py-8 py-10 ">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 ">
          <div className="xl:hidden md:hidden lg:hidden block">
            <div className="font xl:p-0 lg:p-5 md:p-5 mb-8">
              <img
                src={letstalk}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650  rounded-xl"
              ></img>
            </div>
          </div>
          <div>
            <p className="xl:pt-12 lg:pt-20 font text-red-500 xl:text-4xl md:text-2xl text-xl font-square">
              Get a 30-minute, no-cost strategy session with a Cloud Services
              expert
            </p>
            <p className="font text-black xl:text-lg text-sm xl:pt-8 md:pt-8 pt-4">
              Whether you are looking to develop a strategy, migrating to Cloud,
              obtain managed services, or further modernize and secure your
              applications and infrastructure, request an expert consultation at
              no cost.
            </p>
            <div className="mb-8">
              <button className="buttoncoffeesmall">Let's talk ➙</button>
            </div>
          </div>
          <div className="xl:block md:block lg:block hidden">
            <div className="font xl:p-0 lg:p-5 md:p-5 mb-8">
              <img
                src={letstalk}
                alt="Your browser does not support this part content"
                className="xl:h-450 xl:w-650 md:h-240 md:w-650  rounded-xl"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default Cloud1;
