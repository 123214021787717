import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";
import EnquiryButton from "components/Pages/EnquiryButton";
import hrservicecandidate from "assets/img/hrservicecandidate.png";
import hrserviceemployee from "assets/img/hrservice-employee.svg";

const HrServiceHomepage = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <>
      <section>
        <IndexNavbar />
        {/* EnquiryButton */}
        <EnquiryButton />
        <div className="xl:mt-20 lg:mt-18 mt-18">
          <div className="">
            <div className="bg-grey-50">
              <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
                <a href="index" className="link">
                  Home
                </a>
                ― Human Resource Service
              </div>
            </div>
          </div>

          {/* Employer Service */}

          <div className="container mx-auto overflow-hidden xl:px-16 px-4 xl:pb-16 lg:pb-8 md:pb-8 pb-8">
            <div className="">
              <div className="items-center grid xl:grid-cols-2 xl:gap-12 md:grid-cols-2 md:gap-8 lg:grid-cols-2 lg:gap-8">
                <div>
                  <p className="uppercase xl:text-base md:text-xs text-sm text-cyan-500 xl:mt-16 lg:mt-8 md:mt-12 mt-12">
                    Employer
                  </p>
                  <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl xl:pt-1 lg:pt-2 pt-2">
                    Employer Service
                  </div>
                  <div className="font text-justify text-base text-light-blue para-font leading-relaxed xl:pt-2 lg:pt-2 pt-1 xl:pb-2 lg:pb-2 md:pb-2 pb-2">
                    At iBC, we provide comprehensive recruitment solutions
                    tailored to meet the diverse needs of employers. Whether
                    you’re looking for temporary, permanent, or contract staff,
                    our services are designed to connect you with the ideal
                    candidates for your organization.
                  </div>
                  <div className="font text-justify text-base text-light-blue para-font leading-relaxed xl:pt-2 lg:pt-2 pt-1 xl:pb-2 lg:pb-2 md:pb-2 pb-2">
                    Our recruitment process is meticulously crafted to address
                    the unique requirements of each employer, ensuring a perfect
                    fit between the company and its future employees. We
                    leverage our extensive network and expertise to streamline
                    the hiring process, from candidate sourcing to final
                    placement.
                  </div>
                  <div className="xl:hidden lg:hidden md:hidden sm:hidden block">
                    <div className="para-font">
                      <p
                        className="figtree text-base underline-on-hover xl:text-base cursor-pointer font-bold text-light-blue"
                        onClick={toggleContent}
                      >
                        Learn more <span className="right-symbol">➜</span>
                      </p>
                    </div>
                    {isContentVisible && (
                      <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-0 lg:pt-2 pt-0 xl:pb-4 lg:pb-0 md:pb-4 pb-0">
                        In addition to recruitment, we collaborate with a wide
                        array of organizations to enhance the value we deliver
                        to our clients. Our partners include business
                        incubators, entrepreneurship centres, and other service
                        providers that complement our recruitment offerings.
                        Through these strategic partnerships, we aim to provide
                        holistic support to our clients, equipping them with the
                        resources and expertise necessary to thrive in today’s
                        competitive business environment. Let iBC be your
                        partner in building a skilled and dedicated workforce.
                      </div>
                    )}
                  </div>
                </div>
                <div className="h-auto xl:mr-24">
                  <img
                    src={hrservicecandidate}
                    alt="Partner Image"
                    className="rounded-md xl:p-0 lg:p-12 xl:mt-20 xl:ml-20 md:mt-0 lg:mt-8 mt-2"
                  />
                </div>
              </div>
              <div className="xl:block lg:block md:block sm:block hidden">
                <div className="xl:pt-0 pt-2 para-font">
                  <p
                    className="figtree text-base underline-on-hover xl:text-base cursor-pointer font-bold text-light-blue"
                    onClick={toggleContent}
                  >
                    Learn more <span className="right-symbol">➜</span>
                  </p>
                </div>
                {isContentVisible && (
                  <div className="text-left">
                    <div className="font text-base text-light-blue para-font leading-relaxed xl:pt-0 lg:pt-2 pt-1 xl:pb-4 lg:pb-0 md:pb-4 pb-0">
                      In addition to recruitment, we collaborate with a wide
                      array of organizations to enhance the value we deliver to
                      our clients. Our partners include business incubators,
                      entrepreneurship centres, and other service providers that
                      complement our recruitment offerings. Through these
                      strategic partnerships, we aim to provide holistic support
                      to our clients, equipping them with the resources and
                      expertise necessary to thrive in today’s competitive
                      business environment. Let iBC be your partner in building
                      a skilled and dedicated workforce.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*  Candidate Service */}

          {/* <div className="container mx-auto overflow-hidden xl:px-16 px-4 xl:pb-12">
            <div className="bg-cover xl:mt-0 mt-8 md:mt-8">
              <div className="items-center grid xl:grid-cols-2 grid-cols-1 md:grid-cols-2 md:gap-8 md:flex-col-reverse  lg:grid-cols-2 lg:gap-8 flex-col-reverse xl:flex-col-reverse flex xl:flex-none xl:gap-12 gap-4">
                <div className="h-auto  xl:mr-20 xl:mt-4 lg:mt-4 md:mt-20">
                  <img
                    src={hrserviceemployee}
                    alt="Partner Image"
                    className="rounded-md xl:w-450px lg:width-400px xl:p-8 lg:p-8 md:p-0 p-0"
                  />
                </div>
                <div className="xl:pb-0 md:pb-4 lg:pb-4 pb-0">
                  <p className="uppercase xl:text-base md:text-xs text-sm text-cyan-500 xl:mt-14 lg:mt-4">
                    Candidate Service benefits
                  </p>
                  <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl lg:pt-1 pt-2 xl:pt-2">
                    Candidate Service
                  </div>
                  <div className="font text-base text-light-blue para-font leading-relaxed xl:pt-1 lg:pt-2 pt-1 xl:pb-4 pb-8">
                    Become an affiliate partner and earn recurring commissions
                    by sharing iBusiness with your audience.
                    <ul className="list-none xl:pt-2 pt-2">
                      <li className="font text-base text-light-blue para-font flex items-start ">
                        <span className="mr-2 text-cyan-500">✔</span>Unique
                        referral link
                      </li>
                      <li className="font text-base text-light-blue para-font flex items-start py-2">
                        <span className="mr-2 text-light-blue">✔</span>Monthly
                        payouts
                      </li>
                      <li className="font text-base text-light-blue para-font flex items-start ">
                        <span className="mr-2 text-cyan-500">✔</span>Uncapped
                        commission
                      </li>
                      <li className="font text-base text-light-blue para-font flex items-start py-2">
                        <span className="mr-2 text-light-blue">✔</span> 45-day
                        tracking cookie
                      </li>
                      <li className="font text-base text-light-blue para-font flex items-start ">
                        <span className="mr-2 text-cyan-500">✔</span> Dedicated
                        support and resources
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <Footer />
        </div>
      </section>
    </>
  );
};

export default HrServiceHomepage;
