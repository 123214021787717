import React, { useState } from "react";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import contactus from "assets/img/icons/contactus.png";
import call from "assets/img/icons/call.png";
import mail from "assets/img/icons/mail.png";
import location from "assets/img/icons/location.png";
import axios from "axios";
import { toast } from "react-toastify";
import whatsapp from "assets/img/whatsapp.png";
import contactus1 from "assets/img/contactus1.svg";
import contactus2 from "assets/img/contactus2.svg";

const Contactus = () => {
  const [selectedService, setSelectedService] = useState("demo");
  const [enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    message: "",
  });

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
  };

  const handleButtonClickCall = () => {
    window.location.href = "tel:+1 514 679 3874 ";
  };

  const handleButtonClickWhatsapp = () => {
    window.location.href = "https://whatsapp.com?send=15146793874";
  };

  const handleButtonClickMail = () => {
    window.location.href = "mailto:info@ibusinessconsulting.ca";
  };

  const handleEnquirySubmit = () => {
    if (enquiry.name == "" || enquiry.name == null) {
      toast.error("Please enter your name");
    } else if (enquiry.mobile == "" || enquiry.mobile == null) {
      toast.error("Please enter your mobile");
    } else if (enquiry.email == "" || enquiry.email == null) {
      toast.error("Please enter your email");
    } else if (enquiry.service == "" || enquiry.service == null) {
      toast.error("Please select your service");
    } else if (enquiry.message == "" || enquiry.message == null) {
      toast.error("Please select your message");
    } else {
      const formData = { enquiry: enquiry };
      axios
        .post(
          "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/add-enquiry",
          formData
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status) {
              setEnquiry({
                name: "",
                email: "",
                mobile: "",
                service: "",
                message: "",
              });
              toast.success("Your enquiry has been sent successfully");
              window.location = "/thank-you";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <section>
      <IndexNavbar fixed />

      <div className="xl:mt-20 lg:mt-18 mt-18">
        <div className="">
          <div className="bg-grey-50">
            <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
              <a href="index" className="link">
                Home{" "}
              </a>{" "}
              ― Contact Us
            </div>
          </div>
        </div>

        <div className="xl:px-16 px-4 container xl:pt-24 lg:pt-12 pt-8 md:pt-12 mx-auto overflow-hidden">
          <div className="items-center grid xl:grid-cols-1 md:grid-cols-1 xl:gap-12 gap-4 md:gap-4 grid-cols-1">
            <div className="text-center ">
              <p className="text-cyan-500 uppercase xl:text-base lg:text-sm md:text-sm">
                contact us
              </p>
              <p className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl pt-2">
                The Fastest way to Contact Us
              </p>
              <p className="font text-base text-light-blue para-font leading-relaxed xl:px-52 lg:px-40 md:px-32 xl:pt-2 lg:pt-2 md:pt-0 pt-0">
                Whether you're an established organization or a startup, we are
                here to assist you in making informed decisions about your
                business in every way possible.
              </p>
              <button
                onClick={handleButtonClickMail}
                className="no-outline focus:outline-none buttonWithBlue font-semibold xl:text-base md:text-sm xl:px-8 px-8 py-2 mt-4 md:pt-2 xl:py-2 xl:mt-4"
              >
                Get In Touch
              </button>
            </div>
          </div>
        </div>

        <div className="container mx-auto relative overflow-hidden px-4 xl:pb-4 mt-4 xl:mt-8 lg:mt-8 md:pb-4 pb-4">
          <div className="grid xl:grid-cols-3 xl:gap-4 lg:grid-cols-3 md:gap-4 md:grid-cols-3 gap-4 grid-cols-1 xl:py-8 xl:px-8 lg:px-20 md:px-4 px-4 xl:pt-0 lg:pt-0 md:pt-4 pt-2">
            <div className="xl:p-8 md:p-4 p-4 border rounded-lg font">
              <div className="">
                <p className="uppercase text-blue xl:text-base md:text-base ">
                  We're here
                </p>
                <p className="xl:pt-2 font text-base text-light-blue para-font pt-2">
                  Our door is always open, and we welcome you to join us for a
                  good cup of tea anytime.
                </p>
              </div>
              <div className="align-items text-center">
                {/* <div className="flex xl:pt-3 md:pt-4 pt-4 ">
                  <img
                    src={call}
                    className="xl:h-5 xl:w-5 lg:h-5 lg:w-5 md:h-5 md:w-5 h-5 w-5"
                  />
                  <button
                    onClick={handleButtonClickCall}
                    className="font text-base text-light-blue para-font xl:pl-2 text-left md:pl-2 xl:pt-1 pl-2 no-outline focus:outline-none"
                  >
                    +1 514 679 3874
                  </button>
                </div> */}
                <div className="flex xl:pt-6 pt-2">
                  <img
                    src={mail}
                    className="xl:h-5 xl:w-5 lg:h-5 lg:w-5 md:h-5 md:w-5 h-5 w-5"
                  />
                  <button
                    onClick={handleButtonClickMail}
                    className="font text-base text-light-blue para-font xl:pl-2 text-left md:pl-3 pl-3 no-outline focus:outline-none"
                  >
                    info@ibusinessconsulting.ca
                  </button>
                </div>
                <div className="flex xl:pt-6 pt-2">
                  <img
                    src={location}
                    className="xl:h-5 xl:w-5 lg:h-5 lg:w-5 md:h-5 md:w-5 h-5 w-5"
                  />
                  <div className="font text-base text-light-blue para-font leading-relaxed text-left xl:pl-2 md:pl-2 pl-2">
                    135 Laurier Avenue West, Ottawa, ON K1P 5J2 Canada
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:p-8 md:p-1 p-4 border bg-white rounded-lg font">
              <div className="text-center align-items">
                <img
                  src={contactus1}
                  className="xl:h-32 xl:w-36 xl:ml-20 md:h-24 md:w-32 md:ml-8 lg:ml-18 ml-22 h-24 w-28 xl:mt-0 lg:mt-0 md:mt-4 mt-2"
                />
                <p className="font-semibold md:text-base text-dark-blue xl:text-lg md:text-base text-lg md:text-base xl:pt-2 md:pt-2 pt-2">
                  Contact Support
                </p>
                <p className="xl:pt-2 md:pt-1 font text-base text-light-blue para-font leading-relaxed px-4 pt-1">
                  "Got questions? We're here to help! Contact our support team,
                  and we'll get back to you ASAP."
                </p>
              </div>
              <div className="center-button">
                <button
                   onClick={handleButtonClickMail}
                  className="no-outline focus:outline-none text-white buttonWithBlue xl:text-sm md:text-xs font rounded-xl xl:px-8 py-2 px-10 xl:mt-4 md:px-4 lg:mt-2 md:mt-4 mt-4 font-semibold"
                >
                  Get Support
                </button>
              </div>
            </div>
            <div className="xl:p-8 md:p-1 p-4 border bg-white rounded-lg font">
              <div className="text-center align-items">
                <img
                  src={contactus2}
                  className="xl:h-32 xl:w-36 xl:ml-20 md:h-24 md:w-32 md:ml-8 lg:ml-18 ml-22 h-24 w-28 xl:mt-0 lg:mt-0 md:mt-4 mt-2"
                />
                <p className="font-semibold md:text-base text-dark-blue xl:text-lg md:text-base text-lg md:text-base xl:pt-2 md:pt-2 pt-2">
                  Contact Sales
                </p>
                <p className="xl:pt-2 xl:px-0 lg:px-6 pt-1 pt-1 text-center leading-relaxed font text-base text-light-blue para-font">
                  "Reach out to us via email, and we'll get back to you
                  promptly."
                </p>
              </div>
              <div className="center-button">
                <button
                  onClick={handleButtonClickMail}
                  className="no-outline focus:outline-none text-white buttonWithBlue xl:text-sm md:text-xs font rounded-xl xl:px-8 py-2 px-10 xl:mt-11 md:px-4 lg:mt-8 md:mt-10 mt-4 font-semibold"
                >
                  Talk to Sales
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bg-extra-light-grey"> */}
        <div className="container mx-auto xl:px-16 px-4 overflow-hidden">
          <div className="items-center xl:gap-4 grid xl:grid-cols-1 md:grid-cols-1 grid-cols-1">
            <div className="xl:px-60 lg:px-60 xl:pt-12 xl:pb-16 md:pb-8 lg:pb-12 pb-12 md:pt-8 pt-4 lg:pt-4">
              <p className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl">
                Enquiry Us
              </p>
              <p className="font pt-1 text-base text-light-blue para-font text-left pb-2">
                Reach out to us for any inquiries you may need. Our team is here
                to help you promptly and efficiently.
              </p>
              <form>
                <div className="grid grid-rows-4 xl:gap-2 md:gap-2 gap-2 xl:mt-4 mt-2 md:mt-4 lg:gap-2 lg:mt-2">
                  <input
                    type="text"
                    className="pl-4 border-on-hover rounded font text-sm border-grey-100 "
                    placeholder="Name"
                    value={enquiry ? enquiry.name : ""}
                    onChange={(e) =>
                      setEnquiry({ ...enquiry, name: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    className="pl-4 border-on-hover rounded font text-sm border-grey-100 "
                    placeholder="Phone"
                    value={enquiry ? enquiry.mobile : ""}
                    onChange={(e) =>
                      setEnquiry({ ...enquiry, mobile: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    className="pl-4 border-on-hover rounded font text-sm border-grey-100 "
                    placeholder="E-mail"
                    value={enquiry ? enquiry.email : ""}
                    onChange={(e) =>
                      setEnquiry({ ...enquiry, email: e.target.value })
                    }
                  />
                </div>

                <div className="grid lg:grid-rows-1">
                  <select
                    id="inquiryType"
                    className="text-grey-500 border-on-hover border-grey-100 mt-2 pl-4  font rounded px-20 xl:w-full py-2 md:py-2 text-sm"
                    value={enquiry ? enquiry.service : ""}
                    onChange={(e) =>
                      setEnquiry({ ...enquiry, service: e.target.value })
                    }
                  >
                    <option
                      value="Corporate Service"
                      className="font md:text-xs xl:text-base lg:text-base text-sm"
                    >
                      Corporate Service
                    </option>
                    <option
                      value="IT Service"
                      className="font md:text-xs xl:text-base lg:text-base text-sm"
                    >
                      IT Service
                    </option>
                    <option
                      value="HR Service"
                      className="font md:text-xs xl:text-base lg:text-base text-sm"
                    >
                      HR Service
                    </option>
                    <option
                      value="IP Service"
                      className="font md:text-xs xl:text-base lg:text-base text-sm"
                    >
                      IP Service
                    </option>
                  </select>
                </div>

                <div className="grid grid-rows-1 xl:gap-4 mt-2">
                  <textarea
                    className="pl-4 rounded border-on-hover font border-grey-100 md:text-xs xl:text-base lg:text-base text-sm"
                    placeholder="Drop us a line."
                    value={enquiry ? enquiry.message : ""}
                    onChange={(e) =>
                      setEnquiry({ ...enquiry, message: e.target.value })
                    }
                  />
                </div>

                <div className="grid grid-cols-2 xl:gap-4 lg:gap-2 md:gap-2 gap-2 xl:mt-8 mt-2 ">
                  <button
                    className="no-outline focus:outline-none font buttonWithBlue rounded border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                    type="button"
                    onClick={handleEnquirySubmit}
                  >
                    Send Message
                  </button>
                  <button
                    onClick={handleButtonClickCall}
                    className="no-outline focus:outline-none font buttonWithBlue rounded border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                  >
                    Call
                  </button>
                </div>

                <div className="grid grid-rows-1 xl:mt-4 lg:mt-2 md:mt-1 mt-2 ">
                  <button
                    className="no-outline focus:outline-none rounded buttonWithBlue border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                    onClick={handleButtonClickWhatsapp}
                  >
                    <div className="flex font justify-center">
                      <img
                        src={whatsapp}
                        className="w-4 h-4 mr-2 xl:mt-0 md:mt-0 mt-1"
                      />
                      Whats App
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Contactus;
