const ApplicationContent3 = () => {
  return (
    <section>
      {/* section */}
      <div className=" bg-blueGray-100">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
          {" "}
          <p className="font xl:text-4xl lg:text-3xl md:text-2xl text-2xl xl:pb-8 lg:pl-4  pt-4 pb-4">
            Customer Stories
          </p>
          <div className="grid xl:grid-cols-3 lg:grid-cols-3  md:grid-cols-2 xl:w-12/12 xl:gap-4 md:gap-4 gap-4 xl:pb-12 md:mb-8">
            <div className=" xl:p-3 md:p-5 p-0 xl:pt-0 pt-4">
              <img
                src="https://i.ibb.co/r5Yxpyc/Dilip-Buildcon-01-4x3.jpg"
                alt="Your browser does not support this part content"
              ></img>
              <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4">
                Dilip Buildcon Limited
              </p>
              <p className="font xl:text-base xl:py-8 md:py-8 py-4">
                "It gives me satisfaction and pride to have realized our dream
                of bringing technology into what we do best, and also to have
                partnered with the best global players—[Kyndryl] and SAP— in
                this area.”
              </p>
              <button className="buttoncoffeesmall">Learn more →</button>
            </div>
            <div className="xl:p-3 md:p-5 p-0 xl:pt-0 pt-4">
              <img
                src="https://i.ibb.co/Bc24ZwY/atmosphericcar-4x3.jpg"
                alt="Your browser does not support this part content"
              ></img>
              <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4">
                Mitsubishi Motors Australia Ltd.
              </p>
              <p className="font xl:text-base xl:pt-8 xl:pb-12  md:py-8 py-4">
                "The actual migration of data from the old system to the new
                system was seamless. It was done without disruption to the
                business."
              </p>
              <div className="xl:mt-8 lg:mt-4">
                <button className="buttoncoffeesmall">Learn more →</button>
              </div>
            </div>

            <div className="xl:p-3 md:p-5 p-0 xl:pt-0 pt-4">
              <img
                src="https://i.ibb.co/wzGgndk/ls-treesreflectedonlake-4x3.jpg"
                alt="Your browser does not support this part content"
              ></img>
              <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4">
                Mondi Group
              </p>
              <p className="font xl:text-base xl:pt-8 xl:pb-12  md:py-8 py-4">
                “For more than a decade, we’ve relied on managed services from
                Kyndryl to support our SAP business systems, and Kyndryl has
                never let us down.”
              </p>
              <div className="xl:mt-2 lg:mt-12">
                <button className="buttoncoffeesmall">Learn more →</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-blueGray-100">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
        <p className="font xl:text-4xl lg:text-3xl md:text-2xl text-2xl xl:pb-8 xl:pt-4 md:pt-4 md:pb-4 pb-4 pt-4">
          Resources
        </p>
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 xl:w-12/12 xl:gap-4 md:gap-4 gap-4 xl:pb-12 md:mb-8 mb-8">
          <div className="bg-white xl:p-3 md:p-5 p-5 mb-4 md:mb-4">
            <img
              src="https://i.ibb.co/sK3V8xj/p-ind-engineerscoolingtower-4x3.jpg"
              alt="Your browser does not support this part content"
            ></img>
            <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4">
              Accelerate cloud adoption
            </p>
            <p className="font xl:text-base xl:py-8 md:py-8 py-4">
              "It gives me satisfaction and pride to have realized our dream of
              bringing technology into what we do best, and also to have
              partnered with the best global players—[Kyndryl] and SAP— in this
              area.”
            </p>
            <div className="xl:mt-8 xl:pb-4">
              <a href="" className="link">
                Read thought leadership report
              </a>
            </div>
          </div>
          <div className="bg-white xl:p-3 md:p-5 p-5 mb-4 md:mb-4">
            <img
              src="https://i.ibb.co/YBvKy7n/sky-archofwhitebridge-4x3.jpg"
              alt="Your browser does not support this part content"
            ></img>
            <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4">
              Managed cloud services for ERP applications
            </p>
            <p className="font xl:text-base xl:pt-8 xl:pb-12  md:py-8 py-4">
              "The actual migration of data from the old system to the new
              system was seamless. It was done without disruption to the
              business."
            </p>
            <div className="xl:mt-8 xl:pb-4 lg:pt-3">
              <a href="" className="link">
                Read the comparison guide
              </a>
            </div>
          </div>

          <div className="bg-white xl:p-3 md:p-5 p-5 mb-4 md:mb-4">
            <img
              src="https://i.ibb.co/6v2vcKf/atwork-businessmeetingmodernoffice-4x3.jpg"
              alt="Your browser does not support this part content"
            ></img>
            <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4">
              Research: Manage cloud ERP workloads
            </p>
            <p className="font xl:text-base xl:pt-8 xl:pb-12  md:py-8 py-4">
              “For more than a decade, we’ve relied on managed services from
              Kyndryl to support our SAP business systems, and Kyndryl has never
              let us down.”
            </p>
            <div className="xl:mt-2 xl:pb-4 lg:pt-3 ">
              <a href="" className="link">
                Read the Frost & Sullivan report
              </a>
            </div>
          </div>
        </div>
      </div></div>

      {/* section */}
      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 xl:py-12 px-4">
        <div className="xl:block md:hidden lg:block hidden">
          <div className="font">
            <img
              src="https://i.ibb.co/SBMRqpZ/team-maninmeetinglookingup-4x3.jpg"
              style={{ height: "400px", width: "600px" }}
              alt="Your browser does not support this part content"
            ></img>
          </div>
        </div>
        <div className="xl:hidden md:hidden lg:hidden block">
          <div className="font xl:p-0 xl:pl-12 lg:p-12">
            <img
              src="https://i.ibb.co/SBMRqpZ/team-maninmeetinglookingup-4x3.jpg"
              style={{ height: "260px", width: "600px", marginTop: "1rem" }}
              alt="Your browser does not support this part content"
            ></img>
          </div>
        </div>

        <div className="xl:hidden md:block lg:hidden hidden">
          <div className="font md:p-8">
            <img
              src="https://i.ibb.co/SBMRqpZ/team-maninmeetinglookingup-4x3.jpg"
              style={{ height: "490px", width: "700px" }}
              alt="Your browser does not support this part content"
            ></img>
          </div>
        </div>

        <div className="font">
          <div className="xl:p-0 xl:pl-12 lg:p-5">
            {/* <div className="ftDropDown pb-5"></div> */}
            <div className="xl:block md:block block">
              <p className="font xl:text-4xl md:text-2xl text-2xl text-black xl:pt-4 md:pt-4 pt-4">
                Get a 30-minute, no-cost strategy session with an Application
                services expert
              </p>
            </div>
            {/* <div className="xl:hidden md:block hidden">
                <p className="font font-thin  md:text-5xl text-black xl:pt-4 md:pt-4">
                  Get a 30-minute, no-cost strategy session with an Application
                  services expert
                </p>
              </div> */}
            <div className="font xl:text-lg md:text-lg xl:pt-8 md:pt-8 pt-4">
              Talk to our experts to get advise on how you can continuously
              transform your applications portfolio with speed and agility and
              drive innovation and business value.
            </div>
            <div className="md:my-0 my-6">
              <button className="buttoncoffeesmall">Let's talk ➙</button>
            </div>
          </div>
        </div></div>
      </div>
    </section>
  );
};

export default ApplicationContent3;
