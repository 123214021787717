import IndexNavbar from "components/Navbars/IndexNavbar";
import React, { useState } from "react";
import forgotpassword from "assets/img/forgotpassword.svg";
import { toast } from "react-toastify";
import axios from "axios";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handleEmailFocus = () => {
    setEmailFocused(true);
  };

  const handleEmailBlur = () => {
    setEmailFocused(false);
  };

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setPasswordFocused(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleResetPassword = () => {
    if(email == '')
    {
      toast.error("Email is required")
    }
    else
    {
      axios.post('https://admin.ibusinessconsulting.ca/admin/public/index.php/api/reset-password', {
        email: email
      })
      .then((response) => {
        if(response.status == 200)
        {
          if(response.data.status)
          {
            toast.success(response.data.message);
            setTimeout(() => {
              window.location= '/';
            },2000);
          }
        }
        else
        {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }

  const handleLanguageToggle = () => {
    // Toggle the display of the dropdown content
    const dropdownContent = document.getElementById("dropdown-content");
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
    } else {
      dropdownContent.style.display = "block";
    }
  };

  return (
    <section>
      <IndexNavbar />
        <div className="overflow-hidden ">
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 xl:gap-8 lg:gap-4 md:gap-4 gap-4">
            <div className=" bg-extra-light-grey">
              <div className="xl:ml-16 lg:ml-0 md:ml-0 ml-0 xl:px-32 md:px-56 lg:px-8 px-4 xl:pt-52 lg:pt-40 md:pt-40 pt-48 px-12 xl:pb-4 lg:pb-64 md:pb-52 pb-64">
                <h1 className="font  text-dark-blue xl:text-2xl lg:text-3xl md:text-3xl text-3xl font-semibold xl:pt-12">
                  Reset Password
                </h1>
                <p className="w-10/12 text-dark-blue font xl:text-sm font-medium xl:pr-16 xl:pt-1 pt-2 pb-3">Enter your email address and we will send you a link to reset your password.</p>
                <div className="login-form xl:pt-8 pt-4 md:pt-4 xl:pr-16">
                  <div className="input-container cursor-pointer w-10/12 xl:w-12/12">
                  <input type="email" placeholder="Enter Email" className="rounded-md border-grey-100 w-full" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    {/* <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      onFocus={handleEmailFocus}
                      onBlur={handleEmailBlur}
                      required
                      className={
                        emailFocused || email
                          ? "active rounded-md border-grey-100"
                          : "rounded-md border-grey-100 "
                      }
                    />
                    <label className="rounded-md font flex">
                      <img
                        src="https://i.ibb.co/c6Rbp1T/email-4546924.png"
                        className="w-4 h-4 mt-1"
                      ></img>
                      <p className="pl-2">Email</p>
                    </label> */}
                  </div>
                  

                  <button className="no-outline focus:outline-none rounded-md buttonWithBlue text-white xl:text-base lg:text-base text-base para-font font-medium w-10/12 xl:w-12/12 px-8 py-2 xl:mt-0 mt-8" type="button" onClick={handleResetPassword}>
                    Submit
                  </button>
                  {/* <div  className="mt-4">
                  <a href="/reset-password">
                      <p className="forgot-password font text-dark-blue font-semibold text-sm">
                        Forgot Password?
                      </p>
                    </a></div> */}
                </div>
                <div className="justify-between flex border-t xl:mt-16 mt-8 lg:mt-12">
                  <a href="/login" className="pt-2">
                    <p className="forgot-password  font text-dark-blue font-semibold text-sm">
                    ← Back To Login
                      {/* <a href="corporate-wizard">
                        <span className="forgot-password text-dark-blue font-semibold pl-2">
                          Sign Up
                        </span>
                      </a> */}
                    </p>
                  </a>
                  {/* <div className="dropdown pt-2" id="dropdown">
                    <a
                      className="font"
                      href="#"
                      id="dropdown-toggle"
                      onClick={handleLanguageToggle}
                    >
                      Language »
                    </a>
                    <div className="dropdown-content" id="dropdown-content">
                      <a className="font p-5 mt-2" href="#">
                        English
                      </a>
                      <br></br>
                      <a className="font p-5 mt-2" href="#">
                        French
                      </a>
                    </div>
                  </div> */}
                  
                </div>
              </div>
            </div>
            <div className="xl:block lg:block md:hidden hidden">
            <div className="xl:pt-16 lg:pt-40 md:pt-0 pt-10 xl:pb-8 lg:pb-0 md:pb-0 pb-8 xl:px-16 px-4 xl:p-0 p-8">
              <img src={forgotpassword} className="xl:p-16 xl:h-650 md:p-12 lg:p-0 p-0"></img>
            </div>
          </div></div>
        </div>

      {/* <Footer /> */}
    </section>
  );
}
