import React, { useState, useEffect } from "react";
import Footer from "components/Footers/Footer.js";
import ibusinessbackgroundnew from "assets/img/ibusinessbackgroundnew.svg";
import gift from "assets/img/gift.png";
import saving from "assets/img/saving.png";
import clock from "assets/img/clock.png";
import corporationservicenew from "assets/img/corporationservicenew.svg";
import itservicenew from "assets/img/itservicenew.svg";
import hrservicenew from "assets/img/hrservicenew.svg";
import ipservicenew from "assets/img/ipservicenew.svg";
import EnquiryButton from "components/Pages/EnquiryButton";

export default function Homepage1({ isHomePage }) {
  //Home page pop up
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
    localStorage.setItem("popupShown", "true");
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const popupShown = localStorage.getItem("popupShown");
    if (!popupShown) {
      setPopupOpen(false);
      localStorage.setItem("popupShown", "false");
      openPopup();
    }
    return () => {
      closePopup();
    };
  }, []);

  //Home page poop up end
  return (
    <section>
      <EnquiryButton />

      {/* Banner desktop and tab landscape view */}
      <div className="xl:block lg:block md:hidden hidden">
        <div
          style={{
            backgroundImage: `url(${ibusinessbackgroundnew})`,
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="xl:h-590 lg:h-385 xl:mt-20 lg:mt-18 mt-18"
        >
          {isPopupOpen && (
            <div className="popup-container-home xl:w-11/12 flex ">
              <div className="popup-content text-semibold text-ivory-black ">
                <div className="xl:block lg:block md:hidden hidden">
                  <p className="text-left">
                    Your privacy is important to us. We use cookies to improve
                    your experience of our website by improving performance,
                    tailoring content and advertising, and providing social
                    media features. Below you can manage your cookie
                    preferences. You can update these settings at any time.
                  </p>
                  <p>
                    <span className="text-blue pr-4">Necessary cookies</span>
                    Necessary icon These cookies are essential for the website
                    to function and cannot be enabled.
                  </p>
                </div>

                <div className="xl:hidden lg:hidden md:block block">
                  <p className="md:ml-4">
                    Your privacy is important to us. We use cookies to improve
                    your experience of our website by improving performance,
                    tailoring content and advertising, and providing social
                    media features. Below you can manage your cookie
                    preferences. You can update these settings at any time.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="xl:pt-6 md:pt-6">
                  <button
                    className="popup-button1 px-4 xl:px-6"
                    onClick={closePopup}
                  >
                    Agree
                  </button>
                </div>
                <div className="xl:pt-6 md:pt-6">
                  <button className="popup-button2 px-4" onClick={closePopup}>
                    Disagree
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="align-items text-center container mx-auto overflow-hidden xl:px-16 px-4">
            <div className="xl:pt-28">
              <p className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl text-center xl:pt-4 lg:pt-16 md:pt-44 xl:px-12 lg:px-16 pt-28">
                Streamline Your Business with iBusiness Consulting Solutions
              </p>
              <p className="xl:px-52 lg:px-52 font text-light-blue text-left text-base xl:text-lg lg:text-lg leading-relaxed text-center pt-2">
                Simplify your operations effortlessly with iBusiness Consulting.
                Register, incorporate, and manage employees all in one intuitive
                platform. Focus on growing your business while we take care of
                the rest.
              </p>
              <div className="flex center-button mt-4">
                <div className="xl:pt-0 pt-2 pb-2 para-font">
                  <a
                    href="/solution-homepage"
                    className="figtree text-base xl:text-lg font-semibold text-light-blue"
                  >
                    Learn More <span className="right-symbol">➜</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* banner tab view */}
      <div className="xl:hidden lg:hidden md:block hidden">
        <div
          style={{
            backgroundImage: `url(${ibusinessbackgroundnew})`,
            width: "100%",
            height: "400px",
            bbackgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            // position: "absolute",
          }}
        >
          <div className="align-items text-center container mx-auto overflow-hidden xl:px-16 px-4">
            <div className="xl:pt-32 md:pt-32 pt-28">
              <p className="md:px-32 figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl text-center">
                Streamline Your Business with iBusiness Consulting Solutions
              </p>
              <p className="md:px-28 para-font text-light-blue text-lg md:text-base xl:text-medium text-center md:pt-1 md:px-16 xl:pt-8">
                Simplify your operations effortlessly with iBusiness Consulting.
                Register, incorporate, and manage employees all in one intuitive
                platform. Focus on growing your business while we take care of
                the rest.
              </p>
              <div className="flex center-button mt-1">
                <div className="para-font">
                  <a
                    href="/solution-homepage"
                    className="figtree text-base xl:text-lg font-semibold text-light-blue"
                  >
                    Learn More <span className="right-symbol">➜</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile banner */}
      <div className="xl:hidden lg:hidden md:hidden block">
        <div className="align-items text-center container mx-auto overflow-hidden xl:px-16 px-4">
          <div className="xl:pt-32">
            <p className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl text-center xl:px-16 md:px-16 px-0 md:pt-6 pt-4 xl:pt-32 md:pt-32 xl:px-32 pt-28">
              Streamline Your Business with iBusiness Consulting Solutions
            </p>
            <p className="xl:px-36 para-font font text-light-blue text-left text-base xl:text-lg lg:text-xl xl:px-44 text-center md:pt-8 md:px-16 pt-4 xl:pt-8">
              Simplify your operations effortlessly with iBusiness Consulting.
              Register, incorporate, and manage employees all in one intuitive
              platform. Focus on growing your business while we take care of the
              rest.
            </p>
            <div className="flex center-button">
              <div className=" para-font">
                <a
                  href="/solution-homepage"
                  className="figtree text-sm xl:text-lg font-semibold text-light-blue"
                >
                  Learn More <span className="right-symbol">➜</span>
                </a>
              </div>
            </div>
          </div>
          <img src={ibusinessbackgroundnew} />
        </div>
      </div>

      {/* ALL-IN-ONE PLATFORM section */}

      <div className="bg-extra-light-grey">
        <div className="container mx-auto overflow-hidden xl:px-16 px-4 ">
          <div className="align-items text-center">
            <div className="xl:pt-20 md:pt-12 pt-8 lg:pt-12">
              <p className="text-blue uppercase xl:text-base text-sm font-medium">
                ALL-IN-ONE PLATFORM
              </p>
              <p className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl text-center xl:px-16 lg:px-0 md:px-32 px-0 pt-2">
                Empowering Entrepreneurs: Paving the Path to Success
              </p>
              <p className="xl:px-24 lg:px-20 md:px-12 font text-light-blue text-left text-base xl:text-lg lg:text-lg leading-relaxed text-center xl:pt-2 lg:pt-2 md:pt-2">
              ❝ iBusiness provides entrepreneurs with the tools they need at
                every stage of their journey. Start with hassle-free
                registration and continue to thrive with our comprehensive
                support. ❞
              </p>
            </div>

            <div className="grid xl:grid-cols-4 xl:pb-20 md:pb-12 pb-8 md:grid-cols-4 lg:grid-cols-4 grid-cols-1 xl:gap-4 lg:gap-4 md:gap-4 gap-4 xl:pt-12 lg:pt-8 md:pt-8 pt-12">
              <a href="/corporate-services">
                <div className="bg-white new-enterprises rounded-md xl:p-4 lg:p-4 md:p-2 p-4 cursor-pointer hover-move-right">
                  <p className="text-dark-blue xl:text-lg font font-semibold lg:text-sm md:text-sm xl:pt-4 pt-2 md:pt-2 lg:pt-4 text-lg">
                    Corporate Services
                  </p>
                  <p className="font text-base text-light-blue xl:pt-4 para-font md:pt-2 pt-2">
                    Incorporating a business abroad is complex due to diverse
                    tax laws, procedures, languages, and costs.
                  </p>
                  <div className="xl:pt-6 pt-4 pb-2 para-font">
                    <a
                      href="/corporate-services"
                      className="font text-base xl:text-base text-blue"
                    >
                      Learn More <span className="right-symbol">➜</span>
                    </a>
                  </div>
                  <img
                    src={corporationservicenew}
                    alt="First Image"
                    className="mt-4"
                  />
                </div>
              </a>

              <a href="/it-services">
                <div className="bg-white new-enterprises rounded-md xl:p-4 lg:p-4 md:p-2 p-4 cursor-pointer hover-move-right">
                  <p className="text-dark-blue xl:text-lg font font-semibold lg:text-sm md:text-sm xl:pt-4 pt-2 md:pt-2 lg:pt-4  text-xl">
                    IT Services
                  </p>
                  <p className="font text-base text-light-blue xl:pt-4 para-font md:pt-2 pt-2">
                    We provide full-scale IT services covering web design,
                    development, mobile app creation, and digital marketing.
                  </p>
                  <div className="xl:pt-6 pt-4 pb-2 para-font">
                    <a
                      href="/it-services"
                      className="font text-base xl:text-base text-blue"
                    >
                      Learn More <span className="right-symbol">➜</span>
                    </a>
                  </div>
                  <img src={itservicenew}></img>
                </div>
              </a>

              <a href="/hr-service-homepage">
                <div className="bg-white new-enterprises rounded-md xl:p-4 lg:p-4 md:p-2 p-4 cursor-pointer hover-move-right">
                  <p className="text-dark-blue xl:text-lg font font-semibold lg:text-sm md:text-sm xl:pt-4 pt-2 md:pt-2 lg:pt-4  text-xl">
                    HR Services
                  </p>
                  <p className="font text-base text-light-blue xl:pt-4 para-font md:pt-2 pt-2">
                    Our HR department expertly handles candidate recruitment,
                    training, and employee benefits programs.
                  </p>
                  <div className="xl:pt-6 pt-4 para-font">
                    <a
                      href="/hr-service-homepage"
                      className="font text-base xl:text-base text-blue"
                    >
                      Learn More <span className="right-symbol">➜</span>
                    </a>
                  </div>
                  <img src={hrservicenew} className="mt-1"></img>
                </div>
              </a>

              <a href="/ip-service-homepage">
                <div className="bg-white new-enterprises rounded-md xl:p-4 lg:p-4 md:p-2 p-4 cursor-pointer hover-move-right">
                  <p className="text-dark-blue xl:text-lg font font-semibold lg:text-sm md:text-sm xl:pt-4 pt-2 md:pt-2 lg:pt-4  text-xl">
                    IP Services
                  </p>
                  <p className="font text-base text-light-blue xl:pt-4 para-font">
                    Intellectual property, such as patents, copyrights, and
                    trademarks, is legally protected.
                  </p>
                  <div className="xl:pt-12 md:pt-11 pt-4 para-font">
                    <a
                      href="/ip-service-homepage"
                      className="font text-base xl:text-base text-blue"
                    >
                      Learn More <span className="right-symbol">➜</span>
                    </a>
                  </div>
                  <img src={ipservicenew} className="mt-2"></img>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container mx-auto overflow-hidden xl:px-16 px-4 ">
          <div className="align-items text-center">
            <div className="xl:pt-12 md:pt-12 xl:px-32 pt-8">
              <p className="text-blue uppercase xl:text-base text-sm font-medium">
                functions
              </p>
              <p className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl text-center xl:pt-0 lg:pt-2 md:pt-2 pt-2 text-2xl">
                Choose iBusiness for Your Business Needs
              </p>
              <p className="xl:px-4 md:px-12 pt-2 font text-light-blue text-left text-base xl:text-lg lg:text-lg leading-relaxed text-center">
                Whether you're starting your first company or seeking.
              </p>
            </div>
            <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-1 xl:gap-8 lg:gap-4 md:gap-4 gap-4 xl:pt-12 xl:pb-12 md:pb-8 pb-4 md:pt-2 pt-2">
              {/* <a href="/solution-homepage"> */}
              <div className="top-border rounded-md xl:p-6 lg:p-6 md:p-6 p-8 cursor-pointer hover-move-right">
                <div className="flex ">
                  <img
                    src={saving}
                    className="xl:h-12 xl:w-12 md:h-12 md:w-12 h-12 w-12 xl:mr-4 md:mr-4 mr-3"
                  ></img>
                  <p className="font-semibold  text-dark-blue text-left text-xl para-font xl:text-lg md:text-base xl:pt-3 md:pt-4 pt-2">
                    The Right Price
                  </p>
                </div>
                <div className=" ftDropDowngrey"></div>
                <p className="font text-light-blue text-left text-base xl:pt-3 md:pt-3 pt-2 pb-3">
                  At iBusiness, we offer competitive pricing without
                  compromising quality, tailored to your needs. Experience
                  transparency and value for your business
                </p>
              </div>
              {/* </a> */}
              {/* <a href="/solution-homepage"> */}
              <div className="top-border rounded-md xl:p-6 lg:p-6 md:p-6 p-8 cursor-pointer hover-move-right">
                <div className="flex ">
                  <img
                    src={gift}
                    className="xl:h-12 xl:w-12 md:h-12 md:w-12 h-12 w-12 xl:mr-4 md:mr-4 mr-3"
                  ></img>
                  <p className="font-semibold  text-dark-blue text-left text-xl para-font xl:text-lg md:text-base xl:pt-3 md:pt-1 pt-2">
                    iBusiness Membership
                  </p>
                </div>
                <div className=" ftDropDowngrey"></div>
                <p className="font text-light-blue text-left text-base xl:pt-3 md:pt-3 pt-2  pb-3">
                  Join iBusiness for exclusive benefits: personalized support,
                  premium resources, and networking events. Elevate your success
                  with our membership program.
                </p>
              </div>
              {/* </a>
              <a href="/solution-homepage"> */}
              <div className="top-border rounded-md xl:p-6 lg:p-6 md:p-6 p-8 cursor-pointer hover-move-right">
                <div className="flex ">
                  <img
                    src={clock}
                    className="xl:h-12 xl:w-12 md:h-12 md:w-12 h-12 w-12 xl:mr-4 md:mr-4 mr-3"
                  ></img>
                  <p className="font-semibold text-dark-blue text-xl text-left para-font xl:text-lg md:text-base xl:pt-3 md:pt-1 lg:pt-3 pt-2">
                    Save Time and Money
                  </p>
                </div>
                <div className=" ftDropDowngrey"></div>
                <p className="font text-left text-light-blue text-base xl:pt-3 md:pt-3 pt-2  pb-3">
                  Streamline operations, cut costs, and boost productivity with
                  iBusiness. Focus on growth while we handle the rest, saving
                  you time and money.
                </p>
              </div>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}
