import IndexNavbar from "components/Navbars/IndexNavBar2";
import React, { useEffect, useState } from "react";
import hrimage from "assets/img/hrimage.png";
import IndexNavbar2 from "components/Navbars/IndexNavBar2";
import { Input } from "postcss";
import axios from "axios";
import Loader from "components/Pages/Loader";
import ProfileSettings from "components/Homepage/ProfileSettings";
import ChangePassword from "components/Homepage/ChangePassword";

const Sidebar = () => {
  // company name start
  const [showSecondMenu, setShowSecondMenu] = useState(false);
  const handleSecondMenuHover = () => {
    setShowSecondMenu(true);
  };

  const handlePackagessMenuLeave = () => {
    setShowSecondMenu(false);
  };

  // company name end

  const [isHidden, setIsHidden] = useState(false);
  const [isDataAdded, setIsDataAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [visibleMenu, setVisibleMenu] = useState("dashboard");

  const handleButtonClick = () => {
    setIsHidden(!isHidden);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/check-corporate-details",
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.status == 200) {
          setIsDataAdded(response.data.status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function logoutUser() {
    setLoading(true);
    setLoadingMessage("Logging out...");
    if (localStorage.getItem("is_logged_in")) {
      let token = localStorage.getItem("token");
      axios
        .get(
          "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/logout",
          { headers: { Authorization: ` Bearer ${token}` } }
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              setLoading(false);
              localStorage.removeItem("token");
              localStorage.removeItem("is_logged_in");
              window.location = "/";
            }
          }
        });
    }
  }

  const [isSelected, setIsSelected] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    setIsSelected(true);
  };

  return (
    <>
      <Loader loading={loading} text={loadingMessage} />
      <section>
        <IndexNavbar2 />
        <div className="xl:block lg:block md:block block">
          <div className="containernew xl:mt-20 lg:mt-18 md:mt-20 mt-18">
            <div>
              {/* The button to toggle left side div */}
              <button className="toggleButton" onClick={handleButtonClick}>
                <div className="rounded-full buttonWithBlueround border-blue text-blue font-extrabold xl:text-xs md:text-sm text-xss xl:px-11 xl:py-1 lg:px-2 lg:py-1 md:px-2 md:py-1 px-2 py-1 xl:mt-2">
                  ﹥
                </div>
              </button>

              {/* Left content */}
              <div className="xl:block lg:block md:block sm:block hidden">
                <div
                  className={`leftContent xl:width-18 lg:width-22 md:width-25 width-0 ${
                    isHidden ? "hidden " : ""
                  }`}
                >
                  {/* Close button to hide left side div to 75% */}
                  <button
                    className="closeButton "
                    onClick={() => setIsHidden(true)}
                  >
                    <div className="rounded-full buttonWithBlueround border-blue text-blue font-extrabold xl:text-xs lg:text-xs md:text-sm text-xss xl:px-11 xl:py-1 lg:px-1 lg:py-1 md:px-2 md:py-1 px-2 py-1 xl:mt-2">
                      〱
                    </div>
                  </button>
                  <div>
                    <ul className="xl:px-4 md:px-4 px-2 md:flex-col md:min-w-full flex flex-col list-none gap-2 xl:pt-12 lg:pt-10 md:pt-12 pt-12">
                      <li
                        className={`items-center border-grey-50 light-blue-new rounded-md xl:pl-6 md:pl-6 pl-2 ${visibleMenu === "dashboard" ? 'selecteddd' : ''}`}
                      >
                        <a
                          href="#!"
                          onClick={() => setVisibleMenu("dashboard")}
                        >
                          <p className={`para-font xl:text-base py-3 font-medium block md:text-xs text-xss ${visibleMenu === "dashboard" ? 'text-white' : 'text-dark-blue'}`}>
                            <span className="fas fa-tv mr-2 xl:text-sm md:text-sm text-xss"></span>
                            Dashboard
                          </p>
                        </a>
                      </li>

                      <li className={`items-center border-grey-50 light-blue-new rounded-md xl:pl-6 md:pl-6 pl-2 ${visibleMenu === "profile" ? 'selecteddd' : ''}`}>
                        <a href="#!" onClick={() => setVisibleMenu("profile")}>
                          <p className={`para-font xl:text-base py-3 font-medium block md:text-xs text-xss ${visibleMenu === "profile" ? 'text-white' : 'text-dark-blue'}`}>
                            <span className="fa fa-user mr-2 xl:text-sm md:text-sm text-xss">
                            </span>
                            Profile
                          </p>
                        </a>
                      </li>

                      <li className={`items-center border-grey-50 light-blue-new rounded-md xl:pl-6 md:pl-6 pl-2 ${visibleMenu === "change-password" ? 'selecteddd' : ''}`}>
                        <a href="#!" onClick={() => setVisibleMenu("change-password")}>
                          <p className={`para-font xl:text-base py-3 font-medium block md:text-xs text-xss ${visibleMenu === "change-password" ? 'text-white' : 'text-dark-blue'}`}>
                            <span className="fas fa-unlock-alt mr-2 xl:text-sm md:text-sm text-xss pl-1"></span>
                            Change Password
                          </p>
                        </a>
                      </li>

                      <li className="items-center border-grey-50 light-blue-new rounded-md xl:pl-6 md:pl-6 pl-2">
                        <a href="#!" onClick={logoutUser}>
                          <p className="para-font xl:text-base py-3 font-medium block md:text-xs text-xss text-dark-blue">
                            <span className="fas fa-sign-out-alt mr-2 xl:text-sm md:text-sm text-xss">
                            </span>
                            Logout
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Right content */}
              {visibleMenu == "dashboard" && 
                <div
                  className={`rightContent xl:width-82 lg:width-75 md:width-75 width-full ${
                    isHidden ? "fullWidth" : ""
                  }`}
                >
                  {/* Add your right content here */}
                  <div className="bg-extra-light-grey xl:pl-8 xl:pt-16 lg:pt-12 md:pt-12 xl:px-16 pt-8 px-4 xl:px-0 xl:pb-32 lg:pb-32 md:pb-40 pb-96">
                    <div className="border-grey-50 xl:p-6 p-4 rounded-md bg-white shadow-md">
                      <div className="xl:flex lg:flex md:flex flex-none justify-between">
                        <div>
                          <h2 className="font text-dark-blue xl:text-base lg:text-base font-semibold md:text-base text-base pb-3">
                            Your Compliance Service
                          </h2>
                        </div>
                        <div>
                          <ul className="xl:pb-0 md:pb-0 pb-4">
                            <div className="menu navbar-links">
                              <div
                                className="menu-item"
                                onMouseEnter={handleSecondMenuHover}
                                onMouseLeave={handlePackagessMenuLeave}
                              >
                                <div className="items-center flex text-sky-800 text-sm sm:text-base font-medium pr-4 relative cursor-pointer">
                                  <h1 className="navbar-dropdown white-on-hover cursor-pointer xl:text-base1 lg:text-base para-font">
                                    <div className="flex">
                                      <div className="font hover:text-dark-900 xl:etxt-base text-sm font-semibold text-blue">
                                        Elegant Carve Ltd.
                                      </div>
                                      <div className="ml-6 font-semibold">⌵</div>
                                    </div>
                                  </h1>
                                </div>
                                <div className="fixed xl:-ml-8 lg:-ml-22 md:-ml-20 xl:mt-0 lg:mt-4 md:mt-4 mt-2 xl:top-14 lg:top-20 xl:w-1111/12 lg:w-3/12 md:w-4/12 w-10/12 cursor-pointer">
                                  {showSecondMenu && (
                                    <div className="menu-dropdown bg-white rounded-md shadow-xl border-light-blue-alterpro xl:mt-4 xl:-ml-12 animate-slide-up">
                                      <div className="pb-4">
                                        <div className="items-center pt-4">
                                          <div className="flex justify-between px-4">
                                            <div>
                                              <a
                                                href="/profile"
                                                className="white-on-hover text-light-blue"
                                              >
                                                <p className="xl:etxt-base text-sm hover:text-dark-900 font font-semibold">
                                                  Elegant Ltd.
                                                </p>
                                              </a>
                                            </div>
                                            <div className="bg-extra-light-grey font-semibold text-blue border-blue font text-xs rounded-md px-4 py-1">
                                              Incomplete
                                            </div>
                                          </div>
                                        </div>
                                        {!isDataAdded && (
                                          <div className="items-center pt-4">
                                            <a
                                              href="/corporate-wizard"
                                              className="white-on-hover text-light-blue "
                                            >
                                              <p className="xl:text-base text-center font text-blue text-sm font-semibold">
                                                + Add New Company
                                              </p>
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>

                      <div
                        className="border-t grid xl:grid-cols-3 xl:gap-2 md:grid-cols-2 md:gap-2 lg:grid-cols-3 lg:gap-2 gap-4"
                        style={{ height: isDataAdded ? 360 : 180 }}
                      >
                        {/* Service  */}
                        <div
                          className="px-2 items-center border-lightblue rounded-md xl:mt-8 md:mt-4 xl:p-6 p-4"
                          style={{ display: isDataAdded ? "block" : "none" }}
                        >
                          <div className="items-center">
                            <div className="mr-8 d-flex justify-center align-center">
                              <img src={hrimage} className="w-16 h-16" />
                            </div>
                            <div className="pl-0 pt-2">
                              <h2 className="text-center font text-dark-blue xl:text-lg lg:text-lg font-semibold md:text-lg text-lg border-b-blue pb-2 ">
                                Corporation Services
                              </h2>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 pt-4 xl:pl-4 pl-4">
                            <div className=" items-center justify-center">
                              <div>
                                <h2 className="font text-dark-blue xl:text-lg lg:text-base font-medium md:text-base text-base">
                                  Service status
                                </h2>
                                <p className="text- font-semibold font text-success">
                                  Active
                                </p>
                              </div>
                            </div>
                            <div className="xl:w-2/12 lg:w-3/12 xl:pl-16 lg:pl-12 flex items-center justify-center pl-0">
                              <div>
                                <h2 className="font text-dark-blue xl:text-lg lg:text-base font-medium md:text-base text-base xl:pt-0">
                                  Subscription
                                </h2>
                                <p className="text-lg font-bold font text-primary">
                                  $499
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-1 pt-4">
                            <div className="center-button">
                              <h2 className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base text-base">
                                Renewal Date
                              </h2>
                            </div>
                            <div className="center-button">
                              <p className="font text-blue">23-01-2002</p>
                            </div>
                          </div>
                          <div className="center-button">
                            <a href="/corporation-service">
                              <button className="buttonWithBlue font xl:py-2 xl:px-6 py-1 px-4  ml-0 font mt-2 text-white text-sm lg:w-36 w-32 rounded-md sm:w-80 hover:bg-lightBlue-600 hover:text-white">
                                Modify
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {visibleMenu == "profile" &&
                <ProfileSettings/>
              }
              {visibleMenu == "change-password" &&
                <ChangePassword/>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
