import axios from "axios";
import IndexNavbar2 from "components/Navbars/IndexNavBar2";
import IndexNavbar from "components/Navbars/IndexNavBar2";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Countries from 'countries.json';

const CorporationService = () => {
  const formSteps = [
    { label: "Profile", component: Step1 },
    { label: "Corporate Details", component: Step2 },
    { label: "Directors & Officers", component: Step3 },
    { label: "Corporate Ownership", component: Step4 },
    { label: "Individuals with Significant", component: Step5 },
    { label: "Choose Your Plan", component: Step6 },
    { label: "Billing Information", component: Step7 },
    { label: "Final Review", component: Step8 },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [userData, setUserData] = useState([]);
  const [corporateDetails, setCorporateDetails] = useState([]);
  const [corporateOwners, setCorporateOwners] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [corporateOwnerType, setCorporateOwnerType] = useState('myself');
  const [corporateOthersType, setCorporateOthersType] = useState('owners');
  const [corporateDirectors, setCorporateDirectors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [states, setStates] = useState([]);

  const handlePrev = () => {
    setCurrentStep(Math.max(0, currentStep - 1));
  };

  const handleNext = () => {
    if (currentStep == 0) {
      if (userData.name == "" || userData.name == null) {
        toast.error("Please enter first name");
      } else if (userData.last_name == "" || userData.last_name == null) {
        toast.error("Please enter last name");
      } else if (userData.mobile == "" || userData.mobile == null) {
        toast.error("Please enter mobile number");
      } else if (userData.email == "" || userData.email == null) {
        toast.error("Please enter email address");
      } else if (userData.gender == "" || userData.gender == null) {
        toast.error("Please select gender");
      } else if (userData.flat == "" || userData.flat == null) {
        toast.error("Please enter Flat/Building.No");
      } else if (userData.street == "" || userData.street == null) {
        toast.error("Please enter Area, Street, Sector, Village");
      } else if (userData.city == "" || userData.city == null) {
        toast.error("Please enter Town/City");
      } else if (userData.country == "" || userData.country == null) {
        toast.error("Please select Country");
      } else if (
        userData.address_state == "" ||
        userData.address_state == null
      ) {
        toast.error("Please select State");
      } else if (userData.postal_code == "" || userData.postal_code == null) {
        toast.error("Please enter postal code");
      } else {
        setCurrentStep(Math.min(formSteps.length - 1, currentStep + 1));
      }
    } else if (currentStep == 1) {
      if (
        corporateDetails.corporate_type == "" ||
        corporateDetails.corporate_type == null
      ) {
        toast.error("Please select company type");
      } else if (
        corporateDetails.corporate_naming_type == "" ||
        corporateDetails.corporate_naming_type == null
      ) {
        toast.error("Please select company naming type");
      } else if (
        corporateDetails.corporate_name == "" ||
        corporateDetails.corporate_name == null
      ) {
        toast.error("Please enter company name");
      } else if (
        corporateDetails.corporate_location == "" ||
        corporateDetails.corporate_location == null
      ) {
        toast.error("Please select company location");
      } else if (
        corporateDetails.formation_date == "" ||
        corporateDetails.formation_date == null
      ) {
        toast.error("Please select company formation date");
      } else {
        setCurrentStep(Math.min(formSteps.length - 1, currentStep + 1));
      }
    } else if (currentStep == 2) {
      if(corporateOwnerType == "myself")
      {
        setCorporateOthersType('')
        let token = localStorage.getItem("token");
        axios.post("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/update-ownertype", { who_manages: corporateOwnerType, others_manages: corporateOthersType }, 
        { headers: { Authorization: "Bearer " + token } })
        .then((response1) => {
          if(response1.data.status)
          {
            setCurrentStep(Math.min(formSteps.length - 1, currentStep + 1));
          }
        });
      }
      else
      {
        const presidents = corporateDirectors.filter(director => director.role === "President");
        const canadians = corporateDirectors.filter(director => director.canadian_citizen === "yes");
        let residentPercentage = ((canadians.length / corporateDirectors.length) * 100);
        console.log(residentPercentage);
        if(corporateDirectors.length === 0)
        {
          toast.error("Please add at least one directors/officers");
        }
        else if(presidents.length === 0)
        {
          toast.error("Please add atleast one president");
        }
        else if(residentPercentage < 25)
        {
          toast.error("Atleast 25% of directors must be canadian citizen");
        }
        else
        {
          let token = localStorage.getItem("token");
          axios.post("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/update-individuals", corporateDirectors, 
            { headers: { Authorization: "Bearer " + token}})
          .then((response) => {
            if(response.data.status)
            {
              console.log(response.data);  
            }
          })
          .catch((error) => {
            console.log(error);
          });
          axios.post("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/update-ownertype", { who_manages: corporateOwnerType, others_manages: corporateOthersType },
          { headers: { Authorization: "Bearer " + token } })
          .then((response1) => {
            if(response1.data.status)
            {
              setCurrentStep(Math.min(formSteps.length - 1, currentStep + 1));
            }
          })
          .catch((error1) => {
            console.log(error1);
          });
        }
      }
    } else if (currentStep == 3) {
      const totalOwnership = corporateOwners.reduce(
        (sum, owner) => sum + parseInt(owner.ownership),
        0
      );
      const hasInvalidName = corporateOwners.some(
        (item) => item.name.trim() === ""
      );
      if (hasInvalidName) {
        toast.error("Please enter valid name");
        return;
      }

      // Check if any name is a number
      const hasNumericName = corporateOwners.some((item) => !isNaN(item.name));
      if (hasNumericName) {
        toast.error("Please enter valid name");
        return;
      }

      const votingOwners = corporateOwners.filter(owner => owner.share_type == "voting");

      if (corporateOwners.length == 0) {
        toast.error("Please add your company ownership details");
      } else if(votingOwners.length == 0)
      {
        toast.error("Please add at least one voting owner");
      } 
      else if(totalOwnership < 100)
      {
        toast.error("Total ownership should be 100");
      }
       else if (totalOwnership > 100) {
        toast.error("Sum of ownership cannot exceed 100");
      } else {
        setCurrentStep(Math.min(formSteps.length - 1, currentStep + 1));
      }
    } else if (currentStep == 4) {
      let token = localStorage.getItem("token");
      axios.post("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/update-individuals", corporateDirectors, 
        { headers: { Authorization: "Bearer " + token}})
      .then((response) => {
        if(response.data.status)
        {
          setCurrentStep(Math.min(formSteps.length - 1, currentStep + 1));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    } else if (currentStep == 5) {
      setCurrentStep(Math.min(formSteps.length - 1, currentStep + 1));
    } else if (currentStep == 6) {
      if (
        billingAddress.first_name == "" ||
        billingAddress.first_name == null
      ) {
        toast.error("Please enter first name");
      } else if (
        billingAddress.last_name == "" ||
        billingAddress.last_name == null
      ) {
        toast.error("Please enter last name");
      } else if (billingAddress.flat == "" || billingAddress.flat == null) {
        toast.error("Please enter Flat/Building.No");
      } else if (billingAddress.street == "" || billingAddress.street == null) {
        toast.error("Please enter Area, Street, Sector, Village");
      } else if (billingAddress.city == "" || billingAddress.city == null) {
        toast.error("Please enter Town/City");
      } else if (
        billingAddress.country == "" ||
        billingAddress.country == null
      ) {
        toast.error("Please select Country");
      } else if (
        billingAddress.address_state == "" ||
        billingAddress.address_state == null
      ) {
        toast.error("Please select State");
      } else if (
        billingAddress.postal_code == "" ||
        billingAddress.postal_code == null
      ) {
        toast.error("Please enter postal code");
      } else {
        setCurrentStep(Math.min(formSteps.length - 1, currentStep + 1));
      }
    }
  };

  const CurrentStepComponent = formSteps[currentStep].component;

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/user-profile",
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setUserData(response.data.profile);
            setSelectedCountry(response.data.profile.country)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setCountries(Countries.map((item => item.name)))
  },[]);

  useEffect(() => {
    const country = Countries.find(country => country.name === selectedCountry);
    if (country) {
      setStates(country.states.map(state => state.name));
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/corporate-details",
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setCorporateDetails(response.data.details);
            setCorporateOwnerType(response.data.details.who_manages ? response.data.details.who_manages : 'myself')
            setCorporateOthersType(response.data.details.others_manages ? response.data.details.others_manages : '')
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/get-directors",
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setCorporateDirectors(response.data.directors);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/corporate-owners",
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setCorporateOwners(response.data.owners);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/billing-address",
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setBillingAddress(response.data.address);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const clearData = () => {
    let token = localStorage.getItem("token");
    axios
      .get(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/delete-details",
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            toast.success(response.data.message);
            window.location = "/sidebar-landing";
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    const formData = {
      userData: userData,
      corporateDetails: corporateDetails,
      owners: corporateOwners,
      billingAddress: billingAddress,
    };
    let token = localStorage.getItem("token");
    axios
      .post(
        "https://admin.ibusinessconsulting.ca/admin/public/index.php/api/corporate-details",
        formData,
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.status == 200) {
          window.location = "/thank-you";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isChecked) {
      setBillingAddress({
        ...billingAddress,
        flat: userData?.flat ?? "",
        street: userData?.street ?? "",
        city: userData?.city ?? "",
        country: userData?.country ?? "",
        address_state: userData?.address_state ?? "",
        postal_code: userData?.postal_code ?? "",
      });
    } else {
      setBillingAddress({
        ...billingAddress,
        first_name: "",
        last_name: "",
        flat: "",
        street: "",
        city: "",
        country: "",
        address_state: "",
        postal_code: "",
      });
    }
  }, [isChecked]);

  useEffect(() => {
    setBillingAddress({
      ...billingAddress,
      first_name: userData.name,
      last_name: userData.last_name,
    });
  }, [userData]);

  return (
    <div className="bg-extra-light-grey corporation-service xl:px-48 px-4 pt-24 xl:pt-32 rounded-md pb-40">
      <div className="flex xl:w-12/12 xl:gap-4 lg:gap-4 md:gap-4 gap-0">
        <div
          className="sticky rounded-md top-28 xl:w-3/12 md:w-5/12 shadow-lg xl:h-480px lg:h-435 md:h-380"
          // style={{ height: "480px", overflowY: "auto" }}
        >
          <div className="xl:block lg:block md:block sm:hidden hidden font buttonWithBlue border-light-blue-alterpro rounded-md shadow-lg tab-buttons xl:p-8 p-4">
            <div className="form-steps cursor-pointer xl:text-base xl:mb-2 ">
              <h2 className="font text-white xl:text-lg lg:text-xl font-semibold md:text-base text-xl pb-3 mb-4 border-b">
                Startup Services
              </h2>
              {formSteps.map((step, index) => (
                <div
                  key={index}
                  className={
                    currentStep === index
                      ? "active pb-4 text-white font-semibold font xl:text-base lg:text-base text-sm"
                      : "lightwhite-on-hover pb-4 xl:text-base lg:text-base text-sm"
                  }
                  onClick={() => setCurrentStep(index)}
                >
                  {step.label}
                </div>
              ))}
              <a href="/sidebar-landing">
                <button className="mb-6 buttonWithBlue text-sm border-white font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-4 md:py-1 border rounded-lg red-new text-white">
                  Go To Dashboard
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          className="xl:w-9/12 w-12/12 border-light-blue-alterpro rounded-md shadow-md"
          style={{ overflowY: "auto" }}
        >
          <div className="current-step">
            <CurrentStepComponent
              formData={formData}
              updateFormData={setFormData}
              handlePrev={handlePrev}
              handleNext={handleNext}
              userData={userData}
              setUserData={setUserData}
              corporateDetails={corporateDetails}
              setCorporateDetails={setCorporateDetails}
              corporateOwners={corporateOwners}
              setCorporateOwners={setCorporateOwners}
              billingAddress={billingAddress}
              setBillingAddress={setBillingAddress}
              setCurrentStep={setCurrentStep}
              handleSubmit={handleSubmit}
              clearData={clearData}
              setIsChecked={setIsChecked}
              isChecked={isChecked}
              corporateOwnerType={corporateOwnerType}
              setCorporateOwnerType={setCorporateOwnerType}
              corporateOthersType={corporateOthersType}
              setCorporateOthersType={setCorporateOthersType}
              corporateDirectors={corporateDirectors}
              setCorporateDirectors={setCorporateDirectors}
              countries={countries}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              states={states}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function Step1({
  formData,
  updateFormData,
  handlePrev,
  handleNext,
  userData,
  setUserData,
  corporateDetails,
  setCorporateDetails,
  corporateOwners,
  setCorporateOwners,
  billingAddress,
  setBillingAddress,
  setCurrentStep,
  handleSubmit,
  clearData,
  isChecked,
  setIsChecked,
  corporateOwnerType,
  setCorporateOwnerType,
  corporateOthersType,
  setCorporateOthersType,
  corporateDirectors,
  setCorporateDirectors,
  countries,
  selectedCountry,
  setSelectedCountry,
  states,
}) {

  const updateCountry = (e) => {
    setSelectedCountry(e.target.value);
    setUserData({ ...userData, country: e.target.value })
  }
  return (
    <section className="">
      <IndexNavbar2 />
      <div>
        <div className="xl:px-5 xl:p-8 p-4 md:p-4 lg:p-8 xl:text-lg bg-white ">
          {/* Desktop , Tab landscape and tab view */}
          <div className="xl:block lg:block hidden md:block">
            <h2 className="font text-cyan-500 font-medium text-base pb-3">
              Profile
            </h2>
          </div>
          {/* Mobile view */}
          <div className="xl:hidden lg:hidden block md:hidden">
            <div className="flex justify-between ">
              <div>
                <h2 className="font text-cyan-500 xl:text-lg lg:text-base font-medium md:text-base text-base pb-3">
                  Profile
                </h2>
              </div>
              <div>
                <a href="/sidebar-landing">
                  <button className="text-sm buttonWithBlueround xl:py-2 xl:px-6 py-1 px-4 lg:ml-16 ml-0 font text-white lg:w-36 w-44 mb-4 font rounded-md sm:w-80 hover:bg-lightBlue-600 hover:text-white">
                    Go To Dashboard
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="grid xl:grid-cols-2 md:grid-cols-2 xl:gap-2 md:gap-2 text-lg border-t">
            <div className="font xl:text-lg text-sm pt-4">
              <p className="text-dark-blue text-sm  font-medium font">
                First Name
              </p>
              <input
                type="text"
                placeholder="Enter First Name"
                className="font border-on-hover border-grey-50 rounded-md text-sm xl:text-sm mt-2 w-full"
                value={userData ? userData.name : ""}
                onChange={(e) =>
                  setUserData({ ...userData, name: e.target.value })
                }
              ></input>
            </div>
            <div className="font xl:text-lg text-sm pt-4">
              <p className="text-dark-blue text-sm font-medium font">
                Last Name
              </p>
              <input
                type="text"
                placeholder="Enter Last Name"
                className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm mt-2 w-full"
                value={userData ? userData.last_name : ""}
                onChange={(e) =>
                  setUserData({ ...userData, last_name: e.target.value })
                }
              ></input>
            </div>
            <div className="font xl:text-lg text-sm pt-6">
              <p className="text-dark-blue text-sm font-medium font">
                Phone number
              </p>
              <input
                type="text"
                placeholder="Enter Phone number"
                className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm  mt-2 w-full"
                value={userData ? userData.mobile : ""}
                onChange={(e) =>
                  setUserData({ ...userData, mobile: e.target.value })
                }
              ></input>
            </div>
            <div className="font xl:text-lg text-sm pt-6">
              <p className="text-dark-blue text-sm  font-medium font">Email</p>
              <input
                type="email"
                placeholder="Enter Email"
                className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm  mt-2 w-full"
                value={userData ? userData.email : ""}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              ></input>
            </div>
            <div className="font xl:text-lg text-sm pt-6">
              <p className="text-dark-blue text-sm  font-medium font">Gender</p>
              <select
                className="border-grey-50 border-on-hover formheading px-3 py-2 text-sm xl:text-sm w-full placeholder-blueGray-300 text-blueGray-600 bg-white rounded  ease-linear transition-all duration-150"
                key="5"
                value={userData ? userData.gender : ""}
                onChange={(e) =>
                  setUserData({ ...userData, gender: e.target.value })
                }
              >
                <option className="text-xs xl:text-sm " value="">
                  Please select one…
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>{" "}
            </div>
          </div>
          <h2 className="font text-cyan-500 font-medium text-base pb-3 pt-6">
            Address
          </h2>
          <div className="grid xl:grid-cols-2 md:grid-cols-2 xl:gap-2 md:gap-2 gap-2 text-lg border-t">
            <div className="font xl:text-lg text-sm pt-4">
              <p className="text-dark-blue text-sm  font-medium font">
                Flat/Building.No
              </p>
              <input
                type="text"
                placeholder="Enter Flat/Building.No"
                className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm w-full mt-2"
                value={userData ? userData.flat : ""}
                onChange={(e) =>
                  setUserData({ ...userData, flat: e.target.value })
                }
              ></input>
            </div>
            <div className="font xl:text-lg text-sm pt-4">
              <p className="text-dark-blue text-sm  font-medium font">
                Area, Street, Sector, Village
              </p>
              <input
                type="text"
                placeholder="Enter Area, Street"
                className="font border-grey-50 border-on-hover  rounded-md text-sm xl:text-sm w-full mt-2"
                value={userData ? userData.street : ""}
                onChange={(e) =>
                  setUserData({ ...userData, street: e.target.value })
                }
              ></input>
            </div>
            <div className="font xl:text-lg text-sm pt-6">
              <p className="text-dark-blue text-sm  font-medium font">
                Town/City
              </p>
              <input
                type="text"
                placeholder="Enter Town/City"
                className="font border-grey-50 border-on-hover  rounded-md text-sm xl:text-sm w-full mt-2"
                value={userData ? userData.city : ""}
                onChange={(e) =>
                  setUserData({ ...userData, city: e.target.value })
                }
              ></input>
            </div>
            <div className="font xl:text-lg text-sm pt-6">
              <p className="text-dark-blue text-sm font-medium font">Country</p>
              <select
                className="border-grey-50 border-on-hover mt-2 border-1 formheading px-3 py-2 w-full placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm xl:text-sm   focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                key="5"
                value={userData ? userData.country : ""}
                onChange={updateCountry}
              >
                <option>Please select one…</option>
                {countries.map((country) => (
                  <option value={country}>{country}</option>
                ))}
              </select>{" "}
            </div>
            <div className="font xl:text-lg text-sm pt-6">
              <p className="text-dark-blue text-sm font-medium font">State</p>
              <select
                className="border-grey-50 border-on-hover border-1 mt-2 formheading px-3 py-2 w-full placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm xl:text-sm   focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                key="5"
                value={userData ? userData.address_state : ""}
                onChange={(e) =>
                  setUserData({ ...userData, address_state: e.target.value })
                }
              >
                <option>Please select one…</option>
                {states.map((addState) => (
                  <option value={addState}>{addState}</option>
                ))}
              </select>{" "}
            </div>
            <div className="font xl:text-lg text-sm pt-6">
              <p className="text-dark-blue text-sm  font-medium font">
                Postal Code
              </p>
              <input
                type="text"
                placeholder="Enter Postal Code"
                className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm w-full mt-2"
                value={userData ? userData.postal_code : ""}
                onChange={(e) =>
                  setUserData({ ...userData, postal_code: e.target.value })
                }
              ></input>
            </div>
          </div>
          <button
            onClick={handleNext}
            className="text-sm buttonWithBlue border-blue font-medium px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

function Step2({
  formData,
  updateFormData,
  handlePrev,
  handleNext,
  userData,
  setUserData,
  corporateDetails,
  setCorporateDetails,
  corporateOwners,
  setCorporateOwners,
  billingAddress,
  setBillingAddress,
  setCurrentStep,
  handleSubmit,
  clearData,
  isChecked,
  setIsChecked,
  corporateOwnerType,
  setCorporateOwnerType,
  corporateOthersType,
  setCorporateOthersType,
  corporateDirectors,
  setCorporateDirectors,
  countries,
  selectedCountry,
  setSelectedCountry,
  states,
}) {
  return (
    <section>
      <IndexNavbar />
      <div>
        <div className="xl:px-5 xl:p-8 p-4 md:p-4 lg:p-8 xl:text-lg bg-white">
          <h2 className="font text-cyan-500 font-medium text-base pb-3">
            Corporate Details
          </h2>
          <div className="grid xl:grid-cols-1 md:grid-cols-1 xl:gap-2 text-lg border-t">
            <div className=" font xl:text-lg text-sm pt-4 xl:w-6/12 md:w-6/12">
              <p className="text-dark-blue text-sm font-medium font">
                My company will be a :
              </p>
              <select
                className="border-grey-50 border-on-hover border-1 mt-2 px-3 py-2  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm xl:text-sm w-full"
                key="5"
                value={corporateDetails ? corporateDetails.corporate_type : ""}
                onChange={(e) =>
                  setCorporateDetails({
                    ...corporateDetails,
                    corporate_type: e.target.value,
                  })
                }
              >
                <option value="">Select</option>
                <option value="Incorporation">Incorporate</option>
                <option value="Sole Proprietorship">Sole Proprietorship</option>
              </select>
            </div>
            <div className="font xl:text-lg text-sm pt-4 xl:w-6/12 md:w-6/12">
              <p className="text-dark-blue text-sm font-medium font">
                I want a company
              </p>
              <select
                className="border-grey-50 border-on-hover border-1 mt-2 px-3 py-2  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm xl:text-sm w-full"
                key="5"
                value={
                  corporateDetails ? corporateDetails.corporate_naming_type : ""
                }
                onChange={(e) =>
                  setCorporateDetails({
                    ...corporateDetails,
                    corporate_naming_type: e.target.value,
                  })
                }
              >
                <option value="">Select</option>
                <option value="name">Named</option>
                <option value="number">Numbered</option>
              </select>
            </div>
            <div className="font xl:text-lg text-sm pt-4 xl:w-6/12 md:w-6/12">
              <p className="text-dark-blue text-sm font-medium font">
                My company will be called
              </p>
              <input
                type="text"
                placeholder="Enter Company"
                className="font border-grey-50 border-on-hover  rounded-md text-sm xl:text-sm w-full mt-2"
                value={corporateDetails ? corporateDetails.corporate_name : ""}
                onChange={(e) =>
                  setCorporateDetails({
                    ...corporateDetails,
                    corporate_name: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="font xl:text-lg text-sm pt-4 xl:w-6/12 md:w-6/12">
              <p className="text-dark-blue text-sm font-medium font">
                My company will based on :
              </p>
              <select
                className="border-grey-50 border-on-hover border-1 mt-2 formheading px-3 py-2  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm xl:text-sm  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                key="5"
                value={
                  corporateDetails ? corporateDetails.corporate_location : ""
                }
                onChange={(e) =>
                  setCorporateDetails({
                    ...corporateDetails,
                    corporate_location: e.target.value,
                  })
                }
              >
                <option value="">Select</option>
                <option value="ALBERTA">ALBERTA</option>
                <option value="BRITISH COLUMBIA">BRITISH COLUMBIA</option>
                <option value="MANITOBA">MANITOBA</option>
                <option value="NEW BRUNSWICK">NEW BRUNSWICK</option>
                <option value="NEWFOUNDLAND and LABRADOR">
                  NEWFOUNDLAND and LABRADOR
                </option>
                <option value="NORTHWEST TERRITORIES">
                  NORTHWEST TERRITORIES
                </option>
                <option value="NOVA SCOTIA">NOVA SCOTIA</option>
                <option value="NUNAVUT">NUNAVUT</option>
                <option value="ONTARIO">ONTARIO</option>
                <option value="PRINCE EDWARD ISLAND">
                  PRINCE EDWARD ISLAND
                </option>
                <option value="QUEBEC">QUEBEC</option>
                <option value="SASKATCHEWAN">SASKATCHEWAN</option>
                <option value="YUKON">YUKON</option>
              </select>{" "}
            </div>
            <div className="font xl:text-lg text-sm pt-4 xl:w-5/12 md:w-6/12">
              <p className="text-dark-blue text-sm font-medium font">
                Company Formation Date
              </p>
              <input
                type="date"
                placeholder="Enter Date"
                className="font border-grey-50 border-on-hover  rounded-md text-sm xl:text-sm xl:w-7/12 mt-2"
                value={corporateDetails ? corporateDetails.formation_date : ""}
                onChange={(e) =>
                  setCorporateDetails({
                    ...corporateDetails,
                    formation_date: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>
          <div className="flex ">
            <button
              onClick={handlePrev}
              className="buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              className="ml-2 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

function Step3({
  formData,
  updateFormData,
  handlePrev,
  handleNext,
  userData,
  setUserData,
  corporateDetails,
  setCorporateDetails,
  corporateOwners,
  setCorporateOwners,
  billingAddress,
  setBillingAddress,
  setCurrentStep,
  handleSubmit,
  clearData,
  isChecked,
  setIsChecked,
  corporateOwnerType,
  setCorporateOwnerType,
  corporateOthersType,
  setCorporateOthersType,
  corporateDirectors,
  setCorporateDirectors,
  countries,
  selectedCountry,
  setSelectedCountry,
  states,
}) {

  const handleOwnershipChange = (e) => {
    setCorporateOwnerType(e.target.value);
  };

  //popup start billing address

  const [isDirectorOpen, setIsDirectorOpen] = useState(false);
  const [visibleIndex, setVisibleIndex] = useState(null);
  const [editDirector, setEditDirector] = useState(null);

  const toggleDirectorPopup = () => {
    setIsDirectorOpen(!isDirectorOpen);
  };

  const closeDirectorPopup = () => {
    setIsDirectorOpen(false);
  };

  //popup end billing address

  //popup second

  const [isNewDirectorOpen, setIsNewDirectorOpen] = useState(false);

  const toggleNewDirectorPopup = () => {
    setIsNewDirectorOpen(!isNewDirectorOpen);
  };

  const closeNewDirectorPopup = () => {
    resetTempDirector();
    setIsNewDirectorOpen(false);
  };

  const [tempDirector, setTempDirector] = useState({first_name: '', last_name:'', email: '', mobile: '', street1:'', street2: '', city: '', addressState: '', country: '', postal_code: '', canadian_citizen: 'yes'});
  const [shouldAddDirector, setShouldAddDirector] = useState(false);

  function resetTempDirector()
  {
    setTempDirector({first_name: '', last_name:'', email: '', mobile: '', street1:'', street2: '', city: '', addressState: '', country: '', postal_code: '', canadian_citizen: "no"});
  }

  useEffect(() => {
    if(shouldAddDirector)
    {
      addDirector();
      setShouldAddDirector(false);  
    }
  },[tempDirector, shouldAddDirector]);

  function addDirector()
  {
    let token = localStorage.getItem("token");
    axios.post("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/add-individual", {...tempDirector, 'role': 'Director'}, 
      {headers: {'Authorization': 'Bearer ' + token}}
    )
    .then((response) => {
      if(response.data.status)
      {
        axios.get("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/get-directors", 
          {headers: {'Authorization': 'Bearer ' + token}}
        )
        .then((response1) => {
          if(response1.data.status)
          {
            setCorporateDirectors(response1.data.directors);
            resetTempDirector();
            closeNewDirectorPopup();
            closeDirectorPopup();
          }
        })
        .catch((error1) => {
          console.log(error1);
        })
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  const [isOpen2, setIsOpen2] = useState(false);

  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };

  const closePopup2 = () => {
    setIsOpen2(false);
  };

  //popup second

  const [isDirectorContentVisible, setDirectorContentVisible] = useState(false);
  const [isContentVisible, setContentVisible] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const toggleDirectorContent = () => {
    setDirectorContentVisible(!isDirectorContentVisible);
  };
  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  // onclick open bottom text second one

  const [isContentPersonVisible, setContentPersonVisible] = useState(false);

  const toggleContentPerson = (index) => {
    setVisibleIndex(visibleIndex === index ? null : index);
  };

  //Add new Position popup

  const [isOpenPosition, setIsOpenPosition] = useState(false);

  const togglePopupPosition = () => {
    setIsOpenPosition(!isOpenPosition);
  };

  const closePopupPosition = () => {
    setIsOpenPosition(false);
  };

  //Add new Position popup

  const [isContentOfficersVisible, setContentOfficersVisible] = useState(false);

  const toggleContentOfficers = () => {
    setContentOfficersVisible(!isContentOfficersVisible);
  };

  //popup for president start

  const [isOpenPresident, setIsOpenPresident] = useState(false);

  const togglePopupPresident = () => {
    setIsOpenPresident(!isOpenPresident);
  };

  const closePopupPresident = () => {
    setIsOpenPresident(false);
  };

  //popup for president end

  // Contact Information Edit popup
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const togglePopupEdit = (index) => {
    setEditIndex(editIndex === index ? null : index);
    setEditDirector(corporateDirectors[index]);
    setSelectedCountry(corporateDirectors[index].country);
  };

  const closePopupEdit = () => {
    setEditIndex(null);
  };

  // Contact Information Edit popup

  // Contact Information Edit popup 2
  const [isOpenEdit2, setIsOpenEdit2] = useState(false);

  const togglePopupEdit2 = () => {
    setIsOpenEdit2(!isOpenEdit2);
  };

  const closePopupEdit2 = () => {
    setIsOpenEdit2(false);
  };

  // Contact Information Edit popup 2

  const saveChanges = (editIndex) => {
    const updatedDirectors = [...corporateDirectors];
    updatedDirectors[editIndex] = {...updatedDirectors[editIndex], ...editDirector}
    setCorporateDirectors(updatedDirectors);
    setEditIndex(null)
  }

  const deleteDirector = (deleteIndex, directorId) => {
    let token = localStorage.getItem("token");
    axios.post("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/delete-individual", { id: directorId }, 
      { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      if(response.data.status)
      {
        setCorporateDirectors(corporateDirectors.filter((_, index) => index !== deleteIndex));
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const updateTempCountry = (e) => {
    setSelectedCountry(e.target.value);
    setTempDirector({...tempDirector, country: e.target.value})
  }

  const updateEditCountry = (e) => {
    setSelectedCountry(e.target.value);
    setEditDirector({...editDirector, country: e.target.value})
  }

  const myselfAsDirector = () => {
    setTempDirector({
      ...tempDirector,
      first_name: userData ? userData.name : '',
      last_name: userData ? userData.last_name : '',
      email: userData ? userData.email : '',
      mobile: userData ? userData.mobile : '',
      street1: userData ? userData.flat : '',
      street2: userData ? userData.street : '',
      city: userData ? userData.city : '',
      addressState: userData ? userData.address_state : '',
      country: userData ? userData.country : '',
      postal_code: userData ? userData.postal_code : '',
      canadian_citizen: userData ? (userData.country === "Canada" ? "yes" : "no") : ''
    });
    setShouldAddDirector(true);
  }

  return (
    <section>
      <IndexNavbar />
      <div className="xl:px-5 xl:p-8 p-4 md:p-4 lg:p-8 xl:text-lg bg-white xl:pb-68 lg:pb-64 md:pb-56">
        {/* Overview */}
        <div>
          <h2 className="font text-blue xl:text-lg lg:text-base font-semibold md:text-base text-base pb-3">
            Overview
          </h2>
          <p className="pt-3 pb-2 text-dark-blue text-sm font-semibold font border-t">
            Company Ownership
          </p>
          <div>
            <div className="flex xl:mt-3 lg:mt-0 mt-0">
              <p className="md:pt-1 text-base text-light-blue para-font leading-relaxed">
                I will manage and own this company
              </p>
              <select
                className="xl:w-4/12 md:w-4/12 xl:ml-2 border-on-hover cursor-pointer border-grey-100 xl:mr-2 text-sm text-light-blue para-font leading-relaxed ml-2 rounded xl:h-10 lg:h-10 md:h-10 h-10 px-3"
                value={corporateOwnerType}
                onChange={handleOwnershipChange}
              >
                <option value="myself" className="text-sm">
                  By Myself
                </option>
                <option value="others" className="text-sm">
                  With Others
                </option>
              </select>
            </div>

            {corporateOwnerType === "others" && (
              <div className="flex xl:mt-6 mt-4">
                <p className="md:pt-1 text-base text-light-blue para-font leading-relaxed">
                  Others who will be involved
                </p>
                <select className="xl:w-4/12 md:w-3/12 xl:ml-2 border-on-hover cursor-pointer border-grey-100 xl:mr-2 text-sm text-light-blue para-font leading-relaxed ml-2 rounded xl:h-10 lg:h-10 md:h-10 h-10 pl-2 pr-7"
                  value={corporateOthersType}
                  onChange={(e) => setCorporateOthersType(e.target.value)}>
                  <option value="owners" className="text-sm">
                    Owners
                  </option>
                  <option value="managers" className="text-sm">
                    Managers
                  </option>
                  <option value="owners-managers" className="text-sm">
                    Owners and Managers
                  </option>
                </select>
              </div>
            )}
          </div>
        </div>

        {/* Directors */}

        {corporateOwnerType == "others" && 
        <>
          <div className="xl:pt-16 lg:pt-10 md:pt-10 pt-8">
          <div className="flex justify-between ">
            <div>
              <h2 className="font text-blue xl:text-lg lg:text-base font-semibold md:text-base text-base pb-3">
                Directors
              </h2>
            </div>
            <div className="text-blue xl:text-base font">
              <button onClick={toggleDirectorPopup} className="font-semibold text-sm">
                + Add New Directors
              </button>
            </div>

            {isDirectorOpen && (
              <div className="popupnew">
                {/* Popup content */}
                <div className="popupnew-contentnew xl:mx-0 md:mx-0 mx-4">
                  <div className="flex justify-between ">
                    <div>
                      <h2 className="text-lg font-semibold pb-4">
                        Select Director
                      </h2>
                    </div>
                    <div>
                      <button onClick={closeDirectorPopup} className="text-sm">
                        ✖
                      </button>
                    </div>
                  </div>
                  {/* Add your form or content here */}
                  <div className="flex">
                    <div>
                      <p className="md:pt-1 xl:text-base md:text-base text-sm text-light-blue para-font leading-relaxed">
                        Others who will be involved
                      </p>
                    </div>
                    <div
                      className="border cursor-pointer px-4 py-2 xl:text-base md:text-base text-xs rounded font ml-3"
                      onClick={toggleDirectorContent}
                    >
                      Select Person <span className="pl-4">⌵</span>
                    </div>
                  </div>
                  {isDirectorContentVisible && (
                    <div>
                      <div
                        onClick={myselfAsDirector}
                        className="cursor-pointer border px-4 py-2 xl:text-base md:text-base text-xs text-blue font mt-1 rounded-md xl:ml-52 lg:ml-52 md:ml-50 ml-44 mr-2 md:mr-10 xl:mr-10 lg:mr-10"
                      >
                        Myself
                      </div>
                      <div
                        onClick={toggleNewDirectorPopup}
                        className="cursor-pointer border px-4 py-2 xl:text-base md:text-base text-xs text-blue font mt-1 rounded-md xl:ml-52 lg:ml-52 md:ml-50 ml-44 mr-2 md:mr-10 xl:mr-10 lg:mr-10"
                      >
                        + Add New Person
                      </div>
                      {isNewDirectorOpen && (
                        <div className="popupnew2">
                          {/* Popup content */}
                          <div className="popupnew2-contentnew xl:h-420 xl:mx-0 md:mx-0 mx-4">
                            <div>
                              <div className="flex justify-between ">
                                <div>
                                  <h2 className="text-lg font-semibold pb-4">
                                    Select Director
                                  </h2>
                                </div>
                                <div>
                                  <button
                                    onClick={closeNewDirectorPopup}
                                    className="text-sm"
                                  >
                                    ✖
                                  </button>
                                </div>
                              </div>
                              <div className="xl:grid-cols-2 grid xl:gap-2 gap-2 text-grey-light">
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={tempDirector ? tempDirector.first_name : ''}
                                  onChange={(e) => setTempDirector({...tempDirector, first_name: e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={tempDirector ? tempDirector.last_name : ''}
                                  onChange={(e) => setTempDirector({...tempDirector, last_name: e.target.value})}
                                />

                                <input
                                  type="email"
                                  placeholder="Email"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={tempDirector ? tempDirector.email : ''}
                                  onChange={(e) => setTempDirector({...tempDirector, email: e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="Phone"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={tempDirector ? tempDirector.mobile : ''}
                                  onChange={(e) => setTempDirector({...tempDirector, mobile: e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="Street Address 1"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={tempDirector ? tempDirector.street1 : ''}
                                  onChange={(e) => setTempDirector({...tempDirector, street1: e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="Street Address 2"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={tempDirector ? tempDirector.street2 : ''}
                                  onChange={(e) => setTempDirector({...tempDirector, street2: e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="City"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={tempDirector ? tempDirector.city : ''}
                                  onChange={(e) => setTempDirector({...tempDirector, city: e.target.value})}
                                />

                                <select className="border-on-hover font rounded-md text-sm font"
                                value={tempDirector ? tempDirector.country : ''}
                                onChange={updateTempCountry}
                                >
                                  <option value="">Country</option>
                                  {countries.map((country) => (
                                    <option value={country}>{country}</option>
                                  ))}
                                </select>
                                <select className="border-on-hover font rounded-md text-sm font"
                              value={tempDirector ? tempDirector.addressState : ''}
                              onChange={(e) => setTempDirector({...tempDirector, addressState: e.target.value})}
                              >
                                <option value="">Select State</option>
                                {states.map((state) => (
                                  <option value={state}>{state}</option>
                                ))}
                              </select>
                                <input
                                  type="text"
                                  placeholder="Postal Code"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={tempDirector ? tempDirector.postal_code : ''}
                                  onChange={(e) => setTempDirector({...tempDirector, postal_code: e.target.value})}
                                />
                              </div>
                              <div className="flex xl:mt-6 lg:mt-4 mt-4">
                                <div>
                                  <input
                                    type="checkbox"
                                    className="border-on-hover font rounded-md text-sm font"
                                    checked={tempDirector ? tempDirector.canadian_citizen == "yes" : false}
                                    onChange={(e) => setTempDirector({...tempDirector, canadian_citizen: e.target.checked ? "yes" : "no"})}
                                  ></input>
                                </div>
                                <div className="font text-blue text-base pl-3 pt-1">
                                  Canadian Resident
                                </div>
                                <div
                                  className="font cursor-pointer text-blue text-base pl-3 pt-1"
                                  title="A Canadian Resident includes citizens and Permanent Residents who ordinarily reside in Canada."
                                >
                                  🛈
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between ">
                              <div></div>
                              <div>
                                <button
                                  onClick={closeNewDirectorPopup}
                                  className="xl:text-sm font border-blue bg-button-white px-4 py-1 xl:px-8 xl:py-1 md:px-8 md:mt-12 md:py-1 rounded text-blue-new"
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={addDirector}
                                  className="xl:text-sm font buttonWithBlue border-blue px-4 py-1 xl:px-10 ml-2 xl:py-1 md:px-12 md:mt-12 md:py-1 rounded text-white"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* <button onClick={closePopup}>✖</button> */}
                </div>
              </div>
            )}
          </div>
          <p className="font text-sm pb-2">
            The Directors appoint Officers and oversee management of the
            company.
          </p>
          <div className="border w-full rounded-md mt-2 p-1">
            <div className="bg-extra-light-grey p-3 xl:text-sm font  w-full rounded-md bg-">
              <h1>Name</h1>
            </div>
            {corporateDirectors.map((item,index) => (
              <div className="mt-2 py-3 p-3 xl:text-sm font border w-full rounded-md">
                <div
                  className="flex justify-between cursor-pointer"
                  onClick={() => toggleContentPerson(index)}
                >
                  <div>
                    <h1 className="font-semibold xl:text-base lg:text-sm md:text-sm text-sm">
                      {item.first_name} {item.last_name}
                    </h1>
                  </div>
                  <div>⌵</div>
                </div>
                {/* {isContentPersonVisible && ( */}
                {visibleIndex == index && 
                  <div
                    className={`person-content visible`}
                  >
                    <div className="mt-4">
                      <div className="flex cursor-pointer text-blue font-semibold border-b pb-2 mb-4" onClick={() => deleteDirector(index,item.id)}>
                        <div>
                          <img
                            src="https://i.ibb.co/D1Yj0BL/delete-3405244.png"
                            className="w-4 mr-1"
                          />
                        </div>
                        <div className="xl:text-base lg:text-sm md:text-sm text-sm">
                          Remove
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div>
                          <h1 className="font-semibold xl:text-base md:text-sm text-smfont">
                            Contact Information
                          </h1>
                        </div>
                        <div
                          className="font-semibold cursor-pointer text-sm"
                          onClick={() => togglePopupEdit(index)}
                        >
                          🖊️ Edit
                        </div>
                      </div>
                      {editIndex == index && (
                        <div className="popupedit">
                          {/* Popup content */}
                          <div className="popupedit-contentnew xl:mx-0 md:mx-0 mx-4">
                            {/* <h2 className="text-lg font-semibold">Select Director</h2> */}
                            <div>
                              <div className="flex justify-between ">
                                <div>
                                  <h2 className="text-lg font-semibold pb-4">
                                    Edit Profile
                                  </h2>
                                </div>
                                <div>
                                  <button
                                    onClick={closePopupEdit}
                                    className="text-sm"
                                  >
                                    ✖
                                  </button>
                                </div>
                              </div>
                              <div className="xl:grid-cols-2 grid xl:gap-2 gap-2 text-grey-light">
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={editDirector.first_name}
                                  onChange={(e) => setEditDirector({...editDirector, first_name:e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={editDirector.last_name}
                                  onChange={(e) => setEditDirector({...editDirector, last_name:e.target.value})}
                                />

                                <input
                                  type="email"
                                  placeholder="Email"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={editDirector.email}
                                  onChange={(e) => setEditDirector({...editDirector, email: e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="Phone"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={editDirector.mobile}
                                  onChange={(e) => setEditDirector({...editDirector, mobile: e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="Street Address 1"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={editDirector.street1}
                                  onChange={(e) => setEditDirector({...editDirector, street1: e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="Street Address 2"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={editDirector.street2}
                                  onChange={(e) => setEditDirector({...editDirector, street2: e.target.value})}
                                />

                                <input
                                  type="text"
                                  placeholder="City"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={editDirector.city}
                                  onChange={(e) => setEditDirector({...editDirector, city: e.target.value})}
                                />

                                <select className="border-on-hover font rounded-md text-sm font" value={editDirector.country} onChange={updateEditCountry}>
                                  <option value="">Country</option>
                                  {countries.map((country) => (
                                    <option value={country}>{country}</option>
                                  ))}
                                </select>

                                <select className="border-on-hover font rounded-md text-sm font" value={editDirector.addressState} onChange={(e) => setEditDirector({...editDirector, addressState:e.target.value})}>
                                  <option value="">Select Location</option>
                                  {states.map((addressState) => (
                                    <option value={addressState}>{addressState}</option>
                                  ))}
                                </select>

                                

                                <input
                                  type="text"
                                  placeholder="Postal Code"
                                  className="border-on-hover font rounded-md text-sm font"
                                  value={editDirector.postal_code}
                                  onChange={(e) => setEditDirector({...editDirector, postal_code: e.target.value})}
                                />
                              </div>
                              <div className="flex xl:mt-6 mt-4">
                                <div>
                                  <input
                                    type="checkbox"
                                    className="border-on-hover font rounded-md text-sm font"
                                    checked={editDirector.canadian_citizen === "yes" ? true : false}
                                    onChange={(e) => setEditDirector({...editDirector, canadian_citizen: e.target.checked ? "yes" : "no"})}
                                  />
                                </div>
                                <div className="font text-blue text-base pl-3 pt-1">
                                  Canadian Resident
                                </div>
                                <div
                                  className="font text-blue text-base pl-3 pt-1"
                                  title="A Canadian Resident includes citizens and Permanent Residents who ordinarily reside in Canada."
                                >
                                  🛈
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between mt-8">
                              <div></div>
                              <div>
                                <button
                                  onClick={closePopupEdit}
                                  className="xl:text-sm font border-blue bg-button-white px-4 py-1 xl:px-8 xl:py-1 md:px-8 md:py-1 rounded text-blue-new"
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() => saveChanges(index)}
                                  className="xl:text-sm font buttonWithBlue border-blue px-7 py-1 xl:px-10 ml-2 xl:py-1 lg:px-12 md:px-12 md:py-1 rounded text-white"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Name */}
                      <div>
                        <h1 className="font-semibold pt-4 text-sm font">
                          {item.first_name} {item.last_name}
                        </h1>
                        <p className="font text-sm text-blue pt-1">
                          Director
                        </p>
                      </div>

                      {/* Email phone address */}
                      <div className="xl:flex lg:flex md:flex flex-none xl:w-12/12">
                        <div className="xl:w-6/12 lg:w-6/12 md:w-6/12 w-full">
                          <h1 className="font-semibold pt-4 text-sm font">Email</h1>
                          <p className="font text-sm text-blue pt-1">
                            {item.email}
                          </p>
                        </div>
                        <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full">
                          <h1 className="font-semibold pt-4 text-sm font">Phone</h1>
                          <p className="font text-sm text-blue pt-1">{item.mobile}</p>
                        </div>
                        <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full">
                          <h1 className="font-semibold pt-4 text-sm font">
                            Address
                          </h1>
                          <p className="font text-sm text-blue pt-1">
                            {item.street1} , {item.street2}, {item.city} & {item.addressState},{item.postal_code}.
                          </p>
                        </div>
                      </div>

                      {/* Residency */}
                      <div>
                        <h1 className="font-semibold text-sm font xl:pt-0 md:pt-0 pt-4">
                          Residency
                        </h1>
                        <p className="font text-sm text-blue pt-1">
                          {item.canadian_citizen == "yes" ? 'Canadian ' : 'Non Canadian '} Resident
                        </p>
                      </div>
                    </div>
                  </div>
                }
                {/* )} */}
              </div>
            ))}
          </div>
        </div>

        {/* officers */}

        <div className="xl:pt-16 lg:pt-10 md:pt-10 pt-8">
          <div className="flex justify-between ">
            <div>
              <h2 className="font text-blue xl:text-lg lg:text-base font-semibold md:text-base text-base pb-3">
                Officers
              </h2>
            </div>
          </div>
          <p className="font text-sm pb-2">
            President is mandatory. However, companies can create other
            positions such as CEO, Treasurer, etc. These positions can all be
            held by the same person.
          </p>
          {corporateDirectors.map((item,index) => (
            <div className="p-2 flex rounded-t-md">
              <div>
                <input
                  type="text"
                  placeholder="Name"
                  className="xl:h-10 lg:h-10 md:h-10 md:text-sm text-xs border-on-hover text-sm rounded font border-grey-50 xl:w-75 lg:w-62 md:w-36 mr-2 w-32"
                  readOnly
                  value={item.first_name + ' ' + item.last_name}
                />
              </div>
              <div className="font-medium text-base">
                <select
                  placeholder="Position"
                  value={item.role}
                  onChange={(e) =>
                    setCorporateDirectors((prevOwners) => {
                      const updatedOwners = [...prevOwners];
                      updatedOwners[index] = {
                        ...item,
                        role: e.target.value,
                      };
                      return updatedOwners;
                    })
                  }
                  className="xl:h-10 lg:h-10 md:h-10 text-sm border-on-hover font rounded border-grey-50 pl-2 xl:ml-0 ml-1 md:ml-0 xl:w-36 mr-1 lg:w-28 md:w-24 w-20"
                >
                  <option value='CEO'>CEO</option>
                  <option value='President'>President</option>
                  <option value='Vice President'>Vice President</option>
                  <option value='Treasurer'>Treasurer</option>
                  <option value='Secretary'>Secretary</option>
                  <option value='Director'>Director</option>
                </select>
              </div>
            </div>
          ))}
        </div>
        </>
        }

        {/* Previous and Next buttons */}
        <div className="flex">
          <button
            onClick={handlePrev}
            className="buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="ml-2 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

function Step4({
  formData,
  updateFormData,
  handlePrev,
  handleNext,
  userData,
  setUserData,
  corporateDetails,
  setCorporateDetails,
  corporateOwners,
  setCorporateOwners,
  billingAddress,
  setBillingAddress,
  setCurrentStep,
  handleSubmit,
  clearData,
  isChecked,
  setIsChecked,
  corporateOwnerType,
  setCorporateOwnerType,
  corporateOthersType,
  setCorporateOthersType,
  corporateDirectors,
  setCorporateDirectors,
  countries,
  selectedCountry,
  setSelectedCountry,
  states,
}) {
  const addRow = () => {
    const totalOwnership = corporateOwners.reduce(
      (acc, row) => acc + parseInt(row.ownership),
      0
    );

    // Check if any name is empty or contains only spaces
    const hasInvalidName = corporateOwners.some(
      (item) => item.name.trim() === ""
    );
    if (hasInvalidName) {
      toast.error("Please enter valid name");
      return;
    }

    // Check if any name is a number
    const hasNumericName = corporateOwners.some((item) => !isNaN(item.name));
    if (hasNumericName) {
      toast.error("Please enter valid name");
      return;
    }

    if (totalOwnership < 100) {
      setCorporateOwners((prevRows) => [
        ...prevRows,
        {
          name: "",
          share_type: "voting",
          ownership: "50",
        },
      ]);
    } else {
      toast.error("Cannot add new row since ownership limit crossed");
    }
  };

  const deleteRow = (indexToDelete) => {
    setCorporateOwners((prevOwners) => {
      // Filter out the row with the specified indexToDelete
      const updatedOwners = prevOwners.filter(
        (_, index) => index !== indexToDelete
      );
      return updatedOwners;
    });
  };

  const [isOpenPresident, setIsOpenPresident] = useState(false);

  const togglePopupPresident = () => {
    setIsOpenPresident(!isOpenPresident);
  };

  const closePopupPresident = () => {
    setIsOpenPresident(false);
  };

  const [isContentVisible, setContentVisible] = useState(false);

  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  //popup second

  const [isOpen2, setIsOpen2] = useState(false);

  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };

  const closePopup2 = () => {
    setIsOpen2(false);
  };

  const addOwnership = (directorIndex) => {
    const totalOwnership = corporateOwners.reduce(
      (acc, row) => acc + parseInt(row.ownership),
      0
    );

    // Check if any name is empty or contains only spaces
    const hasInvalidName = corporateOwners.some(
      (item) => item.name.trim() === ""
    );
    if (hasInvalidName) {
      toast.error("Please enter valid name");
      return;
    }

    // Check if any name is a number
    const hasNumericName = corporateOwners.some((item) => !isNaN(item.name));
    if (hasNumericName) {
      toast.error("Please enter valid name");
      return;
    }

    if (totalOwnership < 100) {
      setCorporateOwners((prevRows) => [
        ...prevRows,
        {
          name: corporateDirectors[directorIndex].first_name + " " + corporateDirectors[directorIndex].last_name,
          share_type: "voting",
          ownership: "50",
        },
      ]);
      togglePopupPresident();
    } else {
      toast.error("Cannot add new row since ownership limit crossed");
    }
  }

  //popup second

  useEffect(() => {
    if (corporateOwners.length === 0 && corporateOwnerType === "myself") {
      setCorporateOwners([{ name: userData.name + " " + userData.last_name, share_type: 'voting', ownership: 100 }]);
    }
  }, [corporateOwners.length, corporateOwnerType, userData.name, userData.last_name]);

  return (
    <section>
      <IndexNavbar />
      <div className="xl:px-5 xl:p-8 p-4 md:p-4 lg:p-8 xl:text-lg bg-white xl:pb-44">
        <h2 className="font text-cyan-500 font-medium text-base pb-3 border-b">
          Corporate Ownership
        </h2>

        <div className="xl:flex lg:flex md:flex flex-none justify-between mt-4">
          <div>
            <p className="font text-blue xl:text-base lg:text-base font-semibold md:text-sm text-sm pb-3">
              Who will manage and own this company
            </p>
          </div>
          {corporateOwnerType != "myself" && 
            <div className="text-blue xl:text-base font xl:pb-0 md:pb-0 pb-4">
              <button
                onClick={togglePopupPresident}
                className="font-semibold text-sm"
              >
                + Add Shareholder
              </button>
            </div>
          }
          {isOpenPresident && (
            <div className="popupnewposition">
              {/* Popup content */}
              <div className="popupnewposition-contentnew">
                <div className="flex justify-between ">
                  <div>
                    <h2 className="text-lg font-semibold pb-4">
                      Add Shareholder
                    </h2>
                  </div>
                  <div>
                    <button onClick={closePopupPresident} className="text-sm">
                      ✖
                    </button>
                  </div>
                </div>
                {/* Add your form or content here */}
                <div className="flex ">
                  <div>
                    <p className="md:pt-1 text-base text-light-blue para-font leading-relaxed">
                      The shareholder is
                    </p>
                  </div>
                  <div
                    className="border cursor-pointer rounded-md px-4 py-2 xl:text-base font ml-3"
                    onClick={toggleContent}
                  >
                    Select Person <span className="pl-4">⌵</span>{" "}
                  </div>
                </div>
                {isContentVisible && (
                  <div>
                    <div className="border rounded-md xl:ml-42 lg:ml-16 mt-1 pb-1 lg:mr-22 xl:w-44">
                      {corporateDirectors.map((item,index) => (
                        <div className="bg-light-grey mr-2 cursor-pointer border px-4 py-2 xl:text-sm lg:text-sm md:text-sm text-blue font mt-1 rounded-md ml-2 xl:w-40" onClick={() => addOwnership(index)}>
                          {item.first_name} {item.last_name}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {/* <button onClick={closePopup}>✖</button> */}
              </div>
            </div>
          )}
        </div>
        <div className="border rounded-md p-2 xl:mt-2 overflow-x-auto">
          <div className="">
            <div className="xl:p-3 lg:p-3 md:p-1 p-2 flex bg-extra-light-grey xl:width-100 lg:width-100 md:width-100 width-150 rounded-t-md">
              <div className="text-dark-blue font-medium xl:text-base md:text-sm text-sm xl:w-92 lg:w-60 md:w-36 mr-2 w-32">
                Name
              </div>
              <div className="text-dark-blue font-medium xl:text-base md:text-sm text-sm border-l pl-2 mr-2 xl:w-44 lg:w-0 md:w-30 w-28">
                Type of Shares
              </div>
              <div className="text-dark-blue font-medium xl:text-base md:text-sm text-sm border-l pl-2 ml-2 xl:ml-0 xl:w-44 lg:w-0 md:w-20 w-20">
                Ownership
              </div>
              <div className="text-dark-blue font-medium xl:text-base md:text-sm text-sm border-l pl-2 ml-2 xl:ml-0 xl:w-44 lg:w-0 md:w-20 w-20">
                Delete
              </div>
            </div>
          </div>

          {/* Rendering dynamic rows */}
          <div className="border-grey-50 xl:width-100 lg:width-100 md:width-100 width-150 rounded mt-2">
            {corporateOwners.map((row, index) => (
              <div key={index} className=" mt-0">
                <div className="p-2 flex rounded-t-md">
                  <div>
                    <input
                      type="text"
                      placeholder="Name"
                      className="xl:h-10 lg:h-10 md:h-10 md:text-sm text-xs border-on-hover text-sm rounded font border-grey-50 xl:w-75 lg:w-62 md:w-36 mr-2 w-32"
                      value={row.name}
                    />
                  </div>
                  <div className="font-medium xl:text-lg text-sm">
                    <select
                      name="shareType"
                      className="xl:h-10 lg:h-10 md:h-10 rounded border-on-hover cursor-pointer font border-grey-50 text-sm pl-2 mr-1 xl:w-36 lg:w-28 md:w-32 w-32"
                      value={row.share_type}
                      onChange={(e) =>
                        setCorporateOwners((prevOwners) => {
                          const updatedOwners = [...prevOwners];
                          updatedOwners[index] = {
                            ...row,
                            share_type: e.target.value,
                          };
                          return updatedOwners;
                        })
                      }
                    >
                      <option value="voting" className="text-xs font">
                        Voting
                      </option>
                      <option value="non-voting" className="text-xs font">
                        Non-voting
                      </option>
                    </select>
                  </div>
                  <div className="font-medium text-base">
                    <input
                      type="number"
                      placeholder="Ownership"
                      value={row.ownership}
                      onChange={(e) =>
                        setCorporateOwners((prevOwners) => {
                          const updatedOwners = [...prevOwners];
                          updatedOwners[index] = {
                            ...row,
                            ownership: e.target.value,
                          };
                          return updatedOwners;
                        })
                      }
                      className="xl:h-10 lg:h-10 md:h-10 text-sm border-on-hover font rounded border-grey-50 pl-2 xl:ml-0 ml-1 md:ml-0 xl:w-36 mr-1 lg:w-28 md:w-24 w-20"
                    />
                  </div>
                  <div className="font-medium text-base">
                    {corporateOwnerType != "myself" && 
                    <button
                      type="button"
                      className="pl-2 ml-2 xl:ml-0 xl:mt-1 xl:w-16 lg:w-24 lg:mt-2 md:mt-2 md:w-16 w-20"
                      onClick={() => deleteRow(index)}
                    >
                      <i className="fa fa-trash" style={{ color: "red" }}></i>
                    </button>
                    }
                  </div>
                </div>
              </div>
            ))}

            {/* Add button */}
            {/* <div className="flex justify-between">
              <div></div>
              <div>
                <button
                  onClick={addRow}
                  className="ml-4 mt-4 mr-2 mb-6 buttonWithBlue  text-sm border-blue font-semibold px-4 py-1 xl:px-5 xl:py-1 md:px-4 md:py-1 border rounded-lg text-white"
                >
                  Add
                </button>
              </div>
            </div> */}
          </div>
        </div>

        {/* Previous and Next buttons */}
        <div className="flex">
          <button
            onClick={handlePrev}
            className="buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="ml-2 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

function Step5({
  formData,
  updateFormData,
  handlePrev,
  handleNext,
  userData,
  setUserData,
  corporateDetails,
  setCorporateDetails,
  corporateOwners,
  setCorporateOwners,
  billingAddress,
  setBillingAddress,
  setCurrentStep,
  handleSubmit,
  clearData,
  isChecked,
  setIsChecked,
  corporateOwnerType,
  setCorporateOwnerType,
  corporateOthersType,
  setCorporateOthersType,
  corporateDirectors,
  setCorporateDirectors,
  countries,
  selectedCountry,
  setSelectedCountry,
  states,
}) {
  //popup start billing address

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  //popup end billing address

  const [isContentVisible, setContentVisible] = useState(false);

  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };

  const closePopup2 = () => {
    setIsOpen2(false);
  };

  // onclick open bottom text second one

  const [isContentPersonVisible, setContentPersonVisible] = useState(false);

  //popup for president start

  const [isOpenPresident, setIsOpenPresident] = useState(false);

  const togglePopupPresident = () => {
    setIsOpenPresident(!isOpenPresident);
  };

  const closePopupPresident = () => {
    setIsOpenPresident(false);
  };

  //popup for president end

  const [visibleIndex, setVisibleIndex] = useState(null);
  const [isOpenInformation, setIsOpenInformation] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editDirector, setEditDirector] = useState({});

  const toggleContentPerson = (index) => {
    setVisibleIndex(visibleIndex === index ? null : index);
    setEditIndex(index);
    setEditDirector(corporateDirectors[index]);
  };

  const togglePopupInformation = (index) => {
    setIsOpenInformation(isOpenInformation === index ? null : index);
  };

  const closePopupInformation = () => {
    setIsOpenInformation(null);
    setEditIndex(null);
  };

  const saveChanges = (index) => {
    const updatedDirectors = corporateDirectors.map((director, i) =>
      i === index ? editDirector : director
    );
    setCorporateDirectors(updatedDirectors);
    closePopupInformation();
  }

  return (
    <section>
      <IndexNavbar />
      <div>
        <div className="xl:px-5 xl:p-8 p-4 md:p-4 lg:p-8 xl:text-lg bg-white xl:pb-36">
          {/* <h2 className="font border-b text-cyan-500 xl:text-base lg:text-base font-medium md:text-base text-xl pb-3">
            Individuals with Significant Control
          </h2> */}
          <div className="">
            <div className="flex justify-between border-b mb-4">
              <div>
                <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base text-sm pb-3">
                  Individuals with Significant
                </h2>
              </div>
            </div>
            <p className="font text-sm pb-2">
              An individual with significant control is someone who owns,
              controls, or directs a significant number of shares, has
              significant influence over the corporation without owning any
              shares; or has a combination of any of these factors. Companies
              are required to identify individuals with significant control or
              declare that they have none.
            </p>
            <div className="border w-full rounded-md mt-2 p-1">
              <div className="bg-extra-light-grey p-3 xl:text-sm font  w-full rounded-md bg-">
                <h1>Name</h1>
              </div>

              {corporateOwnerType != "myself" && 
                <div className="mt-2 py-3 p-3 xl:text-sm font border w-full rounded-md">
                  {corporateDirectors.map((item, index) => (
                    <div
                      className="xl:flex lg:flex md:flex-none flex-none justify-between cursor-pointer"
                      onClick={() => toggleContentPerson(index)}
                    >
                      <div className="xl:w-3/12 lg:w-3/12 md:w-12/12">
                        <h1 className="font-semibold xl:text-base lg:text-sm md:text-sm text-sm">
                          {item.first_name} {item.last_name}
                        </h1>
                      </div>
                      <div className="xl:w-6/12 lg:w-5/12 md:w-12/12 text-sm xl:pt-0 lg:pt-0 md:pt-2 pt-2">
                        This individual was automatically added because they control
                        25%+ voting shares. We require additional information in
                        order to continue.
                      </div>
                      <button
                        onClick={togglePopupInformation}
                        className="xl:ml-4 lg:ml-4 md:ml-0 xl:mt-0 lg:mt-0 md:mt-3 xl:h-12 lg:h-12 md:h-8 h-10 w-12/12 mt-3  xl:w-3/12 lg:w-4/12 md:w-6/12 buttonWithBlue text-sm border-blue font-semibold"
                      >
                        Add Missing Deails
                      </button>
                    </div>
                  ))}

                  {isOpenInformation && (
                    <div className="popupnewPresident">
                      {/* Popup content */}
                      <div className="popupnewPresident-contentnew xl:mx-0 md:mx-0 mx-4">
                        <div>
                          <div className="flex justify-between ">
                            <div>
                              <h2 className="text-lg font-semibold pb-4">
                                Review Profile
                              </h2>
                              <h1 className="font text-sm text-blue">
                                <b>
                                  Name and address information submitted below may
                                  be made public on Corporations Canada's website.
                                </b>{" "}
                                Please review the information below and provide an
                                alternate address if you do not want the
                                individual's residential address to be made
                                public.
                              </h1>
                            </div>
                            <div>
                              <button
                                onClick={closePopupInformation}
                                className="text-sm"
                              >
                                ✖
                              </button>
                            </div>
                          </div>
                          <div className="xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid gap-2 mt-4 text-grey-light">

                            <input
                              type="text"
                              placeholder="First Name"
                              className="border-on-hover font rounded-md text-sm font"
                              value={editDirector.first_name}
                              onChange={(e) => setEditDirector({ ...editDirector, first_name: e.target.value })}
                            />

                            <input
                              type="text"
                              placeholder="Last Name"
                              className="border-on-hover font rounded-md text-sm font"
                              value={editDirector.last_name}
                              onChange={(e) => setEditDirector({ ...editDirector, last_name: e.target.value })}
                            />

                            <input
                              type="email"
                              placeholder="Email"
                              className="border-on-hover font rounded-md text-sm font"
                              value={editDirector.email}
                              onChange={(e) => setEditDirector({ ...editDirector, email: e.target.value })}
                            />

                            <input
                              type="text"
                              placeholder="Phone"
                              className="border-on-hover font rounded-md text-sm font"
                              value={editDirector.mobile}
                              onChange={(e) => setEditDirector({ ...editDirector, mobile: e.target.value })}
                            />

                            <p className="font text-blue text-sm mt-4 mb-2 font-semibold">
                              Residential Address
                            </p>
                            <p></p>

                            <input
                              type="text"
                              placeholder="Street Address 1"
                              className="border-on-hover font rounded-md text-sm font"
                              value={editDirector.street1}
                              onChange={(e) => setEditDirector({ ...editDirector, street1: e.target.value })}
                            />

                            <input
                              type="text"
                              placeholder="Street Address 2"
                              className="border-on-hover font rounded-md text-sm font"
                              value={editDirector.street2}
                              onChange={(e) => setEditDirector({ ...editDirector, street2: e.target.value })}
                            />

                            <input
                              type="text"
                              placeholder="City"
                              className="border-on-hover font rounded-md text-sm font"
                              value={editDirector.city}
                              onChange={(e) => setEditDirector({ ...editDirector, city: e.target.value })}
                            />

                            <select className="border-on-hover font rounded-md text-sm font"
                            value={editDirector.addressState}
                            onChange={(e) => setEditDirector({ ...editDirector, addressState: e.target.value })}
                            >
                              <option value="">Select Location</option>
                              <option value="ALBERTA">ALBERTA</option>
                              <option value="BRITISH COLUMBIA">
                                BRITISH COLUMBIA
                              </option>
                              <option value="MANITOBA">MANITOBA</option>
                              <option value="NEW BRUNSWICK">NEW BRUNSWICK</option>
                              <option value="NEWFOUNDLAND and LABRADOR">
                                NEWFOUNDLAND and LABRADOR
                              </option>
                              <option value="NORTHWEST TERRITORIES">
                                NORTHWEST TERRITORIES
                              </option>
                              <option value="NOVA SCOTIA">NOVA SCOTIA</option>
                              <option value="NUNAVUT">NUNAVUT</option>
                              <option value="ONTARIO">ONTARIO</option>
                              <option value="PRINCE EDWARD ISLAND">
                                PRINCE EDWARD ISLAND
                              </option>
                              <option value="QUEBEC">QUEBEC</option>
                              <option value="SASKATCHEWAN">SASKATCHEWAN</option>
                              <option value="YUKON">YUKON</option>
                            </select>

                            <select className="border-on-hover font rounded-md text-sm font"
                            value={editDirector.country}
                            onChange={(e) => setEditDirector({ ...editDirector, country: e.target.value })}
                            >
                              <option value="">Country</option>
                              <option value="Canada">Canada</option>
                            </select>

                            <input
                              type="text"
                              placeholder="Postal Code"
                              className="border-on-hover font rounded-md text-sm font"
                              value={editDirector.postal_code}
                              onChange={(e) => setEditDirector({ ...editDirector, postal_code: e.target.value })}
                            />
                          </div>
                          <div className="flex xl:mt-6 mt-4">
                            <div className="pt-1">
                              <input
                                type="checkbox"
                                className="border-on-hover font rounded-md text-sm font"
                                checked={true}
                              />
                            </div>
                            <div className="font text-blue text-base pl-3 pt-1">
                              I would like a different address to be made public
                              by the government instead of my residential address
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between mt-4">
                          <div></div>
                          <div>
                            <button
                              onClick={closePopupInformation}
                              className="xl:text-sm font border-blue bg-button-white px-4 py-1 xl:px-8 xl:py-1 md:px-8 md:py-1 rounded text-blue-new"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => saveChanges(editIndex)}
                              className="xl:text-sm font buttonWithBlue border-blue px-8 py-1 xl:px-10 ml-2 xl:py-1 md:px-8 md:py-1 rounded text-white"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              }
            </div>
          </div>

          {/* <div className=" pb-4 flex xl:w-12/12 lg:w-12/12">
          </div> */}
          <button
            onClick={handlePrev}
            className="mb-6 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="ml-2 mb-6 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

function Step6({
  formData,
  updateFormData,
  handlePrev,
  handleNext,
  userData,
  setUserData,
  corporateDetails,
  setCorporateDetails,
  corporateOwners,
  setCorporateOwners,
  billingAddress,
  setBillingAddress,
  setCurrentStep,
  handleSubmit,
  clearData,
  isChecked,
  setIsChecked,
  corporateOwnerType,
  setCorporateOwnerType,
  corporateOthersType,
  setCorporateOthersType,
  corporateDirectors,
  setCorporateDirectors,
  countries,
  selectedCountry,
  setSelectedCountry,
  states,
}) {
  return (
    <section>
      <IndexNavbar />
      <div>
        <div className="xl:px-5 xl:p-8 p-4 md:p-4 lg:p-8 xl:text-lg bg-white xl:pb-60 xl:pb-60 md:pb-56">
          <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base text-xl pb-3">
            Choose Your Plan
          </h2>
          <div className="border-t pb-4 xl:flex lg:flex md:flex sm:flex flex-none xl:w-12/12 md:w-12/12">
            <div className="xl:w-3/12 md:w-4/12">
              <div className="xl:w-8/12 md:w-10/12 w-5/12">
                <div className="borderborderr p-1 mt-3 bg-lightbluee-200 font-medium rounded-md  uppercase text-xss">
                  ☑ registration fees
                </div>
              </div>
              <p className="font xl:text-base md:text-xs my-2 font-semibold">
                Complete Incorporation
              </p>
              <p className="font xl:text-sm md:text-xs">
                One time fees to Incorporate your business
              </p>
            </div>
            <div className="xl:w-9/12 md:w-8/12">
              <div className="border-grey-50 flex justify-between rounded-md mt-3 w-12/12">
                <div className="flex">
                  <div className="">
                    <img
                      src="https://i.ibb.co/997Bv2h/Screenshot-479.png"
                      className="w-20 rounded-l-md"
                    />
                  </div>
                  <div className="p-2">
                    <p className="font font-semibold text-sm">
                      Government Registration
                    </p>
                    <p className="font text-sm text-light-blue">
                      $200 - One Time Fee
                    </p>
                  </div>
                </div>
                <div className="mt-3 mr-4">
                  <p>✅</p>
                </div>
              </div>
              <div className="border-grey-50 flex justify-between rounded-md mt-1 xl:w-12/12">
                <div className="flex">
                  <div className="">
                    <img
                      src="https://i.ibb.co/pdFPqWP/legalformation.jpg"
                      className="w-20 rounded-l-md"
                    />
                  </div>
                  <div className="p-2">
                    <p className="font font-semibold text-sm">
                      Legal Formation
                    </p>
                    <p className="font text-sm text-light-blue">
                      $299 - One Time Fee
                    </p>
                  </div>
                </div>
                <div className="mt-3 mr-4">
                  <p>✅</p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className=" pb-4 flex xl:w-12/12 lg:w-12/12">
          </div> */}
          <button
            onClick={handlePrev}
            className="mb-6 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="ml-2 mb-6 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

function Step7({
  formData,
  updateFormData,
  handlePrev,
  handleNext,
  userData,
  setUserData,
  corporateDetails,
  setCorporateDetails,
  corporateOwners,
  setCorporateOwners,
  billingAddress,
  setBillingAddress,
  setCurrentStep,
  handleSubmit,
  clearData,
  isChecked,
  setIsChecked,
  corporateOwnerType,
  setCorporateOwnerType,
  corporateOthersType,
  setCorporateOthersType,
  corporateDirectors,
  setCorporateDirectors,
  countries,
  selectedCountry,
  setSelectedCountry,
  states,
}) {

  const updateBillingCountry = (e) => {
    setSelectedCountry(e.target.value);
    setBillingAddress({...billingAddress, country: e.target.value})
  }
  
  return (
    <section>
      <IndexNavbar />
      <div>
        <div className="xl:px-5 xl:p-8 p-4 md:p-4 lg:p-8 xl:text-lg bg-white">
          <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base text-xl pb-3">
            Billing Information
          </h2>
          <div className="border-t pb-4 rounded-b-md">
            <div className="flex mt-4">
              <div className="w-full xl:w-11/12  ">
                <div className="border-blue border-gray-300 w-80 h-auto relative shadow-lg rounded-md bottom-20">
                  <div className="relative bg-light-grey-new rounded-b-md">
                    <h4 className="font bg-bluee-700 flex text-left text-white xl:text-lg font-medium font p-4 rounded-t-md">
                      Order #: IBC-OR3059
                    </h4>
                    <div className="w-full mt-4 p-3 px-4 flex flex-wrap lg:w-12/12">
                      <div className="font block text-dark-blue font-regular text-sm mb-2 lg:w-6/12 w-6/12">
                        Yearly iBC Subscription Fee
                      </div>
                      <div className="font block text-dark-blue font-bold pl-4 text-sm mb-2 lg:w-1/12">
                        :
                      </div>
                      <div className="font block text-dark-blue font-regular pl-4 text-base mb-2 lg:w-4/12">
                        $499
                      </div>
                    </div>

                    <div className="w-full pt-1 px-4 flex flex-wrap lg:w-12/12">
                      <div className="font block  text-dark-blue font-regular text-sm mb-2 lg:w-6/12 w-6/12">
                        Government Registration One year Fee
                      </div>
                      <div className="font block  text-dark-blue font-bold pl-4 text-base mb-2 lg:w-1/12">
                        :
                      </div>
                      <div className="font block  text-dark-blue font-regular pl-4 text-base mb-2 lg:w-4/12">
                        $200
                      </div>
                    </div>

                    <div className="w-full pt-1 px-4 flex flex-wrap lg:w-12/12">
                      <div className="font block  text-dark-blue font-regular text-sm mb-2 lg:w-6/12 w-6/12">
                        Legal Formation One year Fee
                      </div>
                      <div className="font block  text-dark-blue font-bold pl-4 text-base mb-2 lg:w-1/12">
                        :
                      </div>
                      <div className="font block  text-dark-blue font-regular pl-4 text-base mb-2 lg:w-4/12">
                        $299
                      </div>
                    </div>

                    <div className="w-full pt-1 px-4 flex flex-wrap lg:w-12/12">
                      <div className="font block  text-dark-blue font-regular text-sm mb-2 lg:w-6/12 w-6/12">
                        Subtotal
                      </div>
                      <div className="font block  text-dark-blue font-bold pl-4 text-base mb-2 lg:w-1/12">
                        :
                      </div>
                      <div className="font block  text-dark-blue font-regular pl-4 text-base mb-2 lg:w-4/12">
                        $499
                      </div>
                    </div>

                    <div className="w-full pt-1  px-4 flex flex-wrap lg:w-12/12">
                      <div className="font block  text-dark-blue font-regular text-sm mb-2 lg:w-6/12 w-6/12">
                        VAT
                      </div>
                      <div className="font block  text-dark-blue font-bold pl-4 text-base mb-2 lg:w-1/12">
                        :
                      </div>
                      <div className="font block  text-dark-blue font-regular pl-4 text-base mb-2 lg:w-4/12">
                        0
                      </div>
                    </div>

                    <div className="w-full pt-1 px-4 pb-6 flex flex-wrap lg:w-12/12 rounded-b-md">
                      <div className="font block  text-dark-blue font-regular text-sm mb-2 lg:w-6/12 w-6/12">
                        Total
                      </div>
                      <div className="font block  text-dark-blue font-bold pl-4 text-base mb-2 lg:w-1/12">
                        :
                      </div>
                      <div className="font block  text-dark-blue font-regular pl-4 text-base mb-2 lg:w-4/12">
                        $499
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4 pb-4 xl:text-lg">
            <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base text-xl pb-3">
              Billing To
            </h2>
            <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-2 md:gap-2 xl:gap-2 text-lg border-t">
              <div className="font xl:text-lg text-sm pt-4">
                <p className="text-dark-blue text-sm font-medium font">
                  First Name
                </p>
                <input
                  type="text"
                  placeholder=""
                  className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm w-full mt-2"
                  value={
                    billingAddress ? billingAddress.first_name : userData.name
                  }
                  onChange={(e) =>
                    setBillingAddress({
                      ...billingAddress,
                      first_name: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="font xl:text-lg text-sm pt-4">
                <p className="text-dark-blue text-sm font-medium font">
                  Last Name
                </p>
                <input
                  type="text"
                  placeholder=""
                  className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm w-full mt-2"
                  value={
                    billingAddress
                      ? billingAddress.last_name
                      : userData.last_name
                  }
                  onChange={(e) =>
                    setBillingAddress({
                      ...billingAddress,
                      last_name: e.target.value,
                    })
                  }
                ></input>
              </div>
            </div>
            <div className=" pt-6">
              <h4 className="text-cyan-500 xl:text-base font-medium font">
                Billing & Mailing Address
              </h4>
              <div className="flex pt-4">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked((prevState) => !prevState)}
                  className="rounded-full"
                />
                <p className="font font-medium text-dark-blue text-sm ml-3">
                  Same as Profile
                </p>
              </div>
            </div>

            <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-2 md:gap-2 xl:gap-2 text-lg border-t mt-6">
              <div className="font xl:text-lg text-sm pt-4">
                <p className="text-dark-blue text-sm font-medium font">
                  Flat/Building.No
                </p>
                <input
                  type="text"
                  placeholder=""
                  className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm w-full mt-2"
                  value={billingAddress ? billingAddress.flat : ""}
                  onChange={(e) =>
                    setBillingAddress({
                      ...billingAddress,
                      flat: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="font xl:text-lg text-sm pt-4">
                <p className="text-dark-blue text-sm font-medium font">
                  Area, Street, Sector, Village
                </p>
                <input
                  type="text"
                  placeholder=""
                  className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm w-full mt-2"
                  value={billingAddress ? billingAddress.street : ""}
                  onChange={(e) =>
                    setBillingAddress({
                      ...billingAddress,
                      street: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="font xl:text-lg text-sm pt-4">
                <p className="text-dark-blue text-sm font-medium font">
                  Town/City
                </p>
                <input
                  type="text"
                  placeholder=""
                  className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm w-full mt-2"
                  value={billingAddress ? billingAddress.city : ""}
                  onChange={(e) =>
                    setBillingAddress({
                      ...billingAddress,
                      city: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="font xl:text-lg text-sm pt-4">
                <p className="text-dark-blue text-sm font-medium font">
                  Country
                </p>
                <select
                  className="border-grey-50 mt-2 border-on-hover border-1 formheading px-3 py-2  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  key="5"
                  value={billingAddress ? billingAddress.country : ""}
                  onChange={updateBillingCountry}
                >
                  <option>Please select one…</option>
                  {countries.map((country) => (
                    <option value={country}>{country}</option>
                  ))}
                </select>{" "}
              </div>
              <div className="font xl:text-lg text-sm pt-4">
                <p className="text-dark-blue text-sm font-medium font">State</p>
                <select
                  className="border-grey-50 border-on-hover border-1 mt-2 formheading px-3 py-2  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  key="5"
                  value={billingAddress ? billingAddress.address_state : ""}
                  onChange={(e) =>
                    setBillingAddress({
                      ...billingAddress,
                      address_state: e.target.value,
                    })
                  }
                >
                  <option>Please select one…</option>
                  {states.map((addressState) => (
                    <option value={addressState}>{addressState}</option>
                  ))}
                </select>{" "}
              </div>
              <div className="font xl:text-lg text-sm pt-4">
                <p className="text-dark-blue text-sm font-medium font">
                  Postal Code
                </p>
                <input
                  type="text"
                  placeholder=""
                  className="font border-grey-50 border-on-hover rounded-md text-sm xl:text-sm w-full mt-2"
                  value={billingAddress ? billingAddress.postal_code : ""}
                  onChange={(e) =>
                    setBillingAddress({
                      ...billingAddress,
                      postal_code: e.target.value,
                    })
                  }
                ></input>
              </div>
            </div>
          </div>
          <button
            onClick={handlePrev}
            className="mb-6 buttonWithBlue text-sm border-blue font-medium px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="ml-2 mb-6 buttonWithBlue text-sm border-blue font-medium px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

function Step8({
  formData,
  updateFormData,
  handlePrev,
  handleNext,
  userData,
  setUserData,
  corporateDetails,
  setCorporateDetails,
  corporateOwners,
  setCorporateOwners,
  billingAddress,
  setBillingAddress,
  setCurrentStep,
  handleSubmit,
  clearData,
  isChecked,
  setIsChecked,
  corporateOwnerType,
  setCorporateOwnerType,
  corporateOthersType,
  setCorporateOthersType,
  corporateDirectors,
  setCorporateDirectors,
  countries,
  selectedCountry,
  setSelectedCountry,
  states,
}) {
  return (
    <section>
      <IndexNavbar />
      <div>
        <div className="xl:px-5 xl:p-8 p-4 md:p-4 lg:p-8 xl:text-lg bg-white">
          <h2 className="font border-b text-cyan-500 xl:text-base lg:text-base font-medium md:text-base text-xl pb-3">
            Final Review
          </h2>
          <div className="flex justify-between pb-3">
            <h4 className="flex xl:text-base font-bold font pt-4">
              Startup Services
            </h4>
            <h3 className="flex xl:text-base font-bold font pt-4">
              Order #: IBC-OR3844
            </h3>
          </div>
          <h2 className="font border-b text-cyan-500 xl:text-base lg:text-base font-medium md:text-base text-xl pb-3">
            Profile
          </h2>
          <div className="border-t pb-4 pt-3">
            <div className="w-full flex flex-wrap xl:w-12/12">
              <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                First Name
              </div>
              <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12 ">
                :
              </div>
              <div className="block  text-dark-blue font-regular lg:pl-4  text-base mb-2 xl:w-6/12 w-5/12">
                {userData ? userData.name : ""}
              </div>
            </div>

            <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
              <div className="block  text-dark-blue font-medium text-base mb-2  xl:w-5/12 w-6/12">
                Last Name
              </div>
              <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12 ">
                :
              </div>
              <div className="block  text-dark-blue font-regular lg:pl-4 text-base mb-2 xl:w-6/12  w-5/12">
                {userData ? userData.last_name : ""}
              </div>
            </div>

            <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
              <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                Gender
              </div>
              <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12 ">
                :
              </div>
              <div className="block  text-dark-blue font-regular lg:pl-4 text-base mb-2 xl:w-6/12  w-5/12">
                {userData ? userData.gender : ""}
              </div>
            </div>

            <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
              <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                Phone number
              </div>
              <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12 ">
                :
              </div>
              <div className="block  text-dark-blue font-regular lg:pl-4 text-base mb-2 xl:w-6/12  w-5/12">
                {userData ? userData.mobile : ""}
              </div>
            </div>

            <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
              <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                E-mail
              </div>
              <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                :
              </div>
              <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                {userData ? userData.email : ""}
              </div>
            </div>

            <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
              <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12  w-6/12">
                Address
              </div>
              <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                :
              </div>
              <div className="block  text-dark-blue font-regular lg:pl-4 text-base mb-2 xl:w-6/12 w-5/12">
                {userData ? userData.flat : ""}
                <br></br>
                {userData ? userData.street : ""}
                <br></br>
                {userData ? userData.city : ""}
                <br></br>
                {userData ? userData.address_state : ""}
                <br></br>
                {userData ? userData.postal_code : ""}
                <br></br>
              </div>
            </div>
          </div>
          {/* Corporate Details */}
          <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base text-xl pb-3">
            Corporate Details
          </h2>
          <div className="w-full flex flex-wrap xl:pt-4 pt-1 xl:w-12/12 border-t">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              My company will be a
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {corporateDetails ? corporateDetails.corporate_type : ""}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              I want a company
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {corporateDetails ? corporateDetails.corporate_naming_type : ""}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              My company will be called
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {corporateDetails ? corporateDetails.corporate_name : ""}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              My company will based on :
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {corporateDetails ? corporateDetails.corporate_location : ""}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Company Formation Date
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {corporateDetails ? corporateDetails.formation_date : ""}
            </div>
          </div>

          {/* Directors And Officers */}
          <h2 className="font text-cyan-500 xl:pt-4 md:pt-4 pt-2 xl:text-base lg:text-base font-medium md:text-base text-xl pb-3">
            Directors And Officers
          </h2>
          <h2 className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base pt-0 text-xl pb-3">
            Directors
          </h2>
          {corporateDirectors.map((item, index) => (
            <>
              <div className="w-full flex flex-wrap xl:pt-4 pt-1 xl:w-12/12 border-t">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  I will manage and own this company
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  By { userData ? (item.first_name == userData.name ? "Myself" : "Others") : 'Others' }
                </div>
              </div>
              <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Director Name
                </div>

                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.first_name} {item.last_name}
                </div>
              </div>
              <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Email
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.email}
                </div>
              </div>
              <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Phone
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.mobile}
                </div>
              </div>
            </>
          ))}

          {/* Corporate Ownership */}
          <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base pt-6 text-xl pb-3">
            Corporate Ownership
          </h2>
          <h2 className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base pt-0 text-xl pb-3">
            Who will manage and own this company
          </h2>

          {corporateOwners.map((item, index) => (
            <>
              <div className="w-full flex flex-wrap pt-3 xl:w-12/12 border-t">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Name
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.name}
                </div>
              </div>
              <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Type of Shares
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.share_type}
                </div>
              </div>
              <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Shares in %
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.ownership}
                </div>
              </div>
            </>
          ))}

          {/* Individuals with Significant */}
          <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base pt-6 text-xl pb-3">
            Individuals with Significant
          </h2>
          {corporateDirectors.map((item, index) => (
            <>
              <div className="w-full flex flex-wrap pt-3 xl:w-12/12 border-t">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  First Name
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.first_name}
                </div>
              </div>

              <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Last Name
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.last_name}
                </div>
              </div>

              {/* <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Date
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  23-01-2002
                </div>
              </div> */}
              <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Email
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.email}
                </div>
              </div>
              <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
                <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
                  Phone
                </div>
                <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
                  :
                </div>
                <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
                  {item.mobile}
                </div>
              </div>
          <h2 className="font text-dark-blue xl:text-base lg:text-base font-medium md:text-base pt-0 xl:pt-4 text-xl pb-3">
            Residential Address
          </h2>
          <div className="w-full flex flex-wrap xl:pt-4 pt-1 xl:w-12/12 border-t">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Street Address 1
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {item.street1}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Street Address 2
            </div>

            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {item.street2}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              City
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {item.city}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Location
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {item.address_state}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Country
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {item.country}
            </div>
          </div>

          <div className="w-full flex flex-wrap pt-1 lg:w-12/12">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Postal Code
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {item.postal_code}
            </div>
          </div>
            </>
          ))}


          {/* Choose Your Plan */}
          <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base pt-8 text-xl pb-3">
            Choose Your Plan
          </h2>
          <h2 className="font text-dark-blue xl:text-base lg:text-base font-semibold md:text-base pt-0 text-xl pb-3">
            Registration Fees
          </h2>
          <div className="block  text-dark-blue font-medium xl:text-base text-base mb-2 xl:w-5/12 w-12/12">
            One time fee to incorporate your business
          </div>
          <div className="w-full flex flex-wrap pt-3 xl:w-12/12 border-t">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
            Government Registration One year Fee
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
            $200
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
            Legal Formation One year Fee
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
            $299
            </div>
          </div>
          {/* Billing Information */}
          <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base pt-8 text-xl pb-3">
            Billing Information
          </h2>
          <div className="w-full flex flex-wrap pt-3 xl:w-12/12 border-t">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              1 - Legal Formation
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              2500 CAD
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              2- One time fees to incorporate your business
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              0 CAD
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              3- Government Registration One year (Free zones) (Off shores)
              (Mainland)
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              0 CAD
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Subtotal
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              2500 CAD
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              VAT
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              0
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Total Account
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              2500 CAD
            </div>
          </div>
          {/* Billing To */}
          <h2 className="font text-cyan-500 xl:text-base lg:text-base font-medium md:text-base pt-8 text-xl pb-3">
            Billing To
          </h2>
          <div className="w-full flex flex-wrap pt-3 xl:w-12/12 border-t">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              First Name
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {billingAddress ? billingAddress.first_name : ""}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Last Name
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {billingAddress ? billingAddress.last_name : ""}
            </div>
          </div>
          {/* Billing & Mailing Address */}
          <h2 className="font text-blue xl:text-base lg:text-base font-medium md:text-base pt-4 text-xl pb-3">
            Billing & Mailing Address
          </h2>
          <div className="w-full flex flex-wrap pt-3 xl:w-12/12 border-t">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Flat/Building.No
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {billingAddress ? billingAddress.flat : ""}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Area, Street, Sector, Village
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {billingAddress ? billingAddress.street : ""}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Town/City
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {billingAddress ? billingAddress.city : ""}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              State
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {billingAddress ? billingAddress.address_state : ""}
            </div>
          </div>
          <div className="w-full flex flex-wrap pt-1 xl:w-12/12 ">
            <div className="block  text-dark-blue font-medium text-base mb-2 xl:w-5/12 w-6/12">
              Country
            </div>
            <div className="block  text-dark-blue font-semibold pl-4 text-base mb-2 xl:w-1/12">
              :
            </div>
            <div className="block  text-dark-blue font-regular pl-4 text-base mb-2 xl:w-6/12">
              {billingAddress ? billingAddress.country : ""}
            </div>
          </div>
          {/* <button
            className="mb-6 btn-primary uppercase text-sm border-primary font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
            onClick={() => setCurrentStep(0)}
          >
            Edit
          </button>
          <button
            className="ml-2 mb-6 button-danger uppercase text-sm border-danger font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
            onClick={clearData}
          >
            Delete
          </button> */}

          <button
            className="mb-6 border-blue px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-2 xl:py-1 md:px-4 md:mt-12 md:py-1 rounded-lg"
            onClick={() => setCurrentStep(0)}
          >
            <img
              src="https://i.ibb.co/YPH6Kq4/pen-1250615.png"
              className="w-5"
            />
          </button>
          <button
            className="ml-2 mb-6 border-blue px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-2 xl:py-1 md:px-4 md:mt-12 md:py-1 rounded-lg"
            onClick={clearData}
          >
            <img
              src="https://i.ibb.co/nrzbGnq/delete-1214428.png"
              className="w-5"
            />
          </button>
          <br></br>
          <button
            onClick={handlePrev}
            className="mb-6 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
          >
            Previous
          </button>
          <button
            className="ml-2 mb-6 buttonWithBlue text-sm border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-4 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white"
            onClick={handleSubmit}
          >
            Agree & Pay
          </button>
        </div>
      </div>
    </section>
  );
}

function Submit({ formData, updateFormData, handlePrev }) {
  return (
    <section>
      <IndexNavbar />
      <div>
        <h2>Final Review</h2>
        <button onClick={handlePrev}>Previous</button>
        <button>Submit</button>
      </div>
    </section>
  );
}

export default CorporationService;
