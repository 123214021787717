import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import bubble from "assets/img/images/bubble.jpg";
import startupimg from "assets/img/images/startupimg.png";

const ItService = () => {
  return (
    <section>
      <IndexNavbar />
      <div className="relative xl:h-max md:h-60 h-48">
        <div
          style={{
            backgroundImage: `url(${bubble})`,
            width: "100%",
            height: "610px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "absolute",
            marginTop: "5.7rem",
          }}
          className="top-0 left-0 opacity-1"
        ></div>
        <div className="container mx-auto xl:px-16 px-4 overflow-hidden ">
          <h1>StartUp Service</h1>
          <div className="grid xl:grid cols-2 lg:grid-cols- md:grid-cols-2 grid-cols-1">
            <div className="xl:mt-56">
              <p className="font xl:text-3xl">
                Business creation services{" "}
                <b> incorporating a business in a foreign</b> jurisdiction is
                never easy tax, procedures,
              </p>
              <p className="font xl:text-lg">
                Deadlines, languages and costs vary greatly from country to
                country and sometimes from city to city. We face a huge mix of
                languages, corporate culture, legal liability and bureaucratic
                requirements. Wherever you need to trade or invest, we are local
                company experts and ensure that local laws, regulations and
                official deadlines (such as tax registration can be very strict)
                are met in order to start a business new business often. We live
                the local culture and speak the language, so we don't interfere
                with local customs and working methods. We ensure that every
                step is completed with confidence for our clients, even in
                jurisdictions that require handwritten documents to be submitted
                in person. iBC is one of the world's leading experts in
                cross-border business formation, providing support for business
                formation worldwide. Our comprehensive services are provided by
                a team of experienced local experts who provide insider
                knowledge of its 3+ jurisdictions around the world.
              </p>
              <div className="xl:mt-12">
                <button className="uppercase font xl:text-base text-white font-semibold xl:px-4 xl:py-2 rounded-full bg-sky-400">
                  get quote
                </button>
                <button className="uppercase font xl:text-base xl:px-4 xl:py-2 rounded-full text-black font-semibold border-black xl:ml-4">
                  contact us
                </button>
              </div>
            </div>

            <div className="xl:mt-56 xl:ml-48">
              <img src={startupimg} className="xl:w-92" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};
export default ItService;
