import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import dropdown from "assets/img/dropdown.png";
import ibusinesslogo from "assets/img/icons/ibusinesslogo.png";
import { createPopper } from "@popperjs/core";
import corporationservice from "assets/img/corporationservice.svg";
import hrservice from "assets/img/hrservice.svg";
import ipservice from "assets/img/ipservice.svg";
import itservice from "assets/img/itservice.svg";
import axios from "axios";

export default function IndexNavbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const popoverDropdownRef = React.createRef();
  const btnDropdownRef = React.createRef();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  //Services on hover box
  const [showFirstMenu, setShowFirstMenu] = useState(false);
  const handleFirstMenuHover = () => {
    setShowFirstMenu(true);
  };

  const handlePackagesMenuLeave = () => {
    setShowFirstMenu(false);
  };

  //Profile on hover box
  const [showSecondMenu, setShowSecondMenu] = useState(false);
  const handleSecondMenuHover = () => {
    setShowSecondMenu(true);
  };

  const handlePackagessMenuLeave = () => {
    setShowSecondMenu(false);
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (isDropdownVisible) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });
    }
  }, [isDropdownVisible]);

  useEffect(() => {
    const isLoggedInValue = localStorage.getItem("is_logged_in");
    setIsLoggedIn(isLoggedInValue ? JSON.parse(isLoggedInValue) : false);
  }, []);

  // Login code start

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get("https://admin.ibusinessconsulting.ca/admin/public/index.php/api/user", {
        headers: { Authorization: ` Bearer ${token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          setLoggedInUser(response.data);
        }
      })
      .catch((error) => {
        if (error.request.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("is_logged_in");
          setIsLoggedIn(false);
          setLoggedInUser(null);
        }
      });
  }, []);

  return (
    <section className="">
      <nav className="fixed z-50 top-0 left-0 right-0 w-full xl:pb-2 navbar-expand-lg bg-white shadow-lg-blue">
        <div className="container mx-auto xl:px-16 px-4 overflow-hidden ">
          <div
            className={
              "lg:flex justify-between flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none py-3 xl:pt-1 xl:py-0" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <div className="flex justify-between">
              <div className="pr-4">
                {/* <span> */}
                <a href="/">
                  <img
                    src={ibusinesslogo}
                    alt=""
                    className="xl:h-16 xl:w-70 md:w-48 h-12 w-48 xl:pt-2 lg:pt-0 lg:mt-0 mt-8 xl:mt-0 cursor-pointer"
                  />
                </a>
                {/* </span> */}
              </div>

              <button
                className="cursor-pointer text-xs leading-none block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                ✖
              </button>
            </div>

            <div className="xl:block lg:block md:hidden sm:hidden hidden">
              <ul className="flex flex-col lg:flex-row w-full list-none mr-auto">
                {/* <div className="menu navbar-links xl:ml-navbar lg:ml-84 xl:mt-2"> */}
                <div className="menu navbar-links xl:mt-2">
                  <div
                    className="menu-item"
                    onMouseEnter={handleFirstMenuHover}
                    onMouseLeave={handlePackagesMenuLeave}
                  >
                    <div className="items-center flex text-sky-800 pr-4 relative cursor-pointer">
                      <h1 className="navbar-dropdown white-on-hover pr-1 cursor-pointer xl:text-sm lg:text-sm text-sm sm:text-base para-font xl:pt-4 lg:pt-4">
                        <a href="/solution-homepage" className="uppercase">
                          Services
                        </a>
                        <span className="ml-1">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </span>
                      </h1>
                      {/* <i className="las la-angle-down cursor-pointer"></i> */}
                    </div>
                    <div className="fixed xl:top-14 lg:top-20 xl:w-22/12 lg:w-22/12 cursor-pointer">
                      {showFirstMenu && (
                        <div className="menu-dropdown bg-white rounded-md shadow-xll border-grey-50 xl:mt-7 lg:mt-7 lg:-ml-32 xl:-ml-16 animate-slide-up">
                          <div className="flex">
                            <div className="xl:w-12/12  xl:p-4 lg:p-4">
                              <div className="flex items-center xl:w-12/12 xl:pl-1 xl:pt-0 ">
                                <div className="xl:w-22/12 lg:w-2/12">
                                  <img
                                    src={corporationservice}
                                    className="w-10 xl:pt-1 xl:ml-2"
                                  ></img>
                                </div>
                                <div className="xl:w-9/12">
                                  <a
                                    href="/corporate-services"
                                    className="pl-3 xl:w-10/12 text-light-bluee xl:pl-3 lg:pl-4"
                                  >
                                    <p className="pl-2 xl:text-sm lg:text-sm uppercase text-black  font-semibold font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      Corporate Service
                                    </p>
                                    <p className="pl-2 font text-sm text-light-bluee para-font">
                                      Incorporating a business abroad is complex
                                      due to diverse tax laws.
                                    </p>
                                  </a>
                                </div>
                              </div>

                              <div className="flex items-center xl:w-12/12 xl:pl-1 xl:pt-2">
                                <div className="xl:w-22/12 lg:w-2/12">
                                  <img
                                    src={itservice}
                                    className="w-10 xl:pt-1 xl:ml-2"
                                  ></img>
                                </div>
                                <div className="xl:w-9/12">
                                  <a
                                    href="/it-services"
                                    className="pl-3 xl:w-10/12 text-light-bluee xl:pl-8 lg:pl-4"
                                  >
                                    <p className="pl-2 xl:text-sm lg:text-sm uppercase text-black font-semibold  font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      IT Service
                                    </p>
                                    <p className="pl-2 font text-sm text-light-bluee para-font">
                                      We provide full-scale IT services covering
                                      web design, and all.
                                    </p>
                                  </a>
                                </div>
                              </div>

                              <div className="flex items-center xl:w-12/12 xl:pl-1 xl:pt-2">
                                <div className="xl:w-22/12 lg:w-2/12">
                                  <img
                                    src={hrservice}
                                    className="w-10 xl:pt-1 xl:ml-2"
                                  ></img>
                                </div>
                                <div className="xl:w-9/12">
                                  <a
                                    href="/hr-service-homepage"
                                    className="pl-3 xl:w-10/12 text-light-bluee xl:pl-8"
                                  >
                                    <p className="pl-2 xl:text-sm lg:text-sm uppercase text-black font-semibold font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      HR Service
                                    </p>
                                    <p className="pl-2 font text-sm text-light-bluee para-font">
                                      Our HR department handles candidate
                                      recruitment processes.
                                    </p>
                                  </a>
                                </div>
                              </div>

                              <div className="flex items-center xl:w-12/12 xl:pl-1 xl:pt-2 xl:pb-4">
                                <div className="xl:w-22/12 lg:w-2/12">
                                  <img
                                    src={ipservice}
                                    className="w-10 xl:pt-1 xl:ml-2"
                                  ></img>
                                </div>
                                <div className="xl:w-9/12">
                                  <a
                                    href="/ip-service-homepage"
                                    className="pl-3 xl:w-10/12 text-light-bluee xl:pl-8 lg:pl-4"
                                  >
                                    <p className="pl-2 xl:text-sm lg:text-sm uppercase text-black font-semibold font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      IP Service
                                    </p>
                                    <p className="pl-2 font text-sm text-light-bluee para-font">
                                      Intellectual property, such as patents,
                                      copyrights, and trademarks.
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="xl:block lg:block md:hidden hidden sm:hidden">
                  <div className="w-full xl:ml-0 lg:ml-4">
                    {isLoggedIn ? (
                      <>
                        <div className="items-center flex text-sky-800 text-sm sm:text-base font-medium pr-4 relative cursor-pointer">
                          <h1 className="navbar-dropdown white-on-hover pr-1 cursor-pointer xl:text-base1 lg:text-base para-font xl:pt-3 xl:pl-76">
                            <a href="/sidebar-landing">
                              <div className="buttonWithBlueround rounded-full xl:px-5 xl:py-new lg:py-4 lg:px-5 font-bold">
                                {loggedInUser ? loggedInUser.name[0] : <span className="fa fa-user"></span>}
                              </div>
                            </a>
                          </h1>
                        </div>

                        {/* After login user name log will display */}
                        {/* <div className="menu navbar-links">
                          <div
                            className="menu-item"
                            onMouseEnter={handleSecondMenuHover}
                            onMouseLeave={handlePackagessMenuLeave}
                          >
                            <div className="items-center flex text-sky-800 text-sm sm:text-base font-medium relative cursor-pointer">
                              <h1 className="navbar-dropdown white-on-hover pr-1 cursor-pointer xl:text-base1 lg:text-base para-font xl:pt-1">
                                <div className="buttonWithBlueround rounded-full xl:px-5 xl:py-4 lg:py-4 lg:px-6 font-bold">
                                  {loggedInUser && loggedInUser.name[0]}
                                </div>
                              </h1>
                            </div>
                            <div className="fixed xl:top-14 lg:top-20 xl:w-1/12 lg:w-1/12 cursor-pointer">
                              {showSecondMenu && (
                                <div className="menu-dropdown bg-white rounded-md shadow-xl border-light-blue-alterpro xl:mt-5 xl:-ml-16 animate-slide-up">
                                  <div className="pl-6 pb-4">
                                    <div className="items-center pt-4">
                                      <a
                                        href="/profile"
                                        className="white-on-hover text-light-blue"
                                      >
                                        <p className="xl:text-base font text-base font-medium xl:-pt-4 md:pt-4 lg:pt-0 pt-4">
                                          Profile Settings
                                        </p>
                                      </a>
                                    </div>

                                    <div className="items-center pt-4">
                                      <a
                                        href="/login"
                                        className="white-on-hover text-light-blue "
                                      >
                                        <p className="xl:text-base font text-base font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                          Sign Out
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div> */}
                      </>
                    ) : (
                      <>
                        <a href="/login">
                          <button className="font border-blue bg-button-white px-8 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-8 xl:py-1 md:px-8 md:mt-12 md:py-1 border rounded text-blue-new md:ml-6 ml-2 xl:ml-0 lg:-ml-3">
                            Sign in
                          </button>
                        </a>

                        <a href="/corporate-wizard">
                          <button class="font buttonWithBlue border-blue px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-5 xl:py-1 md:px-4 md:mt-12 md:py-1 border rounded red-new text-white md:ml-6 ml-2 xl:ml-4 lg:ml-4 lg:-ml-3">
                            Get Started
                          </button>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </ul>
            </div>

            {/* Mobile and tab view */}

            <div className="xl:hidden lg:hidden md:block block">
              <div className="border-b pb-2 mb-2">
                <p className="ml-2 py-4 text-blue font font-semibold text-base">
                  Welcome To iBusiness Consulting Firm<br></br>
                  Helping Hand For Entrepreneurs
                </p>
              </div>
              <span
                className="flex-1 flex justify-between my-3 md:pt-6 pt-4"
                onClick={handleDropdownToggle}
              >
                <h3
                  className="md:border-b-long xl:border-b-none font lg:border-b-none pb-0 md:pb-4 uppercase text-dark-blue text-base pl-2"
                  onClick={handleDropdownToggle}
                >
                  <a href="/solution-homepage">Services</a>
                </h3>
                <img
                  src={dropdown}
                  className="w-4 h-4"
                  alt="dropdown icon"
                  onClick={handleDropdownToggle}
                />
              </span>

              {isDropdownOpen && (
                <div className="dropdown-content">
                  <div className="font xl:p-4 pb-0 pl-4 md:pl-8 md:p-0 p-0 xl:w-11/12">
                    <a href="/corporate-services">
                      <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-4 md:pt-4 lg:pt-14 pt-4">
                        Corporate Services
                      </p>
                    </a>

                    <a href="/it-services">
                      <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                        IT Services
                      </p>
                    </a>

                    <a href="/ip-service-homepage">
                      <p className="font xl:text-sm text-base font-medium text-light-blue  xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                        IP Services
                      </p>
                    </a>

                    <a href="/hr-service-homepage">
                      <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                        HR Services
                      </p>
                    </a>
                  </div>
                </div>
              )}
            </div>

            {/* Mobile view and tab view  */}
            <div className="xl:hidden  lg:hidden md:block block">
              <a href="/about-us">
                <p className="md:border-b-longg white-on-hover xl:border-b-none lg:border-b-none cursor-pointer md:pl-0 pl-2 xl:pl-0 md:pb-3 pb-0 xl:pb-0 lg:pb-0 lg:pl-0 md:pt-4 pt-5 xl:pt-0 lg:pt-0 font text-base uppercase text-dark-blue">
                  About Us
                </p>
              </a>
            </div>
            <div className="xl:hidden lg:hidden md:block block">
              <a href="/contact">
                <p className="md:border-b-longg xl:border-b-none lg:border-b-none cursor-pointer md:pl-0 pl-2 xl:pl-0 md:pb-3 pb-0 xl:pb-0 lg:pb-0 lg:pl-0 md:pt-4 pt-5 xl:pt-0 lg:pt-0  text-base font  uppercase text-dark-blue">
                  Contact Us
                </p>
              </a>
            </div>

            <div className="xl:hidden lg:hidden md:block block sm:block">
              <div className="xl:ml-42 lg:ml-34">
                {isLoggedIn ? (
                  <>
                    <a href="/sidebar-landing">
                      <button class="buttonWithBlue border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-8 xl:py-2 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white md:ml-6 ml-2 xl:ml-4 lg:ml-4 lg:-ml-3">
                        Dashboard
                      </button>
                    </a>
                  </>
                ) : (
                  <>
                    <a href="/login">
                      <button className="border-blue bg-white font-semibold px-8 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-10 xl:py-2 md:px-8 md:mt-12 md:py-1 border rounded-lg text-blue-new md:ml-6 ml-2 xl:ml-0 lg:-ml-3">
                        Sign in
                      </button>
                    </a>

                    <a href="/corporate-wizard">
                      <button class="buttonWithBlue border-blue font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-8 xl:py-2 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white md:ml-6 ml-2 xl:ml-4 lg:ml-4 lg:-ml-3">
                        Get Started
                      </button>
                    </a>
                  </>
                )}
              </div>
            </div>

            <div className="xl:hidden lg:hidden md:block block">
              <div className="mt-32 pt-8 border-t xl:pl-4 md:pl-4 pb-96 pl-0">
                <a href="">
                  <button
                    className="bg-blue text-white shadow-lg font-normal  h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href=""
                  >
                    <i className="fab fa-facebook"></i>
                  </button>
                </a>

                <a href="">
                  <button
                    className="bg-blue text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href="twitter"
                  >
                    <i className="">𝕏</i>
                  </button>
                </a>

                <a href="">
                  <button
                    className="bg-blue text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href="youtube"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                </a>

                <a href="">
                  <button
                    className="bg-blue text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href="youtube"
                  >
                    <i className="fab fa-linkedin"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="xl:hidden lg:hidden md:block sm:block block md:py-4 py-4">
            <div className="px-0">
              <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <div className={`px-0 ${navbarOpen ? "hidden" : ""}`}>
                  <span>
                    <a href="index">
                      <img
                        src={ibusinesslogo}
                        alt=""
                        className="xl:h-12 xl:w-52 md:h-45 md:w-56 lg:h-12 h-10 w-48 cursor-pointer pr-0 md:pr-0"
                      />
                    </a>
                  </span>
                </div>
                <button
                  className="cursor-pointer text-xl leading-none px-3 py-1 border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                  type="button"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  {navbarOpen ? (
                    <i className=""></i>
                  ) : (
                    <i className="fas fa-bars"></i>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
}
