import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer.js";
import EnquiryButton from "components/Pages/EnquiryButton";
import webapplicationdevelopment from "assets/img/webapplicationdevelopment.svg";
import startupservice from "assets/img/startupservice.svg";
import businessadvisoryservice from "assets/img/business-advisory-service.svg";
import basnew from "assets/img/basnew.svg";
import corporateserviceibc from "assets/img/corporateserviceibc.svg";

const CorporateServices = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const [isContent2Visible, setIsContent2Visible] = useState(false);

  const toggleContent2 = () => {
    setIsContent2Visible(!isContent2Visible);
  };
  return (
    <section>
      <IndexNavbar />
      <EnquiryButton />
      <div className="xl:mt-20 lg:mt-18 mt-18">
        <div className="">
          <div className="bg-grey-50">
            <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
              <a href="index" className="link">
                Home
              </a>
              ― Corporate Services
            </div>
          </div>
        </div>

        {/* Employer Service */}

        <div className="container mx-auto overflow-hidden xl:px-16 px-4">
          <div className="items-center grid xl:grid-cols-2 xl:gap-12 md:grid-cols-2 md:gap-8 lg:grid-cols-2 lg:gap-8">
            <div>
              <p className="uppercase xl:text-base md:text-xs text-sm text-cyan-500 xl:mt-16 lg:mt-12 md:mt-12 mt-8">
                Mastering Business
              </p>
              <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl xl:pt-1 lg:pt-2 pt-2">
                Mastering Business Incorporation Across Borders
              </div>
              <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-2 lg:pt-2 pt-1 xl:pb-4 lg:pb-0 md:pb-4 pb-3">
                Incorporating a business in Canada or any foreign jurisdiction
                presents a myriad of challenges—from navigating complex tax
                procedures and adhering to local regulations, to meeting
                stringent deadlines that vary significantly from one province or
                country to another.
              </div>
              <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-2 lg:pt-2 pt-1 xl:pb-4 lg:pb-4 pb-0 md:pb-4">
                At iBC, we specialize in understanding and managing the
                intricate web of corporate culture, legal liabilities, and
                bureaucratic demands essential for starting and running a
                business in diverse regulatory environments. No matter where you
                aim to establish your enterprise, our seasoned local experts
                ensure full compliance with all relevant laws, regulations, and
                crucial deadlines, including precise tax registration processes
                critical to your business's launch.
              </div>
            </div>
            <div className="xl:block lg:block md:block hidden">
              <div className="h-auto xl:mr-24 xl:p-12 lg:p-4 md:p-0 lg:pb-0 p-8">
                <img
                  src={corporateserviceibc}
                  alt="Partner Image"
                  className="rounded-md xl:p-0 lg:p-12 xl:mt-16 xl:ml-20 md:mt-8 lg:mt-8 mt-2"
                />
              </div>
            </div>
          </div>
          <div className="xl:pt-0 pt-2 para-font">
            <p
              className="figtree text-base underline-on-hover xl:text-base cursor-pointer font-bold text-light-blue"
              onClick={toggleContent}
            >
              Learn more <span className="right-symbol">➜</span>
            </p>
          </div>
          {isContentVisible && (
            <div className="text-left">
              <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-0 lg:pt-2 pt-1 xl:pb-4 lg:pb-0 md:pb-4 pb-0">
                Our team is deeply entrenched in the local cultures and fluent
                in the languages of the regions we operate in, allowing us to
                integrate seamlessly and respect local customs without
                disrupting traditional working methods. This cultural
                sensitivity is vital, particularly in jurisdictions requiring
                that official documents be submitted in person and often
                handwritten. Proudly standing as a global leader in cross-border
                business formation, iBC provides unparalleled support for
                setting up businesses worldwide. Our comprehensive services are
                powered by a cadre of local professionals, each bringing deep,
                insider knowledge of over 30 jurisdictions. Let us help you
                navigate the complexities of international business formation,
                ensuring your venture is built on a solid foundation, ready to
                thrive globally.
              </div>
            </div>
          )}

          <div className="xl:hidden lg:hidden md:hidden block">
            <div className="h-auto xl:mr-24 xl:p-12 lg:p-4 md:p-0 lg:pb-0 p-8">
              <img
                src={startupservice}
                alt="Partner Image"
                className="rounded-md xl:p-0 lg:p-12 xl:mt-16 xl:ml-20 md:mt-8 lg:mt-8 mt-2"
              />
            </div>
          </div>
        </div>

        {/*  Candidate Service */}

        <div className="container mx-auto overflow-hidden xl:px-16 px-4 xl:pb-0 lg:pb-0 md:pb-4">
          <div className="bg-cover xl:mt-8 mt-8 md:mt-8">
            <div className="grid xl:grid-cols-2 grid-cols-1 md:grid-cols-2 md:gap-8 md:flex-col-reverse  lg:grid-cols-2 lg:gap-8 flex-col-reverse xl:flex-col-reverse flex xl:flex-none xl:gap-12 gap-4">
              <div className="h-auto xl:p-24 lg:p-12  md:p-4 p-8 xl:mr-20 xl:mt-0 lg:mt-4 md:mt-20">
                <img
                  src={basnew}
                  alt="Partner Image"
                  className="rounded-md xl:pl-0 "
                />
              </div>
              <div className="xl:pb-8 md:pb-4 lg:pb-4 pb-0">
                <p className="uppercase xl:text-base md:text-xs text-sm text-cyan-500 xl:mt-14 lg:mt-4">
                  Business Advisory
                </p>
                <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl lg:pt-1 pt-2 xl:pt-2">
                  Business Advisory Service
                </div>
                <div className="font text-justify text-base text-light-blue para-font leading-relaxed xl:pt-1 lg:pt-2 pt-1 xl:pb-4 lg:pb-0 md:pb-1 pb-2">
                  iBC is your partner in success, offering access to our
                  extensive nationwide network of top-tier management
                  consultants. Whether you're seeking to overcome business
                  challenges or propel your growth to new heights, our seasoned
                  professionals are here to guide you.
                </div>
                <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-1 lg:pt-2 pt-1 xl:pb-4 lg:pb-0 md:pb-1 pb-2">
                  Drawing from their wealth of industry experience, our
                  consultants employ a structured, business-centric approach
                  that integrates proven methodologies and industry best
                  practices. The result? Practical, tangible results that drive
                  your business forward.
                </div>

                <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-1 lg:pt-2 pt-1 xl:pb-4 lg:pb-0 md:pb-1 pb-2">
                  Our solutions are tailored to empower you with the knowledge,
                  skills, and tools necessary to effectively manage every facet
                  of your business. We specialize in:
                </div>

                <div className="xl:pt-0 pt-2 para-font">
                  <p
                    className="figtree text-base font-bold underline-on-hover xl:text-base cursor-pointer text-light-blue"
                    onClick={toggleContent2}
                  >
                    Learn more <span className="right-symbol">➜</span>
                  </p>
                </div>
                {isContent2Visible && (
                  <div>
                    <div className="xl:pt-2 lg:pt-2 md:pt-0 pt-2">
                      <p className="font text-base text-light-blue para-font text-left">
                        <b>Strategic Planning: </b> Chart a clear path for your
                        business's future growth and success.
                      </p>

                      <div className="xl:pb-16 lg:pb-8 md:pb-4 pb-0 xl:pt-2 lg:pt-2 md:pt-2 pt-2">
                        <p className="font text-base text-light-blue para-font text-left">
                          <b> International Expansion: </b> Navigate the
                          complexities of global markets with confidence.
                        </p>

                        <p className="font text-base text-light-blue para-font text-left xl:pt-2 lg:pt-2 md:pt-2 pt-2">
                          <b>Technology Solutions: </b>Leverage cutting-edge
                          technology to optimize your operations and stay ahead
                          of the curve.
                        </p>

                        <p className="font text-justify text-base text-light-blue para-font text-left xl:pt-2 lg:pt-2 md:pt-2 pt-2">
                          <b> Operational Efficiency: </b> Streamline processes
                          and maximize productivity to achieve peak performance.
                          At iBC, our mission is to equip you with the expertise
                          and resources needed to thrive in today's dynamic
                          business landscape. Let us be your trusted partner on
                          the journey to success.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default CorporateServices;
