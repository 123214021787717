import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import service from "assets/img/icons/service.jpg";
import favicon from "assets/img/icons/favicon.png";
import logo from "assets/img/icons/logo.webp";
import Meetmarslab from "assets/img/images/Meetmarslab.png";

export default function CloudService() {
  return (
    <section className="relative overflow-hidden">
      <IndexNavbar fixed />
      <div className="xl:mt-20 lg:mt-16 mt-16"></div>
      <div className="container xl:px-16 px-4 mx-auto relative overflow-hidden ">
        <div className="flex xl:w-12/12 w-12/12 md:12/12 xl:gap-4 xl:py-16">
          <div className="font xl:w-8/12 md:w-8/12">
            <div className="xl:pr-4">
              <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-4 pt-8 md:pt-12 xl:py-0">
                What is Marslab Consult?
              </p>
            </div>

            <div className="xl:pr-4">
              <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
                Marslab Consult drives digital transformation by synchronizing
                business goals with the underlying technology, capitalizing on
                decades of mission-critical expertise and an embedded culture of
                continuous modernization.
              </p>
              <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-4 pt-4">
                We spark innovation by merging top-notch technical expertise
                with data-driven intelligence from the Marslab Bridge platform
                and the collaborative problem-solving approaches of Marslab
                Vital.
              </p>

              <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-4 pt-4">
                Our extensive global network of technology strategy consultants,
                architects, and engineers possesses deep domain expertise, honed
                through decades of experience in navigating some of the world's
                most intricate IT environments.
              </p>
            </div>

            <div class="flex xl:w-12/12 border-red shadow-xl bg-transparent-black rounded-lg xl:mt-8 xl:p-3 mt-4">
              <div class="xl:w-2/12 xl:p-1">
                <img
                  src={favicon}
                  class="xl:h-16 h-8 w-32 mt-4 xl:mt-0 md:w-28"
                />
              </div>
              <div class="font xl:w-10/12 xl:pl-3 pl-4 pt-1">
                <p class="xl:text-sm text-white text-xs font">
                  Marslab is the only “visionary” again on the 2021 Gartner
                  Magic Quadrant for the CRM Customer Engagement Center.
                </p>

                <a href="">
                  <p class="text-danger xl:text-base text-xs xl:pt-2 pt-1">
                    Get the report
                  </p>
                </a>
              </div>
            </div>

            <p className="font text-black xl:text-4xl md:text-2xl text-2xl font-semibold xl:w-11/12 xl:pt-16 pt-8 md:pt-12 xl:py-0">
              How Marslab Consult works
            </p>

            <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
              We partner with customers to eliminate roadblocks to innovation
              and empower them with modern IT management practices.
            </p>
            <p className="font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-4 pt-4">
              Our continuous lifecycle approach caters to businesses at any
              stage of their digital transformation journey and provides a
              framework for seamless co-innovation.
            </p>
            <div className="xl:hidden md:hidden block">
              <div className="font xl:mt-0 xl:pt-3 ml-12 my-4">
                <img
                  src={Meetmarslab}
                  alt="Your browser does not support this image shadow-xl"
                  className="xl:h-400 md:240 h-250 rounded-xl"
                ></img>
              </div>
            </div>
          </div>
          <div className="xl:block md:block hidden">
            <div className="font xl:mt-0 xl:pt-8 md:pt-24">
              <img
                src={Meetmarslab}
                alt="Your browser does not support this image shadow-xl"
                className="xl:h-400 md:240 h-250 rounded-xl"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto xl:px-16 px-4 xl:py-4 xl:pb-20 md:py-8">
        <div className="grid xl:grid-cols-4 md:grid-cols-5 grid-cols-2 gap-2 xl:gap-2 mt-4 mb-8">
          <div className="border-ivory-black cursor-pointer buttonWithGradientnew text-black xl:p-4 p-2 rounded-xl  text-center align-items">
            <img
              src={logo}
              className="xl:h-16 xl:w-16 h-16 w-16 xl:ml-24 md:ml-8 ml-12"
            />
            <p className="font text-ivory-black xl:text-lg md:text-sm text-sm font-semibold xl:pt-2 pt-2">
              Envision
            </p>
            <p className="font xl:text-sm text-xs md:text-xs xl:pt-1 pt-1">
              Initiate a thorough evaluation and strategic roadmap exercise to
              pinpoint and drive towards sought-after business outcomes.
            </p>
          </div>

          <div className="border-ivory-black cursor-pointer buttonWithGradientnew text-black xl:p-4 p-2 rounded-xl  text-center align-items">
            <img
              src={logo}
              className="xl:h-16 xl:w-16 h-16 w-16 xl:ml-24 md:ml-8 ml-12"
            />
            <p className="font xl:text-lg font-semibold text-sm xl:pt-2 pt-2">
              Design
            </p>
            <p className="font xl:text-sm text-xs xl:pt-1 pt-1">
              Collaboratively craft solutions tailored to your specific industry
              and unique hybrid technology landscape.
            </p>
          </div>

          <div className="border-ivory-black cursor-pointer buttonWithGradientnew text-black xl:p-4 p-2 rounded-xl  text-center align-items">
            <img
              src={logo}
              className="xl:h-16 xl:w-16  h-16 w-16 xl:ml-24 md:ml-8  ml-12"
            />
            <p className="font xl:text-lg text-sm font-semibold xl:pt-2 pt-2">
              Adopt
            </p>
            <p className="font xl:text-sm text-xs xl:pt-1 pt-1">
              Streamline your transformation journey by seamlessly migrating,
              modernizing, and integrating workloads to the most suitable
              environments.
            </p>
          </div>

          <div className="border-ivory-black cursor-pointer buttonWithGradientnew text-black xl:p-4 p-2 rounded-xl  text-center align-items">
            <img
              src={logo}
              className="xl:h-16 xl:w-16 h-16 w-16 xl:ml-24 md:ml-8 ml-12"
            />
            <p className="font xl:text-lg text-sm font-semibold xl:pt-2 pt-2">
              Evolve
            </p>
            <p className="font xl:text-sm text-xs xl:pt-1 pt-1">
              Regularly pause, assess progress, identify successes and areas for
              improvement, and adapt accordingly for the next phase of
              modernization.
            </p>
          </div>
        </div>
        <div>
        <p className="xl:text-lg md:text-base text-base text-danger font-semibold text-center xl:pt-4 pt-4">
          Revamp your transformation roadmap with Marslab Consult's Strategy &
          Transformation expertise.
        </p>
      </div>
      </div>
      
      <Footer />
    </section>
  );
}
