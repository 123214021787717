import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";

const CancellationPolicy = () => {
  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-18 mt-18">
        <div className="">
          <div className="bg-grey-50">
            <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
              <a href="index" className="link">
                Home{" "}
              </a>{" "}
              ― Cancellation policy
            </div>
          </div>
        </div>
        <div className="container mx-auto overflow-hidden xl:px-16 px-4 xl:pb-16 pb-8 md:pb-8">
          <h1 className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl text-center xl:pt-16 lg:pt-12 md:pt-12 xl:px-24 lg:px-32 pt-8">
          Cancellation Policy
          </h1>
          <div className="text-left ">
            {/* <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              Introduction
            </p> */}
            <p className="para-font xl:text-base text-dark-blue xl:pt-4 md:pt-2 pt-2  md:text-sm text-sm">
              At iBusiness Consulting (iBC), we strive to maintain a fair and
              transparent relationship with our clients. To ensure clarity and
              fairness, we have established the following cancellation policy:
            </p>
            {/* <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              This is accomplished through a variety of business procedures and
              security measures that keep your personal information confidential
              and secure and will only be used in accordance with this Privacy
              Policy or with your consent.
            </p> */}
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Notice Required:
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-0 md:pt-2 pt-2  md:text-sm text-sm">
              To cancel a service and qualify for a full refund, clients must
              provide a written notice (via email or letter) at least [Number]
              business days prior to the scheduled start date of the service.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Late Cancellations:
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-0 md:pt-2 pt-2  md:text-sm text-sm">
              Cancellations made within [Number] business days of the scheduled
              start date are subject to a [Percentage]% cancellation fee.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              No-Shows:
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-0 md:pt-2 pt-2  md:text-sm text-sm">
              Clients who fail to show up for a scheduled service without
              notifying iBC in advance will be charged the full service fee.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Project Engagements:
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-0 md:pt-2 pt-2  md:text-sm text-sm">
              For ongoing project engagements, we require a [Number]-day written
              notice for termination. Please note that early termination may
              result in prorated fees based on the extent of work completed
              prior to the notice.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Specific Service Terms:
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-0 md:pt-2 pt-2  md:text-sm text-sm">
              Please be aware that some services or projects may have specific
              cancellation terms which will be detailed in your service
              agreement. Always discuss and confirm the cancellation terms with
              your iBC representative when contracting our services.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Additional Considerations:
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-0 md:pt-2 pt-2  md:text-sm text-sm">
              We collect Personal Information from you in several ways,
              including but not limited to:
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              1. Unforeseen Circumstances: We understand that unexpected events
              may occur. Please contact us immediately if exceptional
              circumstances affect your ability to adhere to our cancellation
              policy.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              2. Rescheduling: If you wish to reschedule a service, please
              inform us as soon as possible. We will do our best to accommodate
              your needs.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              3. Refund Process: Refunds will be processed to the original
              payment method within a specified timeframe, which will be
              communicated at the time of cancellation.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              Our cancellation policy is designed to foster good communication
              and planning, helping us provide high-quality, timely service to
              all clients while respecting the commitments made to our team and
              other clients.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              Thank you for choosing iBusiness Consulting. We look forward to
              serving you and ensuring your experience with us is both
              successful and satisfying.
            </p>
          </div>
          {/* <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Consent
            </p>
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Your consent to the collection, use, or disclosure of your
              personal information may be obtained in writing or electronically.
              Consent can also be implied through your actions. You have the
              right to opt out or withdraw your consent at any time, though this
              may affect our ability to provide you with certain services.
              Disclosure of Personal Information
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              We do not share your personal information with third parties,
              except as described in this Privacy Policy or when required by
              law, or with your explicit consent.
            </p>
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Responsibility and Compliance
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              iBC is committed to protecting your personal information in
              accordance with this Privacy Policy and relevant data protection
              regulations. Our Data Protection Officer oversees compliance and
              is available to address any questions or concerns you may have
              regarding our privacy practices.
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              If you have any issues or complaints, we encourage you to contact
              our Data Protection Officer. We will investigate and respond
              promptly and, if necessary, adjust our policies and practices.
            </p>
            
          </div>
          <div className="text-left ">
            <p className="font-bold para-font xl:text-base text-dark-blue pt-4">
              Communication Preferences
            </p>
            <p className="para-font xl:text-base text-dark-blue xl:pt-1 md:pt-2 pt-2  md:text-sm text-sm">
              iBC respects your preferences concerning the receipt of
              promotional communications. You can update your communication
              preferences at any time, and changes will be implemented within 10
              business days at no cost. This Privacy Policy is subject to
              periodic updates and modifications. Please review it frequently to
              stay informed about how we are protecting your personal
              information.
            </p>
          </div> */}
        </div>
      </div>
      <Footer />
    </section>
  );
};
export default CancellationPolicy;
