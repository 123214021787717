const ApplicationContent2 = () => {
  return (
    <section>
      {/* section */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 xl:pt-8 pt-0 lg:pt-0 md:pt-2">
          <div className="font xl:w-9/12 xl:text-4xl lg:text-3xl md:text-4xl text-2xl font-thin md:font-thin xl:w-9/12 xl:py-0 ">
            Unlock the full value of your applications with our end-to-end
            services
          </div>
          <div className="font xl:text-lg lg:text-lg md:text-lg text-lg md:py-12 lg:py-0 py-8 xl:py-0">
            To drive business agility and deliver improved user experience,
            organizations must implement a flexible strategy for transforming
            their applications and data. Successful assessment, migration,
            modernization, and management of applications in hybrid, multi-cloud
            and distributed environments require specialized skills, tools, and
            capabilities. Kyndryl can help you transform your complex
            applications portfolio, boost performance and availability, improve
            security, and maintain application currency.
            <div className="xl:pt-8 md:pt-8 xl:pb-8 lg:pb-4 md:pb-0 pt-4">
              <a href="" className="link">
                Read the ERP solution brief
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* section */}

      <div className="bg-green-950 ">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 grid-cols-1">
            <div className="xl:p-12 lg:p-5 md:p-12 p-4 py-4  buttoncoffeesmallred xl:buttoncoffeesmallred">
              <p className="font text-white xl:text-4xl md:text-2xl text-2xl font-square">
                Modernize for better experiences
              </p>
              <p className="font text-white xl:text-lg text-lg xl:pt-4 md:pt-4 pt-4">
                We have forged our culture through the lens 6 key principles
                that we practice every day.We call it "The Kyndryl Way"
              </p>
            </div>
            <div className="xl:p-12 lg:p-5 md:p-12 p-4 buttoncoffeesmallred xl:buttoncoffeesmallred">
              <p className="font text-white xl:text-4xl md:text-2xl text-2xl font-square">
                Innovate for sustainable advantage
              </p>
              <p className="font text-white xl:text-lg text-lg xl:pt-4 md:pt-4 pt-4">
                Corporate citizenship powers human progress to drive success in
                our business, and we apply this principle to how we approach our
                Environmental.
              </p>
            </div>
            <div className="xl:p-12 lg:p-5 md:p-12 p-4 buttoncoffeesmallred xl:buttoncoffeesmallred">
              <p className="font text-white xl:text-4xl md:text-2xl text-2xl font-square">
                Secure for resilient operations
              </p>
              <p className="font text-white xl:text-lg text-lg xl:pt-4 md:pt-4 pt-4">
                Our purpose , promise and mission forged our culture through the
                lens 6 key principles that we practice every day.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* section */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 md:py-12 lg:py-8 py-10 ">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2">
          <div>
            <p className="xl:pt-8 lg:pt-20 font text-red-500 xl:text-4xl md:text-4xl text-4xl font-square">
              Modernization and Migration Services
            </p>
            <p className="font text-black xl:text-lg text-lg xl:pt-8 md:pt-8 pt-4">
              Modernize and migrate your applications while also integrating and
              optimizing within your ecosystem. Transform how you develop new
              products, build up new skills, and manage costs while mitigating
              the risk of the platform.
            </p>
            <div className="mb-8">
              <button className="buttoncoffeesmall">
                Read the solution brief ➙
              </button>
            </div>
          </div>
          <div className="xl:block md:hidden lg:block">
            <div className="font xl:p-0 lg:p-5">
              <img
                src="https://i.ibb.co/QPjYsj0/sky-ind-containercrane-4x3.jpg"
                style={{ height: "450px", width: "650px" }}
                alt="Your browser does not support this part content"
              ></img>
            </div>
          </div>{" "}
        </div>
        <div className="xl:hidden md:block lg:hidden hidden">
          <div className="font md:p-12">
            <img
              src="https://i.ibb.co/QPjYsj0/sky-ind-containercrane-4x3.jpg"
              style={{ height: "590px", width: "650px" }}
              alt="Your browser does not support this part content"
            />
          </div>
        </div>
        <div></div>
      </div>

      {/* section */}

      <div className="bg-blueGray-200">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4  xl:flex lg:flex xl:w-12/12">
          <div className="xl:w-5/12 md:w-12/12 md:p-12 lg:p-7 xl:p-4 xl:mt-6">
            <img
              src="https://i.ibb.co/4jbn3Qr/technicians-in-server-room-16x9.jpg"
              className="xl:h-40 "
              alt="Your browser does not support this part content"
            />
          </div>
          <div className="xl:w-7/12 xl:pl-4 md:pl-4 pl-4 lg:pr-4 xl:py-4">
            <p className="font xl:text-3xl lg:text-3xl md:text-4xl text-2xl font-thin md:font-thin xl:w-9/12 md:py-0 py-4 xl:py-0">
              74% of businesses are just starting or in-progress <br></br>
              towards modernizing their mainframe applications
            </p>
            <p className="font xl:text-lg lg:text-xl md:text-lg text-xl xl:pt-4  md:py-8 py-4 xl:py-0">
              Kyndryl commissioned Coleman Parkes Research paper outlines the
              key findings of the survey and the implications for mainframe
              decision-makers.
            </p>
            <button className="buttoncoffeesmall md:mb-8 xl:mb-0 mb-8">
              Learn more →
            </button>
          </div>
        </div>
      </div>

      {/* section */}
      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 xl:py-12 px-4">
          {/* Desktop view */}
          <div className="xl:block md:hidden lg:block hidden">
            <div className="font xl:p-0 xl:pl-0 lg:p-12">
              <img
                src="https://i.ibb.co/6X4Y2B3/hp-ind-scientistandworkersgreenhouse-4x3.jpg"
                style={{ height: "400px", width: "600px" }}
                alt="Your browser does not support this part content"
              ></img>
            </div>
          </div>

          {/* Tab view */}
          <div className="xl:hidden md:block lg:hidden hidden">
            <div className="font md:p-8">
              <img
                src="https://i.ibb.co/6X4Y2B3/hp-ind-scientistandworkersgreenhouse-4x3.jpg"
                style={{ height: "590px", width: "650px" }}
                alt="Your browser does not support this part content"
              ></img>
            </div>
          </div>

          {/* mobile view */}
          <div className="xl:hidden md:hidden lg:hidden block">
            <div className="font">
              <img
                src="https://i.ibb.co/6X4Y2B3/hp-ind-scientistandworkersgreenhouse-4x3.jpg"
                style={{ height: "250px", width: "650px", paddingTop: "1rem" }}
                alt="Your browser does not support this part content"
              ></img>
            </div>
          </div>

          <div className="font">
            <div className="xl:p-0 xl:pl-12 lg:p-12">
              <div className="xl:block md:block block">
                <p className="font xl:text-4xl text-2xl text-black xl:pt-4 md:pt-4 pt-4">
                  SAP application services and S/4HANA on AWS, Azure or Google
                  Cloud
                </p>
              </div>
              <div className="font xl:text-lg md:text-lg xl:pt-8 md:pt-8 pt-4">
                Organizations are looking for flexible and composable SAP
                application services to reduce cost and complexity while
                supporting their service delivery needs.
              </div>
              <div className="md:my-6 my-6">
                <button className="buttoncoffeesmall">
                  Explore SAP Application Services ➙
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section */}
      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 xl:py-12 px-4">
        <div className="font">
          <div className="xl:p-0 xl:pl-0 lg:p-12">
            {/* <div className="ftDropDown pb-5"></div> */}
            <div className="xl:block lg:block md:hidden hidden">
              <div>
                <p className="font text-4xl text-black xl:pt-12 md:pt-4 pt-4">
                  Oracle application services on AWS, Azure or OCI
                </p>
              </div>
              {/* <div className="xl:hidden md:hidden hidden">
              <p className="font font-thin  md:text-5xl text-black xl:pt-4 md:pt-4">
                Oracle application services on AWS, Azure or OCI
              </p>
            </div> */}
              <div className="font xl:text-lg md:text-lg xl:pt-8 md:pt-8 pt-4">
                Kyndryl offers a full range of services, solutions and
                technologies to manage, secure and optimize performancefor your
                Oracle application workloads on Amazon, AWS, Microsoft Azure .
              </div>
              <div className="md:my-6 my-6">
                <button className="buttoncoffeesmall">
                  Explore Oracle application services ➙
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:block md:hidden lg:block block">
          <div className="font xl:p-0 xl:pl-12 lg:p-12">
            <img
              src="https://i.ibb.co/D4v7pMM/ls-ricefieldaerial-4x3.jpg"
              style={{ height: "400px", width: "600px" }}
              alt="Your browser does not support this part content"
            ></img>
          </div>
        </div>

        <div className="xl:hidden md:block lg:hidden hidden">
          <div className="font md:p-8">
            <img
              src="https://i.ibb.co/D4v7pMM/ls-ricefieldaerial-4x3.jpg"
              style={{ height: "590px", width: "700px" }}
            ></img>
          </div>
        </div>

        <div className="xl:hidden lg:hidden md:block block">
          <div>
            <p className="font font-thin md:text-5xl text-2xl text-black pt-4">
              Oracle application services on AWS, Azure or OCI
            </p>
          </div>
          <div className="font xl:text-lg md:text-lg xl:pt-8 md:pt-8 pt-4">
            Kyndryl offers a full range of services, solutions and technologies
            to manage, secure and optimize performancefor your Oracle
            application workloads on Amazon, AWS, Microsoft Azure .
          </div>
          <div className="md:my-6 my-6">
            <button className="buttoncoffeesmall">
              Explore Oracle application services ➙
            </button>
          </div>
        </div>
      </div></div>
    </section>
  );
};

export default ApplicationContent2;
