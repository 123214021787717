import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";
import Vision from "assets/img/Vision.svg";
import mission from "assets/img/mission.svg";
import corevalues from "assets/img/corevalues.svg";
import EnquiryButton from "components/Pages/EnquiryButton";

const Aboutt = () => {
  // Our Mission learnmore togglecontent
  const [isContentVisible, setContentVisible] = useState(false);

  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  // last section on click accordination
  const [activeService, setActiveService] = useState(null);

  const toggleService = (service) => {
    if (activeService === service) {
      setActiveService(null);
    } else {
      setActiveService(service);
    }
  };

  const getContainerStyle = (service) => ({
    overflow: "hidden",
    transition: "max-height 0.3s ease-out",
    maxHeight: activeService === service ? "1120px" : "0",
  });

  // Our Mission learnmore togglecontent

  const [isContent2Visible, setIsContent2Visible] = useState(false);

  const toggleContent2 = () => {
    setIsContent2Visible(!isContent2Visible);
  };

  return (
    <>
      <section>
        <IndexNavbar /> {/* ENquiry Button */} <EnquiryButton />
        <div className="xl:mt-20 lg:mt-18 mt-18">
          <div className="">
            <div className="bg-grey-50">
              <div className="para-font container mx-auto text-dark-blue relative overflow-hidden xl:px-16 px-4 xl:py-3 lg:py-4 py-4">
                <a href="index" className="link">
                  Home
                </a>
                ― About Us
              </div>
            </div>
          </div>

          {/* More content section */}

          <div className="container mx-auto overflow-hidden xl:px-16 px-4 xl:pb-12 lg:pb-8 md:pb-8 pb-8">
            <div className="items-center grid xl:grid-cols-2 xl:gap-12 md:grid-cols-2 md:gap-8 lg:grid-cols-2 lg:gap-8">
              <div>
                <p className="uppercase xl:text-base md:text-xs text-sm text-cyan-500 xl:mt-16 lg:mt-8 mt-8 md:mt-12">
                  about us
                </p>
                <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl xl:pt-1 lg:pt-2 pt-2">
                  About us
                </div>
                <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-2 lg:pt-2 pt-1 xl:pb-4 lg:pb-0 md:pb-0 pb-3">
                  At iBusiness Consulting Inc., a proudly Canadian company, we
                  bring years of experience to the global market, collaborating
                  with a diverse array of countries and organizations. Our
                  mission is to align entrepreneurs with the resources they need
                  to excel in today's dynamic digital landscape. Whether you are
                  steering an established organization or launching a start-up,
                  our goal is to support your business journey towards success
                  with unwavering commitment and exceptional support.
                </div>
                <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-2 lg:pt-2 pt-1 xl:pb-4 lg:pb-4 pb-0 md:pb-4">
                  We empower you to make informed business decisions across all
                  fronts. Our core aim is to equip you with the necessary tools
                  and insights to fuel your passion for business. Navigating the
                  complexities of maximizing operational efficiency can be
                  challenging, but with iBC, you gain a partner who amplifies
                  your business’s potential.
                </div>
              </div>
              <div className="h-auto xl:mr-24 xl:p-12 lg:p-4 md:p-0 lg:pb-0 p-8">
                <img
                  src={mission}
                  alt="Partner Image"
                  className="rounded-md xl:p-0 lg:p-12 xl:mt-16 xl:ml-20 md:mt-8 lg:mt-8 mt-2"
                />
              </div>
            </div>
            <div className="xl:pt-0 pt-2 para-font">
              <p
                className="figtree text-base underline-on-hover xl:text-base cursor-pointer font-bold text-light-blue"
                onClick={toggleContent}
              >
                Learn more <span className="right-symbol">➜</span>
              </p>
            </div>
            {isContentVisible && (
              <div className="text-left">
                <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-0 lg:pt-2 pt-1 xl:pb-4 lg:pb-0 md:pb-4 pb-0">
                  Our team of subject matter experts delivers comprehensive
                  consulting services across Canada and internationally. We
                  support organizations at every stage—from inception to
                  expansion—providing tailored solutions that meet your specific
                  needs. In partnership with law firms and accounting
                  specialists, we guide our clients through their business
                  journey with confidence and vitality, helping them achieve
                  their objectives.
                </div>
                <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-0 lg:pt-2 pt-1 xl:pb-4 lg:pb-0 md:pb-4 pb-0">
                  At iBC, we are dedicated to providing the knowledge,
                  expertise, and practical tools needed to thrive in the modern
                  business environment. Let us be your partner in navigating the
                  complexities of today's business world, and together, we will
                  chart a path to your success.
                </div>
              </div>
            )}
          </div>

          {/* vision  */}

          <div className="bg-extra-light-grey">
            <div className="container mx-auto overflow-hidden xl:px-16 px-4">
              {/* <div className="xl:mb-16 lg:mb-12 mb-6 md:mb-12 md:mt-12 xl:mt-12 lg:mt-12 mt-6"> */}
              <div>
                <div className="xl:pb-12 lg:pb-8 lg:pt-8 xl:pt-0 md:pt-8 md:pb-8 items-center grid xl:grid-cols-2 grid-cols-1 md:grid-cols-2 md:gap-8 md:flex-none lg:grid-cols-2 lg:gap-8 lg:flex-none flex-col-reverse xl:flex-col-reverse flex xl:flex-none xl:gap-12">
                  <div className="h-auto xl:mr-20 xl:mt-4 mt-0 lg:mt-0 md:mt-12 xl:p-12 lg:p-4 md:p-4 p-4">
                    <img
                      src={Vision}
                      alt="Partner Image"
                      className="rounded-md xl:w-450px lg:width-400px"
                    />
                  </div>
                  <div className="xl:pt-16 md:pt-4 pt-8">
                    <p className="uppercase xl:text-base lg:text-sm md:text-xs text-sm text-cyan-500 xl:mt-14">
                      vision
                    </p>
                    <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl xl:pt-2 lg:pt-2 md:pt-2 pt-2">
                      Our Vision
                    </div>
                    <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-1 lg:pt-2 pt-1 xl:pb-4 pb-0">
                      At iBusiness Consulting Inc., we are driven by a profound
                      commitment to professionalism, core values, and a deep
                      understanding of what is best for our customers. Our
                      vision is to be a steadfast partner for our clients,
                      supporting their growth and fostering innovative solutions
                      for future challenges. We are dedicated to crafting a
                      creative and forward-thinking company that thrives on
                      passion for what we do.
                    </div>
                    <div className="font text-base text-justify text-light-blue para-font leading-relaxed xl:pt-1 lg:pt-2 pt-1 xl:pb-4 pb-0">
                      Designed for and delivered in the digital age, our
                      approach is to anticipate the evolving needs of our
                      clients and respond with agility and creativity. We strive
                      to be pioneers in the industry, continuously pushing the
                      boundaries of the digital landscape to bring you solutions
                      that are not only effective but also resonate with your
                      business objectives. With iBC, embark on a journey where
                      your business aspirations become attainable through
                      strategic planning and expert execution.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* community partner */}

          <div className="container mx-auto overflow-hidden xl:px-16 px-4">
            <div className="xl:mb-16 lg:mb-12 mb-6 md:mb-12 md:mt-12 xl:mt-16 lg:mt-8 mt-6">
              <div className="items-center grid xl:grid-cols-2 xl:gap-12  md:grid-cols-2 md:gap-8 lg:grid-cols-2 lg:gap-8">
                <div className="flex flex-col justify-center">
                  <p className="uppercase xl:text-base lg:text-sm md:text-xs text-sm text-cyan-500 xl:pt-0 md:pt-0 pt-4">
                    mission
                  </p>
                  <div className="figtree xl:text-4xl text-light-blue font-extrabold lg:text-3xl md:text-2xl text-2xl xl:pt-2 lg:pt-2 md:pt-2 pt-2">
                    Our Mission
                  </div>
                  <div className="font text-base text-light-blue para-font leading-relaxed xl:pt-2 lg:pt-2 xl:pb-0">
                    <p>
                      iBusiness Consulting empowers businesses to thrive through
                      comprehensive and practical solutions across four key
                      areas:
                    </p>
                    <ul className="list-none xl:pt-3 pt-2">
                      <li className="font text-base text-light-blue para-font leading-relaxed flex items-start">
                        <p>
                          <b>Corporate Services:</b> Streamline your operations
                          and ensure compliance with expert guidance.
                        </p>
                      </li>
                      <li className="font text-base text-light-blue para-font leading-relaxed flex items-start py-2">
                        <p>
                          <b>HR Services:</b> Build a high-performing team with
                          our strategic human resources solutions.
                        </p>
                      </li>
                      <li className="font text-base text-light-blue para-font leading-relaxed flex items-start">
                        <p>
                          <b>IT Services:</b> Leverage technology to drive
                          efficiency and innovation with our IT expertise.
                        </p>
                      </li>
                      <li className="font text-base text-light-blue para-font leading-relaxed flex items-start py-2">
                        <p>
                          <b>Intellectual Property:</b> Services Intellectual
                          Property Services: Protect your ideas and safeguard
                          your competitive edge with our intellectual property
                          specialists.
                        </p>
                      </li>
                    </ul>
                    <p className="xl:pt-0 pt-1">
                      By focusing on long-term success, we become a trusted
                      partner in your journey.
                    </p>
                  </div>
                </div>
                <div className="xl:block lg:block md:block hidden">
                  <div className="h-auto xl:mr-24">
                    <img
                      src={mission}
                      alt="Partner Image"
                      className="rounded-md xl:mt-0 mt-4 md:mt-12 lg:mt-4 xl:ml-20 xl:p-4 xl:w-450px lg:width-400px"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:pt-6 lg:pt-6 pt-4 para-font">
                <p
                  className="figtree text-base underline-on-hover xl:text-base cursor-pointer font-bold text-light-blue"
                  onClick={toggleContent2}
                >
                  Learn more <span className="right-symbol">➜</span>
                </p>
              </div>
              {isContent2Visible && (
                <div className="text-left">
                  <p className="xl:pt-0 pt-1 font-semibold text-blue text-base">
                    Improvements:
                  </p>
                  <ul className="list-none xl:pt-3 pt-2">
                    <li className="font text-base text-light-blue para-font leading-relaxed flex items-start">
                      <p>
                        <b> Clear and concise:</b> Explains the mission in a
                        straightforward way. Action-oriented language:
                        Highlights the benefits for clients.
                      </p>
                    </li>
                    <li className="font text-base text-light-blue para-font leading-relaxed flex items-start py-2">
                      <p>
                        <b>Specificity:</b> Briefly describes each service area.
                      </p>
                    </li>
                    <li className="font text-base text-light-blue para-font leading-relaxed flex items-start">
                      <p>
                        <b>Long-term focus:</b> Retains the idea of thinking
                        long-term for your clients.
                      </p>
                    </li>
                    <li className="font text-base text-light-blue para-font leading-relaxed flex items-start py-2">
                      <p>
                        <b>Stronger ending:</b> Positions iBC as a trusted
                        partner.
                      </p>
                    </li>
                  </ul>
                </div>
              )}
              <div className="xl:hidden lg:hidden md:hidden block">
                <div className="h-auto xl:mr-24">
                  <img
                    src={mission}
                    alt="Partner Image"
                    className="rounded-md xl:mt-0 mt-4 md:mt-12 lg:mt-4 xl:ml-20 xl:p-4 xl:w-450px lg:width-400px"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="xl:px-60 lg:px-52 md:px-4 px-4 xl:pt-0 md:pt-0 pt-4 xl:pb-16 lg:pb-12 md:pb-12 xl:px-0 lg:px-0 pb-8">
            <div
              className="border-t cursor-pointer"
              onClick={() => toggleService("Management")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-3 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Management")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold">Management</p>
                    <p>✛</p>
                  </div>
                </button>
              </div>

              <div
                style={getContainerStyle("Management")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t ">
                  We develop strategies that optimize organizational structure
                  and process efficiency, ensuring that management practices
                  propel the company forward.
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Information Technology")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Information Technology")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold"> IT</p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Information Technology")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t">
                  Our IT consultancy services facilitate the integration of
                  advanced technology solutions that drive innovation and
                  operational effectiveness.
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Marketing")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Marketing")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold">Marketing </p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Marketing")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t">
                  We assist in crafting targeted marketing strategies that
                  enhance brand visibility and engage the intended audiences
                  effectively.
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Human Resources")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Human Resources")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold">Human Resources</p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Human Resources")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t">
                  By optimizing HR processes, we ensure that our clients
                  attract, develop, and retain the best talent necessary for
                  organizational success.
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Operations")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Operations")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold">Operations</p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Operations")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-2 px-4 border-t">
                  Our team helps streamline operational procedures to improve
                  quality, reduce costs, and increase overall efficiency.
                  Additionally, we have extended our services to provide
                  personalized support to entrepreneurs. This involves offering
                  tailored advice that addresses the unique challenges faced by
                  new and growing businesses, helping them to navigate the
                  complexities of their respective markets.
                </p>
                <p className="py-2 px-4">
                  Our in-depth knowledge and diverse industry experience have
                  been instrumental in shaping our identity as trusted experts.
                  By maintaining a strong commitment to continuous learning and
                  adaptation, we keep pace with industry trends and
                  developments, ensuring our clients receive the most current
                  and effective solutions. This unwavering dedication to
                  excellence and client success is what sets iBusiness
                  Consulting Inc. apart as a leader in the business consulting
                  field.
                </p>
                <p className="py-0 px-4 pb-2">
                  What Elevates iBC as a Premier Consulting Firm? At iBusiness
                  Consulting Inc. (iBC), our distinction as a high-level
                  consulting firm is rooted in several key factors that set us
                  apart:
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Expert Team of Specialists")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Expert Team of Specialists")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold">Expert Team of Specialists</p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Expert Team of Specialists")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t">
                  iBC is powered by a team of subject matter experts whose
                  extensive knowledge spans various industries and business
                  functions. This depth of expertise allows us to offer
                  specialized consulting that is both relevant and impactful.
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Collaborative Approach")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Collaborative Approach")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold">Collaborative Approach</p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Collaborative Approach")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t">
                  We believe in the power of collaboration. Working closely with
                  law firms and accountants, we integrate legal and financial
                  insights into our consultancy services. This multidisciplinary
                  approach ensures that our strategies are comprehensive,
                  legally compliant, and financially sound.
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Tailored Solutions")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Tailored Solutions")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold"> Tailored Solutions</p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Tailored Solutions")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t">
                  Understanding that each business is unique, we customize our
                  consulting services to meet the specific needs of each client.
                  Whether it's navigating market entry, scaling operations, or
                  driving innovation, our solutions are designed to align with
                  the client's strategic goals.
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Commitment to Client Success")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Commitment to Client Success")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold">
                      Commitment to Client Success
                    </p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Commitment to Client Success")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t">
                  At iBC, our clients’ objectives are our top priority. We are
                  committed to providing the knowledge, tools, and support
                  necessary to empower businesses in achieving their goals. This
                  client-centric ethos fosters long-term relationships built on
                  trust and mutual success.
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Adaptability and Forward Thinking")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() =>
                    toggleService("Adaptability and Forward Thinking")
                  }
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold">
                      Adaptability and Forward Thinking
                    </p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Adaptability and Forward Thinking")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t">
                  At iBC, our clients’ objectives are our top priority. We are
                  committed to providing the knowledge, tools, and support
                  necessary to empower businesses in achieving their goals. This
                  client-centric ethos fosters long-term relationships built on
                  trust and mutual success.In today’s rapidly evolving business
                  landscape, staying ahead is key. Our team is not just
                  responsive to changes but anticipates them, ensuring that our
                  clients are always prepared for the future. We bring energy
                  and confidence to our engagements, driving dynamic solutions
                  that are both innovative and executable.
                </p>
              </div>
            </div>

            <div
              className="border-t  cursor-pointer"
              onClick={() => toggleService("Global and Local Insights")}
            >
              <div className="font xl:text-base text-base text-light-blue para-font leading-relaxed xl:pt-3 lg:pt-2 md:pt-3 md:pb-4 pt-4 xl:pb-4 pb-4">
                <button
                  onClick={() => toggleService("Global and Local Insights")}
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  className="w-full px-4"
                >
                  <div className="flex justify-between xl:pt-3 pt-1 md:pt-2 lg:pt-3">
                    <p className="font-semibold">Global and Local Insights</p>
                    <p>✛</p>
                  </div>
                </button>
              </div>
              <div
                style={getContainerStyle("Global and Local Insights")}
                className="font text-base bg-light-greyy"
              >
                <p className="py-4 px-4 border-t">
                  With experience across various geographic markets, we offer a
                  balanced perspective that incorporates both global trends and
                  local nuances. This enables us to provide advice that is
                  globally informed yet locally applicable. These elements
                  collectively contribute to our reputation as a high-level
                  consulting firm. iBC's ability to combine deep industry
                  knowledge with a collaborative, client-focused approach
                  ensures that we deliver value that not only meets but exceeds
                  expectations.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </section>
    </>
  );
};

export default Aboutt;
